
import defaultHead from '@/utils/defaultHead'
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'
import HeadMx from '@/mixins/head-mixin'

export default {
  name: 'WebviewLayout',
  components: { ZendeskButton },
  mixins: [HeadMx],
  head() {
    return {
      ...defaultHead(this.$locale),
      link: [...(this.hreflang() || []), this.canonical()],
    }
  },
}
