export default ({ store }) => {
  const sharedMutations = [
    'cart/addItem',
    'cart/updateItem',
    'cart/remove',
    'cart/setDeliveryMethod',
    'cart/removeDeliveryMethod',
    'cart/addCoupon',
    'cart/clearCoupons',
    'cart/setAddress',
    'cart/setSyncError',
    'cart/setIsReady',
    'cart/setCartUid',
    'cart/setRemoteCart',
    'cart/clearCart',
    'cart/clearRemoteCart',
  ]
  let isProcessing = false

  store.subscribe(mutation => {
    if (sharedMutations.includes(mutation.type) && !isProcessing) {
      const data = {
        type: mutation.type,
        payload: mutation.payload,
      }
      localStorage.setItem('vuex-mutation', JSON.stringify(data))
    }
  })

  // Handle incoming mutations
  window.addEventListener('storage', event => {
    if (event.key === 'vuex-mutation' && event.newValue) {
      const mutation = JSON.parse(event.newValue)
      isProcessing = true
      store.commit(mutation.type, mutation.payload)
      isProcessing = false
    }
  })
}
