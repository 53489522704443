import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('club', {
      currentClub: state => state,
      clubConfig: state => state.company_group_configuration,
    }),
    ...mapGetters('club', ['isClub', 'layout', 'clubEnablePayment', 'clubEnableDelivery', 'clubConfig']),
    clubUid() {
      return this.$store.state.club.uid
    },
    clubAds() {
      if (this.isClub && this.clubConfig?.product_ads?.length) {
        return this.clubConfig?.product_ads.map(ad => {
          return {
            _uid: ad._uid,
            component: 'GridAd',
            desktop_index: ad.line,
            format: ad.kind === 'LANDSCAPE' ? 'full' : ad.position ? ad.position?.toLowerCase() : null,
            mobile_image: ad.image_url,
            mobile_index: ad.line,
            url: ad.url,
            wide_image: ad.image_url,
          }
        })
      }
    },
    clubBanners() {
      if (this.isClub && this.clubConfig?.banner) {
        return [
          {
            component: 'Slide',
            image_alt: 'club banner',
            mobile_image: this.clubConfig?.banner,
            wide_image: this.clubConfig?.banner,
            testId: 'club-banner',
          },
        ]
      } else if (this.isClub && this.clubConfig?.banners) {
        if (this.clubConfig.banners.some(x => x.desktop_image_url || x.mobile_image_url)) {
          return this.clubConfig.banners.map(banner => {
            return {
              component: 'Slide',
              image_alt: banner.alt,
              mobile_image: banner.mobile_image_url,
              target_value: banner.url,
              wide_image: banner.desktop_image_url,
              testId: 'club-banner',
            }
          })
        }
      }
      return null
    },
  },
}
