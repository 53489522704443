
import API from '@/mixins/sp-api-mixin'
import Mobile from '@/mixins/mobile-mixin'
import URL from '@/mixins/url-mixin'
import i18nMixin from '@/mixins/i18n-mixin'
import dynamicContentMx from '@/mixins/dynamic-content-mixin'

import Category from '@/models/Category'

import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'BurgerMenu',
  components: {
    MainButton,
  },
  mixins: [API, Mobile, URL, i18nMixin, dynamicContentMx],
  data() {
    return {
      visible: false,
      selectedCategory: null,
      selectedLevel: 0,
      showCategories: false,
      showInspirations: false,
      proUrl: '',
      tagsStocks: [],
    }
  },
  computed: {
    featuredInspirations() {
      return this.$store.getters['dynamicContent/featuredInspirations']?.filter(inspiration => {
        return !!this.tagsStocks.find(x => x?.tag === inspiration?.url?.split('?tags=')?.[1] && x?.stock_quantity > 10)
      })
    },
    hasGreenEveryday() {
      return this.tagsStocks?.find(x => x.tag === 'ged')?.stock_quantity > 10
    },
    user() {
      return this.$currentUser
    },
    menuCategories() {
      return [this.allCategories, ...this.allCategories.filter(x => !x.parent)]
    },
    specialCategories() {
      let list = [
        {
          label: this.$t('actions.newProducts'),
          link: this.localePath('category-new-products'),
          testId: 'category-new-products',
        },
        {
          label: this.$t('actions.bestDeals'),
          link: this.localePath('category-best-deals'),
          testId: 'category-best-deals',
        },
        {
          label: this.$t('actions.bestSellers'),
          link: this.localePath('category-best-sellers'),
          testId: 'category-best-sellers',
        },
        // {
        //   label: this.$t('actions.specialDiscounts'),
        //   link: this.localePath('category-special-discounts'),
        // },
      ]
      if (this.hasGreenEveryday) {
        list.push({
          label: this.$t('actions.greenEveryday'),
          link: this.localePath('category-green-everyday'),
        })
      }
      return list
    },
    allCategories() {
      return this.$store.state.categories.available.map(category => new Category(category, this.country))
    },
    seasonalSelectionMenu() {
      return this.$store.getters['dynamicContent/featuredSeasonalSelectionsMenu']
    },
    contactFormLink() {
      return this.country?.contactFormLink[this.locale]
    },
    faqLink() {
      return this.country?.faqLink[this.locale]
    },
    countryCodeFR() {
      return this.country?.code === 'fr'
    },
  },
  beforeMount() {
    this.$bus.$on(this.$events.OPEN_BURGER_MENU_CATEGORIES, () => {
      this.toggleMenu()

      this.$nextTick(() => {
        this.visible = true
        this.showCategories = true
      })
    })
    this.$bus.$on(this.$events.CLOSE_BURGER_MENU, () => (this.visible = false))
  },
  beforeDestroy() {
    this.$bus.$off(this.$events.OPEN_BURGER_MENU_CATEGORIES)
    this.$bus.$off(this.$events.CLOSE_BURGER_MENU)
  },
  mounted() {
    this.getDynamicContent({
      $store: this.$store,
      contents: [
        // 'featuredinspirations',
        'featuredseasonalselections',
      ],
    })
    this.proUrl = this.getProUrl()
    this.spRequest({ req: this.$api.tags.getStocksTags() }).then(results => {
      this.tagsStocks = results
    })
  },
  methods: {
    toggleCategories() {
      if (this.showCategories && this.selectedLevel > 0) {
        this.selectedLevel = 0
        this.selectedCategory = null
      } else {
        this.showCategories = !this.showCategories
      }
    },

    toggleInspirations() {
      this.showInspirations = !this.showInspirations
    },

    toggleMenu() {
      this.visible = !this.visible
      this.selectedCategory = null
      this.selectedLevel = 0
      this.showCategories = false
      this.showInspirations = false
    },

    selectCategory(category, level) {
      let pCategory = category
      if (category && !(category instanceof Category)) {
        pCategory = new Category(category, this.country)
      }
      if (pCategory === null || pCategory.hasChildren) {
        this.selectedLevel = level
        this.selectedCategory = pCategory
      }
    },

    selectInspiration(url) {
      this.toggleMenu()
      this.$router.push(this.localePath(url))
    },
  },
}
