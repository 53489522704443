
import Club from '@/mixins/club-mixin'

export default {
  name: 'ClubFooter',
  mixins: [Club],
  methods: {
    clubSocial(social) {
      return this.clubConfig.components?.socials?.find(x => x.platform === social)
    },
  },
}
