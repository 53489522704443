import { getCurrentLocale, currentLocation, currentCountry } from '@/utils/currentLocale'

export const state = () => ({
  tld: null,
  locale: null,
  country: null,
})

export const mutations = {
  setTld(state, tld) {
    state.tld = tld
  },
  setLocale(state, locale) {
    state.locale = locale
  },
  setCountry(state, country) {
    state.country = country
  },
}

export const getters = {
  langCode:
    state =>
    (min = false, revert = false) => {
      let currentLocale = state.locale
      let location = state.tld
      if (min && currentLocale?.toLowerCase() === location?.toLowerCase()) {
        return currentLocale
      }
      if (revert) {
        return location?.toLowerCase() + '-' + currentLocale?.toLowerCase()
      }
      return currentLocale?.toLowerCase() + '-' + location?.toLowerCase()
    },
  country: state => state.country,
}

export const actions = {
  async init({ commit, state }, ctx) {
    if (!state.tld) {
      const tld = currentLocation(ctx)
      // console.log('tld', tld)
      commit('setTld', tld)
    }

    if (!state.locale) {
      const locale = getCurrentLocale(ctx)
      // console.log('locale', locale)
      commit('setLocale', locale)
      ctx.i18n.locale = locale
    }

    if (!state.country) {
      const country = currentCountry(ctx)
      commit('setCountry', country)
    }
  },
}
