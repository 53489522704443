import Repository from './repository'

export default class CompanyRepository extends Repository {
  async findWithSiren(siren) {
    const fSiren = siren.replace(/\s/g, '')
    const url = `${this.backendUrl}companies/siren/${fSiren}`
    return this.axios.get(url).then(response => response.data.company)
  }

  async findWithName(name) {
    const url = `${this.backendUrl}companies/search`
    const config = {
      params: {
        name,
      },
    }
    return this.axios.get(url, config).then(response => response.data.companies)
  }

  async getCompanies(filter, mapping = this.mapper.companyMapping) {
    const url = `${this.backendUrl.replace('v1', 'v2')}companies` // Temporary solution for calling v2, need global solution
    const config = {
      params: {
        full: true,
      },
      headers: {
        Mapping: mapping,
      },
    }
    if (filter?.kind) config.params.kind = filter.kind
    if (filter?.items_per_page) config.params.per_page = filter.items_per_page
    if (filter?.page) config.params.page = filter.page
    if (filter?.search) config.params.search = filter.search
    return this.axios.get(url, config).then(response => response.data)
  }

  async subscribe(company) {
    const subscribeUrl = `${this.backendUrl}companies`
    return this.axios.post(subscribeUrl, { company }).then(response => response.data.company)
  }

  async subscribeStripeVerification(companyUid, formData) {
    const subscribeUrl = `${this.backendUrl}companies/${companyUid}/verification`
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    return this.axios.post(subscribeUrl, formData, config).then(response => response.data.success)
  }

  async subscribeStripe(companyUid, purchase) {
    const subscribeUrl = `${this.backendUrl}companies/${companyUid}/company_purchases`
    const payload = {
      company_purchase: purchase,
    }
    return this.axios.post(subscribeUrl, payload).then(response => response.data)
  }
}
