
export default {
  name: 'StandardModal',
  props: {
    maxWidth: { type: String, default: '320px' },
    minWidth: { type: String, default: '0px' },
    name: { type: String, default: 'modal' },
    showCloseButton: { type: Boolean, default: true },
    fullscreen: { type: Boolean, default: true },
    position: { type: String, default: 'c' }, // could be c for center, tr, tl, br, bl for topright, etc
  },
  data() {
    return {
      showModal: false,
      payload: {},
      attr: [
        { key: '.topbar', index: 0 },
        { key: '.navbar-left', index: 0 },
        { key: '.layout-content', index: 0 },
        { key: '.search-autocomplete-content', index: 1 },
        { key: 'footer', index: -1 },
      ],
      modalOpenName: 'modalOpen',
      modalCloseName: 'modalClose',
    }
  },
  computed: {
    openedSearchAutocomplete() {
      // Check if search autocomplete is opened for top bar z-index
      return !!document.querySelector('.search-autocomplete-content')
    },
  },
  mounted() {
    this.setModalEventNames()
    this.$root.$on(this.modalOpenName, data => {
      if (typeof data === 'object') {
        const { name, payload } = data
        if (this.name === name) this.showModal = true
        this.payload = payload
        this.$emit('sendPayload', this.payload)
      } else if (data === this.name) this.showModal = true
      // z-index updated to have the mask overlay over the header
      this.attr.forEach(attr => {
        let element = document.querySelector(attr.key)
        if (element) {
          if (attr.key === '.topbar') {
            element.setAttribute('style', `z-index: ${this.openedSearchAutocomplete ? '1' : '0'};`)
          } else element.setAttribute('style', `z-index: ${attr.index};`)
        }
      })
    })
    // Always call modalClose before delete modal
    // z-index re-set to initial value
    this.$root.$on(this.modalCloseName, name => {
      if (name === this.name) this.showModal = false
      this.refreshZIndex()
    })
  },
  beforeDestroy() {
    this.setModalEventNames()
    this.$root.$off(this.modalOpenName)
    this.$root.$off(this.modalCloseName)
    this.refreshZIndex()
  },
  methods: {
    close() {
      this.$root.$emit(this.modalCloseName, this.name)
      this.$emit('close')
    },
    refreshZIndex() {
      this.attr.forEach(attr => {
        let element = document.querySelector(attr.key)
        if (element) element.setAttribute('style', '')
      })
    },
    setModalEventNames() {
      // Set separate modal events to avoïd global $on/$off on standard modals when navigate
      this.modalOpenName = this.name !== 'modal' ? 'modalOpen_' + this.name : 'modalOpen'
      this.modalCloseName = this.name !== 'modal' ? 'modalClose_' + this.name : 'modalClose'
    },
  },
}
