
export default {
  name: 'MainButton',
  props: {
    label: { type: String, default: null },
    badge: { type: String, default: null },
    title: { type: String, default: null },
    ariaLabel: { type: String, default: null },
    link: { type: String, default: null },
    target: { type: String, default: null },
    tag: { type: String, default: 'nuxt-link' }, // nuxt-link or any tag
    tooltip: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    iconPosition: { type: String, default: 'LEFT' }, // LEFT, RIGHT
    classVariant: { type: null, default: null }, // See bellow
    styleVariant: { type: null, default: () => {} },
    loading: { type: Boolean, default: false },
    hasPremiumIcon: { type: Boolean, default: false },
    hasEmoji: { type: Boolean, default: false },
    outlinedColor: { type: String, default: null },
    elementTest: { type: String, default: '' },
  },
  computed: {
    hasSlot() {
      return this.$slots.default !== null && this.$slots.default !== undefined
    },
  },
}
