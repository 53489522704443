import reject from 'lodash.reject'

export const state = () => ({
  routeName: null,
  active: [],
  shouldListen: false,
  expandedFilter: null,
  lastFilterToggled: null, // For GA tracking event
})

export const getters = {
  routeName: state => state.routeName,
  items: state => state.active,
  shouldListen: state => state.shouldListen,
  filterByType: state => type => state.active.filter(f => f.type === type),
  displayableFilters: state => state.active.filter(f => f.display),
  isActive: state => filter => {
    const match = state.active.find(
      f => f.type === filter.type && f.value === filter.value && (f.value !== null || f.value !== undefined)
    )
    return !!match
  },
  lastFilterToggled: state => state.lastFilterToggled,
}

export const mutations = {
  setRouteName(state, routeName) {
    state.routeName = routeName
  },
  setShouldListen(state, listen) {
    state.shouldListen = listen
  },
  setActiveFilters(state, active) {
    state.active = active
  },
  addFilter(state, { filter, shouldListen = true }) {
    state.shouldListen = shouldListen
    state.active.push(filter)
    state.lastFilterToggled = filter
  },
  addFilterUnique(state, filter) {
    state.active = reject(state.active, f => f.type === filter.type)
    state.shouldListen = true
    state.active.push(filter)
    state.lastFilterToggled = filter
  },
  addFiltersUnique(state, filters) {
    for (let i = 0; i <= filters.length - 2; i += 1) {
      const filter = filters[i]
      state.active = reject(state.active, f => f.type === filter.type)
      state.active.push(filter)
    }
    const filter = filters[filters.length - 1]
    state.active = reject(state.active, f => f.type === filter.type)
    state.shouldListen = true
    state.active.push(filter)
  },
  removeFilter(state, filter) {
    state.shouldListen = true
    state.active = reject(state.active, f => f.type === filter.type && f.value === filter.value)
  },
  removeFilters(state) {
    state.shouldListen = true
    state.active = []
  },
  removeFilterTypes(state, { types, shouldListen }) {
    state.shouldListen = shouldListen
    state.active = reject(state.active, f => types.includes(f.type))
  },
  setExpandableFilter(state, filter) {
    state.expandedFilter = filter
  },
}
