import Router from 'vue-router'

/*
  This router rewrites routes for pages under club-domain & root-domain directories
  It's for club feature using StockPro subdomains. Some url paths load different page content.
  If a page is moved under a domain directory, make sure to have it's counterpart in the club or root directory
*/

// Inspired from https://ynechaev.medium.com/subdomains-with-nuxt-js-483df826c788
export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options

  let routesDirectory = 'root-domain'

  if (process.server && ssrContext && ssrContext.nuxt && ssrContext.req) {
    let subpaths = ssrContext.req.headers.host.split('.') || []
    // console.log(subpaths)
    let isClubDomain =
      subpaths.length > 2 &&
      !['www', 'pro', 'intl', 'staging', process.env.ENV_NAME?.toLowerCase()].includes(subpaths[0])
    let isProDomain = subpaths.length > 2 && subpaths[0] === 'pro'

    // Get routes directory based on host and subdomain presence
    if (isClubDomain) {
      routesDirectory = 'club-domain'
    } else if (isProDomain) {
      routesDirectory = 'pro-domain'
    }
    // routesDirectory = isClubDomain ? 'club-domain' : 'root-domain'
    // console.log('routesDirectory', routesDirectory)
    ssrContext.nuxt.routesDirectory = routesDirectory
  }

  if (process.client) {
    // Get what we saved on SSR
    if (window.__NUXT__ && window.__NUXT__.routesDirectory) {
      routesDirectory = window.__NUXT__.routesDirectory
    }
  }

  function isUnderDirectory(route, directory) {
    const path = route.path
    return path === '/' + directory || path.startsWith('/' + directory + '/')
  }

  let newRoutes = []

  if (routesDirectory) {
    newRoutes = options.routes
      .filter(route => {
        // Exclude duplicated routes based on opposite routesDirectory
        const excludedDirectory = routesDirectory === 'club-domain' ? 'root-domain' : 'club-domain'
        return !isUnderDirectory(route, excludedDirectory)
      })
      .map(route => {
        // Remove domain directory in path and name
        if (isUnderDirectory(route, routesDirectory)) {
          return {
            ...route,
            path: route.path?.substr(routesDirectory.length + 1) || '/',
            name: route.name?.substr(routesDirectory.length + 1) || 'index',
          }
        }
        return route
      })
  }

  return new Router({
    ...options,
    routes: newRoutes,
  })
}
