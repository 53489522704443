export default class Analytics {
  constructor(analytics) {
    this.analytics = analytics
  }

  sendTagEvent(name, params = {}) {
    this.analytics.forEach(analytic => {
      analytic.sendTagEvent(name, params)
    })
  }

  sendEvent(name, params = {}) {
    this.analytics.forEach(analytic => {
      analytic.sendEvent(name, params)
    })
  }

  sendScreen(name, params = {}) {
    this.analytics.forEach(analytic => {
      analytic.sendScreen(name, params)
    })
  }
}
