export default {
  computed: {
    downloadAppLink() {
      if (this.$ua.isFromAndroidOs()) {
        return 'https://play.google.com/store/apps/details?id=com.stockpro'
      }
      return 'https://apps.apple.com/fr/app/stock-pro/id1450359795'
    },
  },
  methods: {
    trackDownloadLink() {
      this.$analytics.sendEvent(this.$events.SUBMIT_APPLICATION)
    },
  },
}
