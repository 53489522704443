export default function ({ route, redirect, store }) {
  let { lang, ...otherQueryParams } = route.query

  // Yuri recommandation before nuxt 3 : in multi lang country only have urls with lang params
  if (store.state.i18nStore.country.availableLanguages.length > 1 && store.state.i18nStore.locale && !lang) {
    return redirect(301, { path: route.path, query: { lang: store.state.i18nStore.locale, ...otherQueryParams } })
  }
  if (!lang) return

  // 1. In case of duplicated param lang (lang=nl,nl,nl&lang=nl) when Google bot crawls
  // if there is 2 lang params, keep only the first
  if (Array.isArray(lang) && lang.length > 1) {
    const cleanedLang = lang[0]
    const cleanedQueryParams = { lang: cleanedLang, ...otherQueryParams }
    return redirect(301, { path: route.path, query: cleanedQueryParams })
  }
  // Keep lang first input in case of lang=nl,nl,nl
  if (typeof lang === 'string' && lang.includes(',')) {
    const cleanedLang = lang.split(',')[0]
    const cleanedQueryParams = { lang: cleanedLang, ...otherQueryParams }
    return redirect(301, { path: route.path, query: cleanedQueryParams })
  }

  // 2. In case of lang=fr param on stock-pro.fr (google bots behaviour)
  if (store.state.i18nStore.country.code === route.query.lang) {
    const cleanedQueryParams = { ...otherQueryParams }
    return redirect(301, { path: route.path, query: cleanedQueryParams })
  }
}
