export const actions = {
  async nuxtServerInit({ dispatch, commit }, ctx) {
    console.log('nuxtServerInit')
    if (ctx.req.headers) {
      let subpaths = ctx.req.headers.host.split('.') || []
      if (subpaths.length > 2 && ['pro'].includes(subpaths[0])) {
        commit('proArea/setIsProDomain', subpaths[0])
      } else if (
        subpaths.length > 2 &&
        !['www', 'pro', 'intl', 'staging', process.env.ENV_NAME?.toLowerCase()].includes(subpaths[0])
      ) {
        commit('club/setClubDomain', subpaths[0])
        // console.log('getClub', subpaths[0])
        await dispatch('club/getClub')
      }
    }

    await dispatch('i18nStore/init', ctx)

    await dispatch('categories/getAvailableCategories')
  },
}
