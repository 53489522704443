export default {
  methods: {
    async getDynamicContent({ $store, contents = [], getAll = false }) {
      let locale = $store?.getters?.['i18nStore/langCode'](true)
      // console.log('getDynamicContent', locale)
      let toUpdateContents = null
      if (contents?.length) {
        toUpdateContents = $store?.getters?.['dynamicContent/contentNeedsUpdate']({
          contents,
          locale,
        })
      }
      let fullContentStored = $store?.state?.dynamicContent.fullContentStored
      if ((getAll && !fullContentStored) || toUpdateContents?.length) {
        // console.log(
        //   getAll ? `Get all content for ${contents?.join(', ')}` : `Needs dynamicContent - ${toUpdateContents}`
        // )
        await $store?.dispatch('dynamicContent/fetchDynamicContent', {
          content: getAll ? contents : toUpdateContents,
          locale,
          getAll,
        })
      }
    },
  },
}
