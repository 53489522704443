
import Trustpilot from '@/mixins/trustpilot-mixin'
import API from '@/mixins/sp-api-mixin'
import DownloadApp from '@/mixins/download-app-mixin'
import i18nMixin from '@/mixins/i18n-mixin'
import Mobile from '@/mixins/mobile-mixin'

import SocialIcons from '@/components/SocialIcons/SocialIcons'

export default {
  name: 'FooterIndex',
  components: {
    SocialIcons,
  },
  mixins: [API, DownloadApp, Trustpilot, i18nMixin, Mobile],
  props: {
    partnerPage: { type: Boolean, default: false },
    proPage: { type: Boolean, default: false },
  },
  data() {
    return {
      email: null,
    }
  },
  computed: {
    rawLanding() {
      return (
        this.$store.getters['landingDownload/landingDownload'] || this.$store.getters['landingDownload/landingDynamic']
      )
    },
    footerAbout() {
      return this.country?.footerAbout[this.locale]
    },
    contactFormLink() {
      return this.country?.contactFormLink[this.locale]
    },
    demoLink() {
      return this.country?.demoLink[this.locale]
    },
    faqLink() {
      return this.country?.faqLink[this.locale]
    },
  },
  methods: {
    submitEmail() {
      if (this.email && this.email !== '') {
        this.spRequest({
          req: this.$api.prospect.subscribeNewsletter(this.email),
          loader: false,
        }).then(() => {
          this.$analytics.sendEvent(this.$events.GENERATE_LEAD, { form_label: 'contact' })
          this.$analytics.sendTagEvent(this.$events.TAG_PLAN.MKP.CLICK_PRODUCT, {
            stock_id: this.referenceItem?.uid,
          })
          // Cheatcode, use error to display newsletter success
          this.error = this.$t('message.subscribeSuccess')
        })
      }
    },
    getProUrl(slug = '') {
      if (process.client) {
        if (window.location.host.includes('www')) {
          return `${window.location.protocol}//${window.location.host.replace('www', 'pro')}/${slug}`
        } else if (window.location.host.split('.')[0] !== 'pro') {
          return `${window.location.protocol}//pro.${window.location.host}/${slug}`
        } else {
          return `${window.location.protocol}//${window.location.host}/${slug}`
        }
      }
    },
  },
}
