import Repository from './repository'

export default class LocationRepository extends Repository {
  async findLocation(location) {
    const url = `${this.backendUrl}location`
    const config = {
      params: {
        address: location,
      },
    }
    return this.axios.get(url, config).then(response => response.data.location)
  }
}
