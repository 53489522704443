import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12a305ee = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _5e5a43b3 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _3f9d0307 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _3fed875d = () => interopDefault(import('../pages/cgu.vue' /* webpackChunkName: "pages/cgu" */))
const _442d2942 = () => interopDefault(import('../pages/cguMobile.vue' /* webpackChunkName: "pages/cguMobile" */))
const _3ffb9ede = () => interopDefault(import('../pages/cgv.vue' /* webpackChunkName: "pages/cgv" */))
const _72ce1e20 = () => interopDefault(import('../pages/cgvMobile.vue' /* webpackChunkName: "pages/cgvMobile" */))
const _08a6f4ca = () => interopDefault(import('../pages/club-domain/index.vue' /* webpackChunkName: "pages/club-domain/index" */))
const _2c0320d6 = () => interopDefault(import('../pages/coming-soon/index.vue' /* webpackChunkName: "pages/coming-soon/index" */))
const _63c33f63 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _735d1010 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _677bce50 = () => interopDefault(import('../pages/mission.vue' /* webpackChunkName: "pages/mission" */))
const _2bba6452 = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _59294658 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _45f90b96 = () => interopDefault(import('../pages/privacyMobile.vue' /* webpackChunkName: "pages/privacyMobile" */))
const _87b58c6a = () => interopDefault(import('../pages/pro-domain/index.vue' /* webpackChunkName: "pages/pro-domain/index" */))
const _699d0e4b = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _16604df2 = () => interopDefault(import('../pages/qrcode.vue' /* webpackChunkName: "pages/qrcode" */))
const _7cc6761e = () => interopDefault(import('../pages/root-domain/index.vue' /* webpackChunkName: "pages/root-domain/index" */))
const _3fa3dfff = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _07096b21 = () => interopDefault(import('../pages/cart/checkout.vue' /* webpackChunkName: "pages/cart/checkout" */))
const _0944a61e = () => interopDefault(import('../pages/cart/login.vue' /* webpackChunkName: "pages/cart/login" */))
const _16e747a6 = () => interopDefault(import('../pages/cart/payment_complete.vue' /* webpackChunkName: "pages/cart/payment_complete" */))
const _3892101e = () => interopDefault(import('../pages/category/best-deals/index.vue' /* webpackChunkName: "pages/category/best-deals/index" */))
const _65fb042b = () => interopDefault(import('../pages/category/best-sellers/index.vue' /* webpackChunkName: "pages/category/best-sellers/index" */))
const _3743f72b = () => interopDefault(import('../pages/category/green-everyday/index.vue' /* webpackChunkName: "pages/category/green-everyday/index" */))
const _e3ea049e = () => interopDefault(import('../pages/category/new-products/index.vue' /* webpackChunkName: "pages/category/new-products/index" */))
const _117662ce = () => interopDefault(import('../pages/category/rooms/index.vue' /* webpackChunkName: "pages/category/rooms/index" */))
const _1a29e05c = () => interopDefault(import('../pages/category/seasonal-selections/index.vue' /* webpackChunkName: "pages/category/seasonal-selections/index" */))
const _847629d8 = () => interopDefault(import('../pages/category/special-discounts/index.vue' /* webpackChunkName: "pages/category/special-discounts/index" */))
const _6f9bca63 = () => interopDefault(import('../pages/login/activation/index.vue' /* webpackChunkName: "pages/login/activation/index" */))
const _1a920073 = () => interopDefault(import('../pages/login/set-password/index.vue' /* webpackChunkName: "pages/login/set-password/index" */))
const _6d667f17 = () => interopDefault(import('../pages/my-account/orders/index.vue' /* webpackChunkName: "pages/my-account/orders/index" */))
const _ef67c1ae = () => interopDefault(import('../pages/my-account/quotes/index.vue' /* webpackChunkName: "pages/my-account/quotes/index" */))
const _5ff0c6e8 = () => interopDefault(import('../pages/my-account/quotes-sales/index.vue' /* webpackChunkName: "pages/my-account/quotes-sales/index" */))
const _5b0cbaf0 = () => interopDefault(import('../pages/my-account/sales/index.vue' /* webpackChunkName: "pages/my-account/sales/index" */))
const _518eecc8 = () => interopDefault(import('../pages/pro-domain/contact/index.vue' /* webpackChunkName: "pages/pro-domain/contact/index" */))
const _40e73eea = () => interopDefault(import('../pages/pro-domain/tarifs/index.vue' /* webpackChunkName: "pages/pro-domain/tarifs/index" */))
const _6c7d96ec = () => interopDefault(import('../pages/root-domain/download.vue' /* webpackChunkName: "pages/root-domain/download" */))
const _7e804114 = () => interopDefault(import('../pages/root-domain/download-premium.vue' /* webpackChunkName: "pages/root-domain/download-premium" */))
const _1e4d1026 = () => interopDefault(import('../pages/root-domain/landing/index.vue' /* webpackChunkName: "pages/root-domain/landing/index" */))
const _24c33765 = () => interopDefault(import('../pages/root-domain/stock/index.vue' /* webpackChunkName: "pages/root-domain/stock/index" */))
const _65f80f01 = () => interopDefault(import('../pages/root-domain/subscription.vue' /* webpackChunkName: "pages/root-domain/subscription" */))
const _317b6671 = () => interopDefault(import('../pages/root-domain/landing/boost-my-sales.vue' /* webpackChunkName: "pages/root-domain/landing/boost-my-sales" */))
const _8864f2b8 = () => interopDefault(import('../pages/root-domain/my-account/mkp-alerts/index.vue' /* webpackChunkName: "pages/root-domain/my-account/mkp-alerts/index" */))
const _a4ab2166 = () => interopDefault(import('../pages/root-domain/my-account/onboarding/index.vue' /* webpackChunkName: "pages/root-domain/my-account/onboarding/index" */))
const _8e3562a0 = () => interopDefault(import('../pages/cart/confirmation/bancontact/_uid.vue' /* webpackChunkName: "pages/cart/confirmation/bancontact/_uid" */))
const _6df202cc = () => interopDefault(import('../pages/cart/confirmation/ideal/_uid.vue' /* webpackChunkName: "pages/cart/confirmation/ideal/_uid" */))
const _64b37164 = () => interopDefault(import('../pages/my-account/sales/issues/_uid.vue' /* webpackChunkName: "pages/my-account/sales/issues/_uid" */))
const _78c19bb4 = () => interopDefault(import('../pages/root-domain/my-account/mkp-alerts/_uid/unsubscribe/index.vue' /* webpackChunkName: "pages/root-domain/my-account/mkp-alerts/_uid/unsubscribe/index" */))
const _13ccd304 = () => interopDefault(import('../pages/root-domain/my-account/mkp-alerts/_uid/unsubscribe/email/_email.vue' /* webpackChunkName: "pages/root-domain/my-account/mkp-alerts/_uid/unsubscribe/email/_email" */))
const _054c76e5 = () => interopDefault(import('../pages/blog/category/_category.vue' /* webpackChunkName: "pages/blog/category/_category" */))
const _241ef7c9 = () => interopDefault(import('../pages/blog/tag/_tag.vue' /* webpackChunkName: "pages/blog/tag/_tag" */))
const _2ba7d940 = () => interopDefault(import('../pages/cart/confirmation/_uid.vue' /* webpackChunkName: "pages/cart/confirmation/_uid" */))
const _36db6ed6 = () => interopDefault(import('../pages/category/best-deals/_slug.vue' /* webpackChunkName: "pages/category/best-deals/_slug" */))
const _644462e3 = () => interopDefault(import('../pages/category/best-sellers/_slug.vue' /* webpackChunkName: "pages/category/best-sellers/_slug" */))
const _5ac34b55 = () => interopDefault(import('../pages/category/brand/_slug.vue' /* webpackChunkName: "pages/category/brand/_slug" */))
const _e757472e = () => interopDefault(import('../pages/category/new-products/_slug.vue' /* webpackChunkName: "pages/category/new-products/_slug" */))
const _386f9892 = () => interopDefault(import('../pages/download/monthly-report/_token.vue' /* webpackChunkName: "pages/download/monthly-report/_token" */))
const _70085e59 = () => interopDefault(import('../pages/download/white-book/_token.vue' /* webpackChunkName: "pages/download/white-book/_token" */))
const _ada16148 = () => interopDefault(import('../pages/my-account/orders/_uid.vue' /* webpackChunkName: "pages/my-account/orders/_uid" */))
const _8d776d2a = () => interopDefault(import('../pages/my-account/quotes-sales/_uid.vue' /* webpackChunkName: "pages/my-account/quotes-sales/_uid" */))
const _25ec650a = () => interopDefault(import('../pages/my-account/quotes/_uid.vue' /* webpackChunkName: "pages/my-account/quotes/_uid" */))
const _bf54973a = () => interopDefault(import('../pages/my-account/sales/_uid.vue' /* webpackChunkName: "pages/my-account/sales/_uid" */))
const _4327b029 = () => interopDefault(import('../pages/pro-domain/offers/_slug.vue' /* webpackChunkName: "pages/pro-domain/offers/_slug" */))
const _12529e70 = () => interopDefault(import('../pages/pro-domain/services/_slug.vue' /* webpackChunkName: "pages/pro-domain/services/_slug" */))
const _1c966ede = () => interopDefault(import('../pages/root-domain/landing/_slug.vue' /* webpackChunkName: "pages/root-domain/landing/_slug" */))
const _45849158 = () => interopDefault(import('../pages/root-domain/partner/_partner.vue' /* webpackChunkName: "pages/root-domain/partner/_partner" */))
const _d35dc060 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _bed68318 = () => interopDefault(import('../pages/brand/_brand.vue' /* webpackChunkName: "pages/brand/_brand" */))
const _14e7201d = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _424095aa = () => interopDefault(import('../pages/merchant/_slug/index.vue' /* webpackChunkName: "pages/merchant/_slug/index" */))
const _aaedf032 = () => interopDefault(import('../pages/press/_article.vue' /* webpackChunkName: "pages/press/_article" */))
const _2ef2b094 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _229b0a74 = () => interopDefault(import('../pages/search/_keywords.vue' /* webpackChunkName: "pages/search/_keywords" */))
const _40abeb39 = () => interopDefault(import('../pages/merchant/_slug/about-us/index.vue' /* webpackChunkName: "pages/merchant/_slug/about-us/index" */))
const _0213803f = () => interopDefault(import('../pages/merchant/_slug/products/index.vue' /* webpackChunkName: "pages/merchant/_slug/products/index" */))
const _b05f53f6 = () => interopDefault(import('../pages/merchant/_slug/products/search/_keywords.vue' /* webpackChunkName: "pages/merchant/_slug/products/search/_keywords" */))
const _45e17215 = () => interopDefault(import('../pages/merchant/_slug/products/_slugCategory.vue' /* webpackChunkName: "pages/merchant/_slug/products/_slugCategory" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _12a305ee,
    name: "blog"
  }, {
    path: "/brands",
    component: _5e5a43b3,
    name: "brands"
  }, {
    path: "/cart",
    component: _3f9d0307,
    name: "cart"
  }, {
    path: "/cgu",
    component: _3fed875d,
    name: "cgu"
  }, {
    path: "/cguMobile",
    component: _442d2942,
    name: "cguMobile"
  }, {
    path: "/cgv",
    component: _3ffb9ede,
    name: "cgv"
  }, {
    path: "/cgvMobile",
    component: _72ce1e20,
    name: "cgvMobile"
  }, {
    path: "/club-domain",
    component: _08a6f4ca,
    name: "club-domain"
  }, {
    path: "/coming-soon",
    component: _2c0320d6,
    name: "coming-soon"
  }, {
    path: "/dashboard",
    component: _63c33f63,
    name: "dashboard"
  }, {
    path: "/login",
    component: _735d1010,
    name: "login"
  }, {
    path: "/mission",
    component: _677bce50,
    name: "mission"
  }, {
    path: "/press",
    component: _2bba6452,
    name: "press"
  }, {
    path: "/privacy",
    component: _59294658,
    name: "privacy"
  }, {
    path: "/privacyMobile",
    component: _45f90b96,
    name: "privacyMobile"
  }, {
    path: "/pro-domain",
    component: _87b58c6a,
    name: "pro-domain"
  }, {
    path: "/products",
    component: _699d0e4b,
    name: "products"
  }, {
    path: "/qrcode",
    component: _16604df2,
    name: "qrcode"
  }, {
    path: "/root-domain",
    component: _7cc6761e,
    name: "root-domain"
  }, {
    path: "/signup",
    component: _3fa3dfff,
    name: "signup"
  }, {
    path: "/cart/checkout",
    component: _07096b21,
    name: "cart-checkout"
  }, {
    path: "/cart/login",
    component: _0944a61e,
    name: "cart-login"
  }, {
    path: "/cart/payment_complete",
    component: _16e747a6,
    name: "cart-payment_complete"
  }, {
    path: "/category/best-deals",
    component: _3892101e,
    name: "category-best-deals"
  }, {
    path: "/category/best-sellers",
    component: _65fb042b,
    name: "category-best-sellers"
  }, {
    path: "/category/green-everyday",
    component: _3743f72b,
    name: "category-green-everyday"
  }, {
    path: "/category/new-products",
    component: _e3ea049e,
    name: "category-new-products"
  }, {
    path: "/category/rooms",
    component: _117662ce,
    name: "category-rooms"
  }, {
    path: "/category/seasonal-selections",
    component: _1a29e05c,
    name: "category-seasonal-selections"
  }, {
    path: "/category/special-discounts",
    component: _847629d8,
    name: "category-special-discounts"
  }, {
    path: "/login/activation",
    component: _6f9bca63,
    name: "login-activation"
  }, {
    path: "/login/set-password",
    component: _1a920073,
    name: "login-set-password"
  }, {
    path: "/my-account/orders",
    component: _6d667f17,
    name: "my-account-orders"
  }, {
    path: "/my-account/quotes",
    component: _ef67c1ae,
    name: "my-account-quotes"
  }, {
    path: "/my-account/quotes-sales",
    component: _5ff0c6e8,
    name: "my-account-quotes-sales"
  }, {
    path: "/my-account/sales",
    component: _5b0cbaf0,
    name: "my-account-sales"
  }, {
    path: "/pro-domain/contact",
    component: _518eecc8,
    name: "pro-domain-contact"
  }, {
    path: "/pro-domain/tarifs",
    component: _40e73eea,
    name: "pro-domain-tarifs"
  }, {
    path: "/root-domain/download",
    component: _6c7d96ec,
    name: "root-domain-download"
  }, {
    path: "/root-domain/download-premium",
    component: _7e804114,
    name: "root-domain-download-premium"
  }, {
    path: "/root-domain/landing",
    component: _1e4d1026,
    name: "root-domain-landing"
  }, {
    path: "/root-domain/stock",
    component: _24c33765,
    name: "root-domain-stock"
  }, {
    path: "/root-domain/subscription",
    component: _65f80f01,
    name: "root-domain-subscription"
  }, {
    path: "/root-domain/landing/boost-my-sales",
    component: _317b6671,
    name: "root-domain-landing-boost-my-sales"
  }, {
    path: "/root-domain/my-account/mkp-alerts",
    component: _8864f2b8,
    name: "root-domain-my-account-mkp-alerts"
  }, {
    path: "/root-domain/my-account/onboarding",
    component: _a4ab2166,
    name: "root-domain-my-account-onboarding"
  }, {
    path: "/cart/confirmation/bancontact/:uid?",
    component: _8e3562a0,
    name: "cart-confirmation-bancontact-uid"
  }, {
    path: "/cart/confirmation/ideal/:uid?",
    component: _6df202cc,
    name: "cart-confirmation-ideal-uid"
  }, {
    path: "/my-account/sales/issues/:uid",
    component: _64b37164,
    name: "my-account-sales-issues-uid"
  }, {
    path: "/root-domain/my-account/mkp-alerts/:uid?/unsubscribe",
    component: _78c19bb4,
    name: "root-domain-my-account-mkp-alerts-uid-unsubscribe"
  }, {
    path: "/root-domain/my-account/mkp-alerts/:uid?/unsubscribe/email/:email",
    component: _13ccd304,
    name: "root-domain-my-account-mkp-alerts-uid-unsubscribe-email-email"
  }, {
    path: "/blog/category/:category?",
    component: _054c76e5,
    name: "blog-category-category"
  }, {
    path: "/blog/tag/:tag?",
    component: _241ef7c9,
    name: "blog-tag-tag"
  }, {
    path: "/cart/confirmation/:uid?",
    component: _2ba7d940,
    name: "cart-confirmation-uid"
  }, {
    path: "/category/best-deals/:slug?",
    component: _36db6ed6,
    name: "category-best-deals-slug"
  }, {
    path: "/category/best-sellers/:slug?",
    component: _644462e3,
    name: "category-best-sellers-slug"
  }, {
    path: "/category/brand/:slug?",
    component: _5ac34b55,
    name: "category-brand-slug"
  }, {
    path: "/category/new-products/:slug?",
    component: _e757472e,
    name: "category-new-products-slug"
  }, {
    path: "/download/monthly-report/:token?",
    component: _386f9892,
    name: "download-monthly-report-token"
  }, {
    path: "/download/white-book/:token?",
    component: _70085e59,
    name: "download-white-book-token"
  }, {
    path: "/my-account/orders/:uid?",
    component: _ada16148,
    name: "my-account-orders-uid"
  }, {
    path: "/my-account/quotes-sales/:uid?",
    component: _8d776d2a,
    name: "my-account-quotes-sales-uid"
  }, {
    path: "/my-account/quotes/:uid?",
    component: _25ec650a,
    name: "my-account-quotes-uid"
  }, {
    path: "/my-account/sales/:uid?",
    component: _bf54973a,
    name: "my-account-sales-uid"
  }, {
    path: "/pro-domain/offers/:slug",
    component: _4327b029,
    name: "pro-domain-offers-slug"
  }, {
    path: "/pro-domain/services/:slug",
    component: _12529e70,
    name: "pro-domain-services-slug"
  }, {
    path: "/root-domain/landing/:slug",
    component: _1c966ede,
    name: "root-domain-landing-slug"
  }, {
    path: "/root-domain/partner/:partner",
    component: _45849158,
    name: "root-domain-partner-partner"
  }, {
    path: "/blog/:article",
    component: _d35dc060,
    name: "blog-article"
  }, {
    path: "/brand/:brand?",
    component: _bed68318,
    name: "brand-brand"
  }, {
    path: "/category/:slug?",
    component: _14e7201d,
    name: "category-slug"
  }, {
    path: "/merchant/:slug",
    component: _424095aa,
    name: "merchant-slug"
  }, {
    path: "/press/:article",
    component: _aaedf032,
    name: "press-article"
  }, {
    path: "/product/:slug?",
    component: _2ef2b094,
    name: "product-slug"
  }, {
    path: "/search/:keywords?",
    component: _229b0a74,
    name: "search-keywords"
  }, {
    path: "/merchant/:slug?/about-us",
    component: _40abeb39,
    name: "merchant-slug-about-us"
  }, {
    path: "/merchant/:slug?/products",
    component: _0213803f,
    name: "merchant-slug-products"
  }, {
    path: "/merchant/:slug?/products/search/:keywords?",
    component: _b05f53f6,
    name: "merchant-slug-products-search-keywords"
  }, {
    path: "/merchant/:slug?/products/:slugCategory",
    component: _45e17215,
    name: "merchant-slug-products-slugCategory"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
