import Reference from '@/models/Reference'
import Category from '@/models/Category'
import truncate from 'lodash.truncate'
import uniqBy from 'lodash.uniqby'
import { getHrefLang, defaultHeadMeta } from '@/utils/defaultHead'
import defaultHead from '@/utils/defaultHead'

export default {
  data() {
    return {
      customScript: [],
      defaultTitle: this.$country?.metaTitle?.[this.$locale],
      defaultDescription: this.$country?.metaDescription?.[this.$locale],
      defaultMetaHeadMx: [
        ...defaultHeadMeta.map(meta => {
          if (meta.name === 'title') {
            meta.content = this.$country?.metaTitle?.[this.$locale]
          }
          if (meta.name === 'og:title') {
            meta.content = this.$country?.metaTitle?.[this.$locale]
          }
          if (meta.name === 'description') {
            meta.content = this.$country?.metaDescription?.[this.$locale]
          }
          if (meta.name === 'og:description') {
            meta.content = this.$country?.metaDescription?.[this.$locale]
          }
          return meta
        }),
      ],
    }
  },
  methods: {
    hrefLangAndCanonical() {
      return getHrefLang({
        $route: this.$route,
        localePath: this.localePath,
        locale: this.$locale,
        country: this.$country,
        reference: this.reference,
        category: this.category || this.currentCategory,
        brand: this.brand || this.currentBrand,
        merchant: this.currentMerchant,
        pageData: this.$store.state?.config?.config?.pageData,
      })
    },
    hreflang() {
      return this.hrefLangAndCanonical()?.hreflang || []
    },
    canonical() {
      return this.hrefLangAndCanonical()?.canonical || {}
    },
    head() {
      return defaultHead(this.$locale)
    },
    default(key, target = null, dynamicMeta) {
      const isClub = this.$store.getters['club/isClub']
      let title =
        dynamicMeta?.title ??
        (dynamicMeta?.name ? `${dynamicMeta?.name} | StockPro` : undefined) ??
        this.defaultTitle ??
        this.$t(`meta.title.${key}`, {
          [key]: target ? target : isClub ? truncate(this.$store.state.club.name, { length: 70 }) : 'StockPro',
        })
      let description =
        dynamicMeta?.description ??
        (dynamicMeta?.name ? `${dynamicMeta?.name} - ${this.defaultDescription}` : undefined) ??
        this.defaultDescription ??
        this.$t(`meta.description.${key}`, { [key]: truncate(target, { length: 70 }) })
      const meta = [
        { hid: 'title', property: 'title', content: title },
        { hid: 'og:title', property: 'og:title', content: title },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
      ]
      const uniqueMeta = uniqBy([...meta, ...this.defaultMetaHeadMx], 'hid')
      return {
        ...this.head(),
        link: [...(this.hreflang() || []), this.canonical()],
        title,
        meta: uniqueMeta,
        script: [...this.customScript, ...this.head().script],
      }
    },
    paginationHead(key, target = null, items, dynamicMeta) {
      let links = []
      const { name, params, query } = this.$route
      const currentPage = this.$query.getPage()
      if (currentPage > 1) {
        // link = [{ rel: 'canonical', href: `${this.localePath({ name, query, params })}` }]
      }
      if (currentPage > 1 && (items === undefined || items === null || items.length === 0)) {
        // Prev link when pagination went to far
        query.page = this.totalPages
        links.push({ rel: 'prev', href: `${this.localePath({ name, query, params })}` })
      } else {
        // Prev & next link of pagination
        if (currentPage > 1) {
          query.page = currentPage - 1
          links.push({ rel: 'prev', href: `${this.localePath({ name, query, params })}` })
        }
        if (currentPage < this.totalPages) {
          query.page = currentPage + 1
          links.push({ rel: 'next', href: `${this.localePath({ name, query, params })}` })
        }
        query.page = currentPage
      }
      const d = this.default(key, target, dynamicMeta)
      return {
        ...d,
        link: [...d.link, ...links],
      }
    },
    homeHead() {
      const d = this.default('home')
      return {
        ...d,
        script: [...d.script, { type: 'application/ld+json', json: this.corporationSchema() }],
      }
    },
    productHead() {
      const meta = [
        { hid: 'title', property: 'title', content: `${this.reference.name} | StockPro` },
        { hid: 'og:url', property: 'og:url', content: `${process.env.PRODUCTION_URL}${this.$route.path.substr(1)}` },
        { hid: 'og:title', property: 'og:title', content: `${this.reference.name} | StockPro` },
        { hid: 'og:type', property: 'og:type', content: 'article' },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('meta.description.product', {
            product: truncate(this.reference.name, { length: 70 }),
          }),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description.product', {
            product: truncate(this.reference.name, { length: 70 }),
          }),
        },
      ]
      if (this.reference.images !== null && this.reference.images !== undefined && this.reference.images.length > 0) {
        meta.push({ hid: 'og:image:secure', property: 'og:image:secure', content: this.reference.thumbnailUrl })
      }
      const d = this.default('home')
      const uniqueMeta = uniqBy([...meta, ...d.meta], 'hid')
      return {
        ...d,
        meta: uniqueMeta,
        title: `${this.reference.name} | StockPro`,
        script: [
          ...d.script,
          { type: 'application/ld+json', json: this.productBreadcrumbSchema(this.rawReference) },
          {
            type: 'application/ld+json',
            json: this.productSchema(
              `${process.env.PRODUCTION_URL}${this.$route.path.substr(1)}`,
              this.rawReference,
              this.$store.state.i18nStore.country.code
            ),
          },
        ],
      }
    },
    categoryHead(dynamicMeta) {
      let link = []
      if (!this.currentCategory?.hasChildren) {
        // Case for category products list page
        const { name, params, query } = this.$route
        const currentPage = this.$query.getPage()
        if (currentPage > 1) {
          // link = [{ rel: 'canonical', href: `${this.localePath({ name, query, params })}` }]
        }
        if (
          currentPage > 1 &&
          (this.references === undefined || this.references === null || this.references.length === 0)
        ) {
          // Prev link when pagination went to far
          query.page = this.totalPages
          link.push({ rel: 'prev', href: `${this.localePath({ name, query, params })}` })
        } else {
          // Prev & next link of pagination
          if (currentPage > 1) {
            query.page = currentPage - 1
            link.push({ rel: 'prev', href: `${this.localePath({ name, query, params })}` })
          }
          if (currentPage < this.totalPages) {
            query.page = currentPage + 1
            link.push({ rel: 'next', href: `${this.localePath({ name, query, params })}` })
          }
          query.page = currentPage
        }
      }
      const d = this.default('home')
      const uniqueMeta = d.meta.map(m => {
        if (m.hid === 'description') {
          return {
            hid: 'description',
            name: 'description',
            content:
              dynamicMeta?.description ||
              this.$t('meta.description.category', {
                category: truncate(this.currentCategory?.name, { length: 70 }),
              }),
          }
        }
        return m
      })
      return {
        ...d,
        title: dynamicMeta?.title || `${truncate(this.currentCategory?.name, { length: 70 })} | StockPro`,
        link: [...d.link, ...link],
        meta: [...uniqueMeta],
        script: [
          ...d.script,
          { type: 'application/ld+json', json: this.categoryBreadcrumbSchema(this.currentCategory) },
        ],
      }
    },
    blogHead() {
      const title = `${
        this.article?.meta_title || this.article?.title || this.article?.name || this.$t('blog.sectionBtnLabel')
      } | StockPro`
      const description = truncate(this.article?.description || this.article?.shortDescription, { length: 160 })
      const content = [
        this.richtextRender,
        ...(this.article?.content?.blocks || [])?.map(x =>
          x?.text ? this.$storyapi.richTextResolver.render(x.text) : null
        ),
      ].filter(x => x)
      const meta = [
        { hid: 'title', property: 'title', content: title },
        { hid: 'og:url', property: 'og:url', content: `${process.env.PRODUCTION_URL}${this.$route.path.substr(1)}` },
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'og:type', property: 'og:type', content: 'article' },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
      ]
      const d = this.default('blog')
      const uniqueMeta = uniqBy([...meta, ...d.meta], 'hid')
      return {
        ...d,
        meta: uniqueMeta,
        link: [
          ...d.link,
          {
            rel: 'preload',
            href: `${this.article?.mainImage?.filename || this.article?.mainImage?.url || this.article?.mainImage}`,
            as: 'image',
          },
        ],
        title,
        description,
        script: [
          ...d.script,
          {
            type: 'application/ld+json',
            json: this.blogArticleSchema(this.article || {}, this.updatedDate, this.relatedTags, content?.join('/n')),
          },
        ],
      }
    },
    productSchema(url, rawReference, country) {
      const reference = new Reference(rawReference, country)
      const schema = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: reference.name,
        image: reference.mainImageUrl,
        description: reference.description,
        brand: reference.brandName,
      }
      if (reference.ean !== null || reference.ean !== undefined) {
        schema.sku = reference.ean
      } else if (reference.refCo !== null || reference.refCo !== undefined) {
        schema.sku = reference.refCo
      }
      schema.offers = {
        '@type': 'Offer',
        url,
        availability: reference.hasStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock"',
      }
      if (schema.sku !== null || schema.sku !== undefined) {
        schema.offers.sku = schema.ean
      }
      if (reference.hasStock && reference.bestStock) {
        schema.offers.priceCurrency = 'EUR'
        // APPLY FRENCH VAT
        schema.offers.price = reference.bestStock?.price_vat
        schema.offers.eligibleQuantity = reference.bestStock?.quantity
      }
      return schema
    },
    productBreadcrumbSchema(reference, country) {
      const items = new Category(reference.category, country).breadcrumbPath.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'WebPage',
          '@id': `${process.env.PRODUCTION_URL}${this.localePath({
            name: 'category-slug',
            params: { slug: item.slug },
          }).substr(1)}`,
          url: `${process.env.PRODUCTION_URL}${this.localePath({
            name: 'category-slug',
            params: { slug: item.slug },
          }).substr(1)}`,
          name: item.name,
        },
      }))
      items.push({
        '@type': 'ListItem',
        position: items.length + 1,
        item: {
          '@type': 'WebPage',
          '@id': `${process.env.PRODUCTION_URL}${this.$route.path.substr(1)}`,
          url: `${process.env.PRODUCTION_URL}${this.$route.path.substr(1)}`,
          name: reference.name,
        },
      })
      return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items,
      }
    },
    categoryBreadcrumbSchema(category) {
      const items = category.breadcrumbPath.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'WebPage',
          '@id': `${process.env.PRODUCTION_URL}${this.localePath({
            name: 'category-slug',
            params: { slug: item.slug },
          }).substr(1)}`,
          url: `${process.env.PRODUCTION_URL}${this.localePath({
            name: 'category-slug',
            params: { slug: item.slug },
          }).substr(1)}`,
          name: item.name,
        },
      }))
      return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items,
      }
    },
    corporationSchema() {
      return {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'StockPro',
        url: `${process.env.PRODUCTION_URL}`,
        logo: `${process.env.PRODUCTION_URL}logo.svg`,
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '0177623880',
          contactType: 'customer service',
        },
        sameAs: [
          'https://www.facebook.com/stockprofrance/',
          'https://linkedin.com/company/stockpro',
          'https://www.youtube.com/channel/UCTvHKa_JcH4Oyn41h7qB7Tg',
          'https://stock-pro.fr',
          'https://twitter.com/francestockpro',
        ],
      }
    },
    blogArticleSchema(article, updatedDate, relatedTags, fullArticle) {
      const articleKeywords = relatedTags?.map(tag => tag?.content?.title).join(' ')
      return {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        headline: article?.title,
        image: article?.mainImage?.filename || article?.mainImage?.url || article?.mainImage,
        editor: article?.author === '' ? 'StockPro' : article?.author,
        genre: article?.category?.content?.name,
        publisher: 'StockPro',
        keywords: articleKeywords,
        url: article?.full_slug,
        datePublished: article?.published_at,
        dateCreated: article?.created_at,
        dateModified: updatedDate,
        description: article?.shortDescription || fullArticle.substr(0, 200),
        articleBody: fullArticle,
        author: {
          '@type': 'Person',
          name: article?.author === '' ? 'StockPro' : article?.author,
        },
      }
    },
  },
}
