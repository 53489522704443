import Repository from './repository'

export default class MkpAlertRepository extends Repository {
  async getMkpAlerts(userUid, numberPerPage = 10, page = 1, filters = [], mapping = this.mapper.basicMapping) {
    const url = `${this.backendUrl}users/${userUid}/mkp_alerts`
    const config = {
      headers: {
        Mapping: mapping,
      },
      params: {
        per_page: numberPerPage,
        page,
        sortBy: 'created_at',
        sortDesc: true,
        included_items: 'UNARCHIVED',
      },
    }
    if (filters && filters.length > 0) {
      const archivedFilter = filters.find(f => f.type === 'ARCHIVED')
      if (archivedFilter && archivedFilter.value) config.params.included_items = 'ARCHIVED'
      const sellerFilter = filters.find(f => f.type === 'SELLER')
      if (sellerFilter && sellerFilter.value) {
        config.params['filter_by[sellers]'] = [sellerFilter.value]
      }
    }
    return this.axios.get(url, config).then(response => {
      return response.data
    })
  }

  async get(userUid, id, mapping = this.mapper.basicMapping) {
    const url = `${this.backendUrl}users/${userUid}/mkp_alerts/${id}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.axios.get(url, config).then(response => {
      return response.data.results
    })
  }

  async create(userUid, mkpAlert, withEmail = false) {
    const url = `${this.backendUrl}users/${userUid}/mkp_alerts${withEmail ? '/email' : ''}`

    return this.axios.post(url, { mkp_alert: mkpAlert }).then(response => {
      return response.data.result
    })
  }

  async update(userUid, id, mkpAlert) {
    const url = `${this.backendUrl}users/${userUid}/mkp_alerts/${id}`

    return this.axios.put(url, { mkp_alert: mkpAlert }).then(response => {
      return response.data.result
    })
  }

  async archive(userUid, id, withEmail = false) {
    if (withEmail) {
      const url = `${this.backendUrl}users/${userUid}/mkp_alerts/${id}/email`
      return this.axios.delete(url).then(response => {
        return response
      })
    } else {
      return this.update(userUid, id, { is_archived: true })
    }
  }

  async delete(userUid, id) {
    const url = `${this.backendUrl}users/${userUid}/mkp_alerts/${id}/unsubscribe`

    return this.axios.delete(url).then(response => {
      return response.success
    })
  }
}
