import CartManager from '@/services/CartManager'
import { currentCountry } from '@/utils/currentLocale'

export default (ctx, inject) => {
  let country = (ctx?.$store || ctx?.store).state?.i18nStore?.country
  // console.log('store', (ctx?.$store || ctx?.store).state?.i18nStore)
  if (!country) {
    // console.log('no country')
    country = currentCountry(ctx)
    ;(ctx?.$store || ctx?.store).commit('i18nStore/setCountry', country)
  }
  inject('cart', new CartManager(ctx?.$store || ctx?.store, country))
}
