
import { mapState, mapGetters } from 'vuex'
import Fuse from 'fuse.js'

import API from '@/mixins/sp-api-mixin'
import Mobile from '@/mixins/mobile-mixin'
import dynamicContentMx from '@/mixins/dynamic-content-mixin'
import i18nMx from '@/mixins/i18n-mixin'

import Category from '@/models/Category'
import Brand from '@/models/Brand'
import Filter from '@/models/Filter'

export default {
  name: 'SearchBar',
  mixins: [Mobile, API, i18nMx],
  data() {
    return {
      timeout: null,
      keywords: null,
      suggestions: [],
      mkpSuggestions: [],
      categoriesSlug: [],
      brandsSlug: [],
      merchantSlug: [],
      suggestionSelected: -1,
      isFocus: false,
      clubBannerHeight: 0,
    }
  },
  computed: {
    ...mapGetters('club', ['isClub']),
    ...mapState('dynamicContent', {
      productsCount: state => state.counters.products || 0,
    }),
    categories() {
      return this.$store.state.categories.available.map(category => new Category(category, this.country))
    },
    brands() {
      return this.$store.state.brands.all.map(brand => (brand ? new Brand(brand, this.country) : null)).filter(b => b)
    },
    isSuggestionsVisible() {
      return this.isFocus && this.keywords !== undefined && this.keywords !== null && this.keywords.length >= 3
    },
    isOnlyOverlayVisible() {
      return this.isFocus && this.isMd
    },
    // STOCK or REFERENCE
    searchMode() {
      if (this.$route.name?.includes('stock')) {
        return 'STOCK'
      }
      return 'REFERENCE'
    },
    searchBarPlaceholder() {
      if (!this.isMd) {
        if (this.isClub) {
          return this.$t('fields.searchClub')
        } else {
          return this.searchMode === 'STOCK' ? this.$t('fields.searchStock') : this.$t('fields.search')
        }
      }
      return this.$t('fields.searchName')
    },
    blogArticles() {
      return this.$store.getters['dynamicContent/blogArticles']
    },
    articlesTitle() {
      if (this.blogArticles?.length) {
        // thresold = match index (0 = perfect / 1 = match anything)
        const fuseOptions = { keys: ['content.title'], threshold: 0.3, ignoreLocation: true }
        if (this.keywords?.length) {
          const fuse = new Fuse(this.blogArticles, fuseOptions)
          const fuseSearch = fuse.search(this.keywords)
          return fuseSearch
            ?.map(article => {
              return { ...article?.item, name: article?.item?.name?.slice(0, 100) }
            })
            .slice(0, 5)
        }
      }
      return []
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from && !['category-slug', 'search-keywords'].includes(to.name)) {
        this.reset()
      }
    },
  },
  mounted() {
    this.clubBannerHeight = document.querySelector('.club-banner')?.getBoundingClientRect()?.height || 0
  },
  beforeDestroy() {
    this.clubBannerHeight = 0
    if (this.timeout !== null) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    onFocus() {
      this.isFocus = true
      this.isMd ? document.body.classList.add('no-scroll') : ''
      this.$analytics.sendTagEvent(this.$events.TAG_PLAN.SEARCH.CLICK_SEARCH_BAR)
      // get article on focus to preload list
      dynamicContentMx.methods.getDynamicContent({
        $store: this.$store,
        contents: ['BlogArticle'],
        getAll: true, // Get all content to be sure to have all articles
      })
    },
    close() {
      this.isFocus = false
      document.body.classList.remove('no-scroll')
      this.mkpSuggestions = []
    },
    onBlur() {
      const suggestionsDiv = document.querySelector('.search-autocomplete-content:hover')
      if (suggestionsDiv !== null && suggestionsDiv !== undefined) {
        if (!(suggestionsDiv.length !== 0)) {
          this.close()
        }
      } else this.close()
    },
    onSearch() {
      if (this.suggestionSelected < 0) this.search(this.keywords)
    },
    onChange() {
      this.isOnlyOverlayVisible || this.isSuggestionsVisible
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll')

      this.$bus.$emit(this.$events.CLOSE_BURGER_MENU)
      if (this.keywords && this.keywords.length >= 3 && this.isFocus) {
        clearTimeout(this.timeout)
        const _this = this
        // Debouncing autocompletion
        this.timeout = setTimeout(() => {
          // if (this.searchMode === 'REFERENCE') {
          //   _this.autocomplete()
          // } else {
          //   }
          _this.autocomplete()
        }, 400)
      }
    },
    search(text, mkp = false) {
      if (text) {
        if (this.searchMode === 'REFERENCE' || mkp) {
          // delete unrecognize char for search from suggestions
          let textQuery = text.name || text
          if (Array.isArray(textQuery) && textQuery.length) textQuery = textQuery.join('')
          this.$router.push(
            this.localePath({
              name: 'search-keywords',
              params: { keywords: textQuery },
            })
          )
        } else {
          this.$router.push(this.localePath({ name: 'stock', query: { ...this.$route.query, query: text, page: 1 } }))
        }
        this.reset(text.name || text)
      }
    },
    searchCategory(cat) {
      if (this.searchMode === 'REFERENCE') {
        this.$router.push(this.localePath({ name: 'category-slug', params: { slug: cat.slug } }))
      } else {
        this.$router.push(
          this.localePath({ name: 'stock', query: { ...this.$route.query, categories: cat.uid, page: 1 } })
        )
      }
      this.reset(cat.name)
    },
    searchBrand(brand) {
      if (this.searchMode === 'REFERENCE') {
        this.$router.push(this.localePath({ name: 'brand-brand', params: { brand: brand.slug } }))
      } else {
        this.$router.push(
          this.localePath({ name: 'stock', query: { ...this.$route.query, brands: brand.uid, page: 1 } })
        )
      }
      this.reset(brand.name)
    },
    openMerchant(merchant) {
      this.$router.push(this.localePath({ name: 'merchant-slug', params: { slug: merchant.slug } }))
      this.reset(merchant.name)
    },
    openReference(ref) {
      this.$router.push(this.localePath({ name: 'product-slug', params: { slug: ref.slug } }))
      this.reset(ref.name)
    },
    openArticle(article) {
      this.$router.push(this.localePath({ name: 'blog-article', params: { article: article.slug } }))
      this.reset(article.name)
    },
    reset(keyword = null) {
      this.keywords = keyword
      this.suggestionSelected = -1
      this.isFocus = false
      this.$refs.searchbar.blur()
      this.close()
    },
    autocompleteReferences() {
      const filters = [
        new Filter({
          type: 'KEYWORDS',
          value: this.keywords.trim(),
        }),
      ]
      this.spRequest({
        req: this.$api.autocomplete.getMarketplace(filters),
      }).then(results => {
        if (results !== null && results !== undefined) {
          this.mkpSuggestions = results?.references?.map(ref => {
            return { ...ref, name: ref.name.slice(0, 100) }
          })
        }
      })
    },
    async autocomplete() {
      const filters = [
        new Filter({
          type: 'KEYWORDS',
          value: this.keywords,
        }),
      ]
      let req = this.$api.autocomplete.getMarketplace(filters)
      if (this.searchMode === 'STOCK') {
        req = this.$api.autocomplete.getStock(this.$currentUser.companyUid, filters)
      }
      const results = await this.spRequest({ req })
      if (results !== null && results !== undefined) {
        if (this.searchMode === 'STOCK') {
          this.suggestions = results.stocks?.map(stock => stock.reference.name.slice(0, 100))
        } else {
          this.suggestions = results.references?.map(ref => {
            return { ...ref, name: ref.name.slice(0, 100) }
          })
        }
        this.categoriesSlug = [
          ...results.categories?.map(category => {
            return { ...category, name: category.name.slice(0, 100) }
          }),
        ]
        this.brandsSlug = [
          ...results.brands?.map(brand => {
            return { ...brand, name: brand.name.slice(0, 100) }
          }),
        ]
        this.merchantSlug = results.companies?.map(merchant => {
          return { ...merchant, name: merchant.name.slice(0, 100) }
        })
      }
    },
    matchingCategories(keywords) {
      const matches = []
      const findMatchingCategories = function (arr, query, nestingKey) {
        arr.forEach(item => {
          const name = encodeURIComponent(item.name?.toLowerCase())
          if (name.includes(query)) {
            matches.push(item)
          }
          if (item[nestingKey]) {
            findMatchingCategories(item[nestingKey], query, nestingKey)
          }
        })
      }
      findMatchingCategories(this.categories, encodeURIComponent(keywords?.toLowerCase()), 'children')
      return matches
    },
    matchingBrands(keywords) {
      const matches = []
      this.brands.forEach(item => {
        const name = encodeURIComponent(item.name?.toLowerCase())
        if (name.includes(encodeURIComponent(keywords?.toLowerCase()))) {
          matches.push(item)
        }
      })
      return matches
    },
    emphasizeKeywords(text) {
      if (text && text.name !== '' && this.keywords) {
        const re = new RegExp(`(?<=${this.keywords})|(?=${this.keywords})`, 'gi')
        return text
          .split(re)
          .map(x => (re.test(x) ? `<span class="u-font-black">${x}</span>` : x))
          .join('')
      } else {
        return null
      }
    },
  },
}
