export default class Repository {
  constructor({ axios, backendUrl, mapper, storyApi }) {
    this.axios = axios
    this.backendUrl = backendUrl
    this.backendUrlV2 = backendUrl.replace('/api/v1', '/api/v2')
    this.mapper = mapper
    this.storyApi = storyApi
    this.token = null
  }
}
