// Warning : universel nuxt cookies only accept 4kb max value for object, so pageData doesn't fit for ex.
export default ({ store, $cookies }) => {
  const savedStateUtm = $cookies.get('x-utm')
  if (savedStateUtm?.list) {
    store.commit('config/add', savedStateUtm.list)
  }
  const savedStateConfig = $cookies.get('x-config')
  if (savedStateConfig?.config) {
    store.commit('config/add', savedStateConfig.config)
  }

  store.subscribe((mutation, state) => {
    if (mutation.type.startsWith('config')) {
      $cookies.set('x-config', state.config, { maxAge: 60 * 60 * 24 * 3, secure: false })
    }
    if (mutation.type.startsWith('utm')) {
      $cookies.set('x-utm', state.utm, { maxAge: 60 * 60 * 24 * 3, secure: false })
    }
  })
}
