import Repository from './repository'

export default class ClubRepository extends Repository {
  async get(mapping = this.mapper.currentClubMapping) {
    const url = `${this.backendUrl}company_groups/current`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.axios.get(url, config).then(response => {
      if (!response.data.success) {
        // console.log(response.data.message)
        throw response.data.message
      }
      return response.data.company_group
    })
  }
}
