
import { mapGetters } from 'vuex'

import API from '@/mixins/sp-api-mixin'
import Club from '@/mixins/club-mixin'
import Mobile from '@/mixins/mobile-mixin'
import Scrollto from '@/mixins/scrollto-mixin'
import i18nMx from '@/mixins/i18n-mixin'

import MainButton from '@/components/Buttons/MainButton/MainButton'
import SbButtonsLinkHover from '@/components/storyblok/SbButtonsLinkHover'
import LangSwitcher from '@/components/I18n/LangSwitcher'

export default {
  name: 'ProHeader',
  components: {
    MainButton,
    SbButtonsLinkHover,
    LangSwitcher,
  },
  mixins: [API, Club, Mobile, Scrollto, i18nMx],
  data() {
    return {
      menuStatus: false,
      companies: [],
      showMenu: false,
      currentMenu: null,
    }
  },
  computed: {
    ...mapGetters('proArea', [
      'getStoryBySlug',
      'getOffersParentList',
      'getOffersChildList',
      'getServicesParentList',
      'getServicesChildList',
    ]),
    ...mapGetters('club', ['isClub']),
    cartQuantity() {
      return this.$cart.totalItems.toString()
    },
    user() {
      return this.$currentUser
    },
    myToolLabel() {
      if (!this.isMd) {
        if (this.user.isAuthenticated && this.user.isPro) {
          return this.$t('header.myTools')
        } else {
          return this.$t('header.marketplace')
        }
      }
      return null
    },
    displayFullMenu() {
      if (this.$store?.state?.i18nStore?.tld) {
        // Match fr or FR
        return this.$store?.state?.i18nStore?.tld?.match(/fr/i)
      }
      return true
    },
    stockButton() {
      return !this.isClub && this.user.isPro && !this.user.isFreemium
    },
  },
  methods: {
    logout() {
      this.$currentUser.logout().finally(() => {
        this.$cart.setIsReady(false)
      })
    },
    handleMenu() {
      this.menuStatus = !this.menuStatus
    },
    toggleMenu(menu, timeout = 100) {
      setTimeout(() => {
        if (this.currentMenu !== menu) {
          this.currentMenu = menu
        } else {
          this.currentMenu = null
        }
      }, timeout)
    },
    myToolsLink() {
      if (this.user.isAuthenticated && this.user.isPro) {
        this.toggleMenu('myTools', 0)
      } else {
        this.redirectToMkp()
      }
    },
    redirectToMkp(slug = '') {
      if (process.client) {
        const host = window.location.host
        let url = `www.${host.substring(host.indexOf('.') + 1)}`
        window.location.replace(`${window.location.protocol}//${url}/${slug}`)
      }
    },
    redirectToClub(club) {
      if (process.client) {
        const host = window.location.host
        window.location.replace(`${window.location.protocol}//${club.key}.${host.substring(host.indexOf('.') + 1)}`)
      }
    },
  },
}
