import Repository from './repository'

export default class UserRepository extends Repository {
  async getUser(userUid, companyUid, mapping = this.mapper.userMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.axios.get(url, config).then(response => response.data.user)
  }

  async login(emailOrPhone = {}, password, mapping = this.mapper.userMapping) {
    const url = `${this.backendUrl}sign_in`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.axios
      .post(
        url,
        {
          user: {
            ...emailOrPhone,
            password,
          },
        },
        config
      )
      .then(response => {
        if (response.status === 200) {
          const token = response.headers.authorization
          if (token !== null && token !== undefined) {
            return {
              token,
              user: response.data.user,
            }
          }
          return {
            confirmed: response.data.confirmed,
            message: response.data.message,
          }
        }
        return null
      })
  }

  async forgotPassword(emailOrPhone = {}) {
    const url = `${this.backendUrl}forgot_password`

    return this.axios
      .post(url, {
        user: emailOrPhone,
      })
      .then(response => response.data.success)
  }

  async activate(emailOrPhone, code, reset = false, mapping = this.mapper.userMapping) {
    const activateUrl = `${this.backendUrl}sign_in`
    const codeKey = reset ? 'reset_password_token' : 'confirmation_token'
    let user = {}
    if (!emailOrPhone?.login) {
      user = { login: emailOrPhone }
    } else {
      user = emailOrPhone
    }
    user[codeKey] = code
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.axios.post(activateUrl, { user }, config).then(response => {
      if (response.data.success) {
        const token = response.headers.authorization
        if (token !== null && token !== undefined) {
          return {
            token,
            user: response.data.user,
          }
        }
        return response.data.success
      }
      if (response.data.message === 'token_expired') {
        throw new Error('code_expired')
      } else if (response.data.message === 'token_invalid') {
        throw new Error('code_invalid')
      } else {
        throw new Error(response.data.message)
      }
    })
  }

  async setPassword(user, password) {
    const setPasswordUrl = `${this.backendUrl}companies/${user.company.uid}/users/${user.uid}`
    return this.axios
      .put(setPasswordUrl, {
        user: {
          password,
        },
      })
      .then(response => response.data.success)
  }

  async getAddresses(userUid) {
    const addressesUrl = `${this.backendUrl}users/${userUid}/addresses`
    return this.axios.get(addressesUrl).then(response => response.data.addresses)
  }

  async saveAddress(userUid, address) {
    let addressesUrl = `${this.backendUrl}users/${userUid}/addresses`
    let method = 'POST'
    if (address.uid !== null) {
      addressesUrl = `${addressesUrl}/${address.uid}`
      method = 'PUT'
    }
    return this.axios({
      method,
      url: addressesUrl,
      data: { address },
    }).then(response => response.data.address)
  }

  async update(userUid, companyUid, user) {
    const url = `${this.backendUrl}companies/${companyUid}/users/${userUid}`
    return this.axios.put(url, user).then(response => response.data.user)
  }

  async getCompanyUsersWithBookedStock(companyUid, mapping = this.mapper.userWithBookedStockMapping) {
    const url = `${this.backendUrl}companies/${companyUid}/users?with_booked_stocks=true`
    const config = {
      headers: {
        Mapping: mapping,
      },
    }
    return this.axios.get(url, config).then(response => response.data.users)
  }
}
