import Cookie from 'js-cookie'

export default class UserService {
  constructor(store, auth) {
    this.store = store
    this.auth = auth
    this.country = store.state.i18nStore.country
  }

  async login(token) {
    this.setToken(null)
    return new Promise((resolve, reject) => {
      this.auth
        .setUserToken(token)
        .then(() => {
          this.setToken(token)
          this.store.commit('activation/setPhone', null)
          this.store.commit('activation/setEmailOrPhone', null)
          this.store.commit('activation/setForgotPassword', false)

          if (this.user.cart_uid !== null && this.user.cart_uid !== undefined) {
            this.store.commit('cart/setCartUid', this.user.cart_uid)
          } else {
            this.store.commit('cart/setCartUid', null)
          }

          if (this.isPro) {
            this.store.commit('config/add', { showVAT: false })
          } else {
            this.store.commit('config/add', { showVAT: true })
          }

          if (this.store.getters['club/isClub']) {
            this.store.dispatch('club/getClub')
          }
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.setToken(null)
      this.auth
        .logout()
        .then(() => {
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  setToken(token) {
    this.auth.strategies.local.options.endpoints.user.headers['Authorization'] = token
    if (token) {
      this.auth.setToken('local', token)
      if (this.country) {
        Cookie.set('auth._token.local', token, { expires: 6, domain: `.${this.country?.host}` })
      }
    } else {
      Cookie.set('auth._token.local', false, { domain: `.${this.country?.host}` })
      this.auth.setToken('local', false)
      Object.keys(Cookie.get()).forEach(cookieName => {
        if (cookieName.match('auth')) {
          Cookie.set(cookieName, false, { domain: `.${this.country?.host}` })
        }
      })
    }
  }

  get name() {
    return this.user?.first_name
  }

  get lastName() {
    return this.user?.last_name
  }

  get fullName() {
    return this.user ? `${this.user.first_name} ${this.user.last_name}` : null
  }

  get uid() {
    return this.user?.uid
  }

  get email() {
    return this.user?.email
  }

  get company() {
    return this.user?.company
  }

  get companyUid() {
    return this.user?.company.uid
  }

  get companyName() {
    return this.user?.company.name
  }

  get phone() {
    return this.user?.phone_number
  }

  get user() {
    return this.auth?.user
  }

  get token() {
    return this.auth?.getToken('local')
  }

  get isFreemium() {
    return this.user?.company?.status === 'free'
  }

  get hasCompanyOptionsDashboard() {
    return this.user?.company?.options?.includes('customer_dashboard')
  }

  get isValidUser() {
    return this.user !== null && this.user !== undefined
  }

  get isPro() {
    return this.isValidUser && this.user?.role?.name !== 'Individual'
  }

  get isEmployee() {
    return this.isValidUser && this.user?.role?.name === 'Employee'
  }

  get isOwner() {
    return this.isValidUser && this.user?.role?.name === 'Owner'
  }

  get isAdmin() {
    return this.isValidUser && this.user?.role?.name === 'Admin'
  }

  get isAuthenticated() {
    return this.auth.loggedIn && this.isValidUser
  }

  get clubs() {
    // Filter out other group kind like GROUP
    return this.isValidUser && this.user.company_groups.filter(g => g.kind == 'MKP_CLUB')
  }

  get isSalesRep() {
    return this.user?.user_roles?.find(role => role?.company?.uid === this.user?.company?.uid)?.is_sales_rep || false
  }

  get isPriceTeam() {
    return this.user?.user_roles?.find(role => role?.company?.uid === this.user?.company?.uid)?.is_price_team || false
  }

  get isSalesAdmin() {
    return this.user?.user_roles?.find(role => role?.company?.uid === this.user?.company?.uid)?.is_sales_admin || false
  }
}
