export const events = {
  ADD_TO_CART: 'add_to_cart',
  LOGIN_STATUS: 'login_status',
  SUBMIT_APPLICATION: 'SubmitApplication',
  TRANSACTION_READY: 'transaction_ready',
  PURCHASE_FB: 'PurchaseFB',
  VIEW_PRODUCT: 'viewProduct',
  PRODUCT_ALREADY_SAVED: 'product_already_saved',
  SEARCH_IN_MKP: 'search_in_mkp',
  SEARCH_IN_MKP_MERCHANT: 'search_in_mkp_merchant',
  OPEN_BURGER_MENU_CATEGORIES: 'openBurgerMenuCategories',
  CLOSE_BURGER_MENU: 'closeBurgerMenu',
  BEGIN_CHECKOUT: 'begin_checkout',
  PURCHASE: 'purchase',
  GENERATE_LEAD: 'generate_lead',
  VIEW_ITEM: 'view_item',
  SELECT_ITEM: 'select_item',
  VIEW_CART: 'view_cart',
  LOGIN: 'login',
  LOGOUT: 'logout',
  SIGN_UP: 'sign_up',
  REMOVE_FROM_CART: 'remove_from_cart',
  TAG_PLAN: {
    MKP: {
      CLICK_CART: 'click_view_cart',
      CLICK_PRODUCT: 'click_product_card',
      CLICK_BUY: 'click_buy',
      CONFIRM_BUY: 'confirm_buy',
      CLICK_AD: 'click_ad',
      SORT: 'sort',
      FILTER: 'filter',
    },
    CART: {
      INITIATE_CHECKOUT_FB: 'initiateCheckoutFB',
      CLICK_ADD_PROMO_CODE: 'click_add_promo_code',
      UPDATE_PRODUCT_QUANTITY: 'update_cart_product_quantity',
      CLICK_PAY_CART: 'click_pay_cart',
      CLICK_SUBMIT_CART: 'click_submit_pay',
      CLICK_REMOVE_PRODUCT_FROM_CART: 'click_remove_product_from_cart',
      TICK_CGV: 'tick_accept_cgv',
    },
    ORDER: {
      TOGGLE_PURCHASES_SALES: 'toggle_purchases_sales',
      CLICK_CANCEL_ORDER: 'click_cancel_order',
      CLICK_CANCEL_ORDER_CONFIRMATION: 'click_cancel_order_confirmation',
      CLICK_OPEN_MAP: 'click_open_map',
      UPDATE_ORDER_STATUS: 'update_order_status',
    },
    SEARCH: {
      CLICK_SEARCH_BAR: 'click_search_bar',
      CLICK_FILTER_DISTANCE: 'click_filter_distance',
      CLICK_FILTER_BRANDS: 'click_filter_brands',
      CLICK_FILTER_CATEGORIES: 'click_filter_categories',
      CLICK_BRAND: 'click_brand_filter',
      CLICK_CATEGORY: 'click_category_filter',
      CLICK_LOCATION: 'click_location_filter',
      CLICK_DISTANCE: 'click_distance_filter',
      CLICK_RESET_DISTANCE: 'click_reset_distance_filter',
    },
    BOOST_MY_SALES: {
      FORM_SENT: 'boost_my_sales_form_sent',
    },
  },
  CLICK_VIPROS_OFFER_DETAILS: 'click_vipros_offer_details',
}
// eslint-disable-next-line
export default ({}, inject) => {
  inject('events', events)
}
