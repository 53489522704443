// import json from '@/static/storyblok-mock/storyblok-mock.js'
// const qs = require('qs')
import Repository from './repository'

export default class StoryblokRepository extends Repository {
  async get(url, params) {
    if (!params['version']) {
      params['version'] = process.env.STORYBLOK_ENV === 'production' ? 'published' : 'draft'
    }
    try {
      // desactivate local storyblok data to improve project size by deleting the mock file
      // if (process.env.STORYBLOK_LOCAL) {
      //   // hack fetch json data from static mock
      //   let queryString = qs.stringify(params, { encode: false, allowDots: true })
      //   // queryString = queryString.match(/[a-zA-Z0-9]+/g).join('')

      //   console.log('queryString', queryString)

      //   let key = url + queryString
      //   if (json?.[key]) {
      //     console.log('from json')
      //     return Promise.resolve({ data: json[key] })
      //   }
      //   console.log('NO json')
      // }
      if (!params['token']) {
        params['token'] = process.env.STORYBLOK_KEY
      }
      if (!params['sort_by']) {
        params['sort_by'] =
          'content.order:asc;content.previous_published_at:desc;first_published_at:desc;published_at:desc;created_at:desc'
      }
      // let date = new Date()
      // params['cv'] = date.setHours(date.getHours(), 0, 0, 0)
      // console.log('call storyblok', url, params)
      const response = await this.storyApi.get(`cdn/stories${url}`, {
        ...params,
      })
      return Promise.resolve(response)
    } catch (e) {
      // if no result, normal storyblok get
      console.log(e)
    }
  }
}
