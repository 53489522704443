/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */

export const routeOption = (route, key, value) => {
  return route.matched.some(m => {
    if (process.client) {
      // Client
      return Object.values(m.components).some(component => component.options && component.options[key] === value)
    } else {
      // SSR
      return Object.values(m.components).some(component =>
        Object.values(component._Ctor).some(ctor => ctor.options && ctor.options[key] === value)
      )
    }
  })
}

export const getMatchedComponents = (route, matches = false) => {
  return [].concat.apply(
    [],
    route.matched.map(function (m, index) {
      return Object.keys(m.components).map(function (key) {
        matches && matches.push(index)
        return m.components[key]
      })
    })
  )
}

export function normalizePath(path = '') {
  // Remove query string
  let result = path.split('?')[0]

  // Remove redundant / from the end of path
  if (result.charAt(result.length - 1) === '/') {
    result = result.slice(0, -1)
  }

  return result
}

export function currentSlug(ctx, key = 'slug') {
  if (
    (ctx?.route?.params?.[key] && ctx?.route?.params?.[key]?.includes('[object Object]')) ||
    ctx?.route?.params?.[key] === 'undefined'
  ) {
    if (process.client && window?.location?.pathname?.split('/')) {
      return window.location.pathname.split('/').pop()
    } else {
      if (ctx?.$nuxt?.context?.req?.headers?.referer) {
        return ctx.$nuxt.context.req.headers.referer.split('/').pop()
      } else if (ctx?.req?.headers?.referer?.split('/')) {
        return ctx.req.headers.referer.split('/').pop()
      }
    }
  }
  if (ctx?.route?.params?.[key]) {
    return ctx.route.params[key]
  }
}

export function debounce(func, wait) {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(args)
    }, wait)
  }
}
