import ReferenceRepository from '@/repositories/referenceRepository'
import BrandRepository from '@/repositories/brandRepository'
import CategoryRepository from '@/repositories/categoryRepository'
import ProspectRepository from '@/repositories/prospectRepository'
import LocationRepository from '@/repositories/locationRepository'
import CompanyRepository from '@/repositories/companyRepository'
import SpecialtyRepository from '@/repositories/specialtyRepository'
import PromoCodeRepository from '@/repositories/promoCodeRepository'
import MetaRepository from '@/repositories/metaRepository'
import UserRepository from '@/repositories/userRepository'
import CartRepository from '@/repositories/cartRepository'
import OrderRepository from '@/repositories/orderRepository'
import StockRepository from '@/repositories/stockRepository'
import MerchantRepository from '@/repositories/merchantRepository'
import CarrierRepository from '@/repositories/carrierRepository'
import AutocompleteRepository from '@/repositories/autocompleteRepository'
import MkpAlertRepository from '@/repositories/mkpAlertRepository'
import StorageRepository from '@/repositories/storageRepository'
import DashboardRepository from '@/repositories/dashboardRepository'
import GeoRepository from '@/repositories/geoRepository'
import ClubRepository from '@/repositories/clubRepository'
import QuoteRepository from '@/repositories/quoteRepository'
import AppPurchaseRepository from '@/repositories/appPurchaseRepository'
import TagsRepository from '@/repositories/tagsRepository'
import DownloadFilesRepository from '@/repositories/downloadFilesRepository'
import StoryblokRepository from '@/repositories/storyblokRepository'

import UserMapper from '@/helpers/mappers/UserMapperHelper'
import CompanyMapper from '@/helpers/mappers/CompanyMapperHelper'
import OrderMapper from '@/helpers/mappers/OrderMapperHelper'
import ReferenceMapper from '@/helpers/mappers/ReferenceMapperHelper'
import StockMapper from '@/helpers/mappers/StockMapperHelper'
import BrandMapper from '@/helpers/mappers/BrandMapperHelper'
import CategoryMapper from '@/helpers/mappers/CategoryMapperHelper'
import CartMapper from '@/helpers/mappers/CartMapperHelper'
import MkpAlertMapper from '@/helpers/mappers/MkpAlertMapperHelper'
import StorageMapper from '@/helpers/mappers/StorageMapperHelper'
import ClubMapper from '@/helpers/mappers/ClubMapperHelper'
import QuoteMapper from '@/helpers/mappers/QuoteMapperHelper'

export default class ApiService {
  constructor({ axios, backendUrl, storyApi }) {
    axios.setHeader('Content-Type', 'application/json')
    axios.setHeader('Authorization', null)
    this.reference = new ReferenceRepository({ axios, backendUrl, mapper: ReferenceMapper })
    this.brand = new BrandRepository({ axios, backendUrl, mapper: BrandMapper })
    this.category = new CategoryRepository({ axios, backendUrl, mapper: CategoryMapper })
    this.prospect = new ProspectRepository({ axios, backendUrl })
    this.location = new LocationRepository({ axios, backendUrl })
    this.user = new UserRepository({ axios, backendUrl, mapper: UserMapper })
    this.company = new CompanyRepository({ axios, backendUrl, mapper: CompanyMapper })
    this.specialty = new SpecialtyRepository({ axios, backendUrl })
    this.meta = new MetaRepository({ axios, backendUrl })
    this.promoCode = new PromoCodeRepository({ axios, backendUrl })
    this.cart = new CartRepository({ axios, backendUrl, mapper: CartMapper })
    this.order = new OrderRepository({ axios, backendUrl, mapper: OrderMapper })
    this.stock = new StockRepository({ axios, backendUrl, mapper: StockMapper })
    this.merchant = new MerchantRepository({ axios, backendUrl, mapper: CompanyMapper })
    this.carrier = new CarrierRepository({ axios, backendUrl })
    this.autocomplete = new AutocompleteRepository({ axios, backendUrl })
    this.mkpAlert = new MkpAlertRepository({ axios, backendUrl, mapper: MkpAlertMapper })
    this.storage = new StorageRepository({ axios, backendUrl, mapper: StorageMapper })
    this.dashboard = new DashboardRepository({ axios, backendUrl })
    this.geo = new GeoRepository({ axios, backendUrl })
    this.club = new ClubRepository({ axios, backendUrl, mapper: ClubMapper })
    this.quote = new QuoteRepository({ axios, backendUrl, mapper: QuoteMapper })
    this.appPurchase = new AppPurchaseRepository({ axios, backendUrl })
    this.tags = new TagsRepository({ axios, backendUrl })
    this.downloadFiles = new DownloadFilesRepository({ axios, backendUrl })
    this.storyblok = new StoryblokRepository({ axios, backendUrl, storyApi })
  }
}
