
import Mobile from '@/mixins/mobile-mixin'
export default {
  name: 'SocialIcons',
  mixins: [Mobile],
  props: {
    displayLink: { type: Boolean, default: false },
    sharePage: { type: Boolean, default: false },
    metaData: { type: Object, default: () => {} },
  },
  methods: {
    copyLink() {
      const dummy = document.createElement('input')
      const text = location.href
      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)

      this.$store.dispatch('commons/snackbar/show', {
        color: 'primary',
        message: this.$t('snackbar.linkCopied'),
      })
    },
    shareLink(type) {
      let url = null
      const linkTo = location.href
      const body = encodeURI(
        this.$t('share.blog', {
          name: this.metaData.title,
          link: linkTo,
        })
      )

      if (type === 'Facebook') url = `https://www.facebook.com/sharer/sharer.php?u=${linkTo}&p[title]=${body}`
      if (type === 'Twitter') url = `http://twitter.com/share?url=${linkTo}&text=${body}`
      if (type === 'LinkedIn') url = `https://www.linkedin.com/shareArticle?mini=true&url=${linkTo}&title=${body}`
      console.log(url)
      if (url) {
        if (this.isMd) {
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          window.open(
            url,
            type,
            'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600'
          )
        }
      }
    },
  },
}
