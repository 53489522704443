const countries = require('../lang/countries')

export function getHrefLang({ $route, locale, country, reference, category, brand, merchant, pageData }) {
  // get TLD and PATH
  const path = process.client ? window?.location?.pathname : $route?.path || ''
  const query = process.client
    ? window?.location?.search?.replace('?', '')
    : Object.keys($route?.query || {})
        .map(key => `${key}=${$route.query[key]}`)
        .join('&')

  const currentPage =
    query
      .split('&')
      .find(param => param.startsWith('page='))
      ?.split('=')[1] || null
  const langParam =
    query
      .split('&')
      .find(param => param.startsWith('lang='))
      ?.split('=')[1] || null
  const hreflang = []
  const translatedSlugs = ['/category/', '/product/']
  const excludeUrls = ['/blog/', '/press/']
  const isProductPage = path.includes('/product/')
  const isCategoryPage =
    path.includes('/category/') &&
    !path.includes('category/brand') &&
    !path.includes('category/best-deals') &&
    !path.includes('category/best-sellers') &&
    !path.includes('category/green-everyday') &&
    !path.includes('category/new-products') &&
    !path.includes('category/rooms') &&
    !path.includes('category/seasonal-selections') &&
    !path.includes('category/special-discounts')
  const isBrandPage = path.includes('/brand/')
  const isMerchantPage = path.includes('/merchant/')

  function referenceHasProductsInCountry(countrySelected) {
    if (
      reference?.public_stocks_quantities_by_country?.[countrySelected.code] !== null ||
      reference?.public_stocks_quantities_by_country?.[countrySelected.code] !== undefined
    ) {
      return reference?.public_stocks_quantities_by_country?.[countrySelected.code] > 0
    }
    return true
  }

  function categoryHasProductsInCountry(countrySelected) {
    if (
      category?.stockAvailable?.[countrySelected.code] !== null ||
      category?.stockAvailable?.[countrySelected.code] !== undefined
    ) {
      return category?.stockAvailable?.[countrySelected.code] > 0
    }
    return true
  }

  function brandHasProductsInCountry(countrySelected) {
    if (
      brand?.offersCountByCountry?.[countrySelected.code] !== null ||
      brand?.offersCountByCountry?.[countrySelected.code] !== undefined
    ) {
      return brand?.offersCountByCountry?.[countrySelected.code] > 0
    }
    return true
  }

  function merchantHasProductsInCountry(countrySelected) {
    if (
      merchant?.offers_count_by_country?.[countrySelected.code] !== null ||
      merchant?.offers_count_by_country?.[countrySelected.code] !== undefined
    ) {
      return merchant?.offers_count_by_country?.[countrySelected.code] > 0
    }
    return true
  }

  function addHrefLang(countrySelected, lang, currentPath, pathLang = null) {
    if (
      (isProductPage && !referenceHasProductsInCountry(countrySelected)) ||
      (isCategoryPage && !categoryHasProductsInCountry(countrySelected)) ||
      (isBrandPage && !brandHasProductsInCountry(countrySelected)) ||
      (isMerchantPage && !merchantHasProductsInCountry(countrySelected))
    ) {
      return
    }
    hreflang.push({
      rel: 'alternate',
      hreflang: lang,
      href: `https://www.${countrySelected.host}${currentPath}${pathLang ? `?lang=${pathLang}` : ''}${
        currentPage > 1 ? `${pathLang ? '&' : '?'}${query}` : ''
      }`,
    })
  }

  function getTranslatedPath(lang) {
    const previousSlug = pageData?.slug
    const newSlug = pageData?.slugs?.[lang]
    return previousSlug && newSlug ? path.replace(previousSlug, newSlug) : path
  }

  // for each country, create an hreflang and handle multiples langs
  function getHrefLangForCountry(c) {
    if (c.availableLanguages?.length > 1) {
      for (const lang of c.availableLanguages) {
        const currentPath = lang !== locale && translatedSlugs.some(x => path.match(x)) ? getTranslatedPath(lang) : path
        addHrefLang(c, `${c.code}-${lang?.toUpperCase()}`, currentPath, lang)
      }
    } else {
      const currentPath = c.availableLanguages[0] !== locale ? getTranslatedPath(c.availableLanguages[0]) : path
      addHrefLang(c, c.code, currentPath)
    }
  }
  // define urls to exclude to generate an hreflang for other countries
  if (!excludeUrls.some(x => path.match(x))) {
    for (const c of countries) {
      getHrefLangForCountry(c)
    }
  } else {
    getHrefLangForCountry(country)
  }
  // create canonical
  let canonical = {
    rel: 'canonical',
    href: `https://www.${country.host}${path}${
      currentPage > 1 || langParam || (langParam && currentPage > 1) ? `?${query}` : ''
    }`,
  }
  return { hreflang, canonical }
}

export function updateHrefLang(hreflang, canonical) {
  if (process.client) {
    hreflang.forEach(lang => {
      const linkTagHreflang = document.querySelector(`link[rel="alternate"][hreflang="${lang.hreflang}"]`)
      if (linkTagHreflang) {
        linkTagHreflang.setAttribute('href', lang.href)
      } else {
        const link = document.createElement('link')
        link.href = lang.href
        link.setAttribute('rel', 'alternate')
        link.setAttribute('hreflang', lang.hreflang)
        document.head.appendChild(link)
      }
    })
    const linkTagCanonical = document.querySelector('link[rel="canonical"]')
    if (linkTagCanonical && canonical?.href) {
      linkTagCanonical.setAttribute('href', canonical.href)
    } else {
      const link = document.createElement('link')
      link.href = canonical?.href
      link.setAttribute('rel', 'canonical')
      document.head.appendChild(link)
    }
  }
}

export const defaultHeadMeta = [
  { charset: 'utf-8' },
  { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' },
  { name: 'google-site-verification', content: process.env.GOOGLE_VERIFICATION },
  { name: 'facebook-domain-verification', content: process.env.FACEBOOK_VERIFICATION },
  {
    hid: 'description',
    name: 'description',
    content:
      "StockPro, la plateforme de réemploi de matériaux de construction et outillage neufs et pas cher. Achetez à bas prix et favorisez l'économie circulaire.",
  },
  // Open Graph
  { hid: 'og:type', name: 'og:type', content: 'website' },
  {
    hid: 'title',
    name: 'title',
    content: 'StockPro, Plateforme Réemploi, Vente matériaux de construction pas cher',
  },
  {
    hid: 'og:title',
    name: 'og:title',
    content: 'StockPro, Plateforme Réemploi, Vente matériaux de construction pas cher',
  },
  {
    hid: 'og:description',
    name: 'og:description',
    content:
      "StockPro, la plateforme de réemploi de matériaux de construction et outillage neufs et pas cher. Achetez à bas prix et favorisez l'économie circulaire.",
  },
  {
    hid: 'og:image',
    rel: 'og:image',
    property: 'og:image',
    content: 'https://d3brsr9pdomwt0.cloudfront.net/social/web_banner.jpg',
  },
]

export function defaultHeadLinks(params) {
  const { hreflang, canonical } = getHrefLang(params)
  return {
    link: [canonical, ...hreflang, { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }],
  }
}

export default function defaultHead(locale, userIsAuthenticated = false, country = null) {
  const scripts = [
    {
      hid: 'newrelic',
      src: `/newrelic-${process.env.ENV_NAME}.js`,
      defer: true,
      type: 'text/javascript',
      charset: 'utf-8',
    },
  ]
  if (!userIsAuthenticated && country?.code === 'fr') {
    scripts.push({
      hid: 'mcjs',
      innerHTML: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/49648c4b7d22f482da0ee9755/ea1440b5ec04d665666c7e22f.js");`,
      type: 'text/javascript',
      charset: 'utf-8',
    })
  }
  return {
    htmlAttrs: {
      lang: locale || 'fr',
    },
    title: 'StockPro, Plateforme Réemploi, Vente matériaux de construction pas cher',
    __dangerouslyDisableSanitizers: ['script'],
    script: scripts,
  }
}
