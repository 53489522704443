
import { mapGetters } from 'vuex'

export default {
  name: 'SnackbarIndex',
  data() {
    return {
      settledTimeout: null,
    }
  },
  computed: {
    ...mapGetters({
      message: 'commons/snackbar/message',
      color: 'commons/snackbar/color',
      timeout: 'commons/snackbar/timeout',
      show: 'commons/snackbar/show',
      canBeClosed: 'commons/snackbar/canBeClosed',
      customTop: 'commons/snackbar/customTop',
    }),
  },
  watch: {
    show(v) {
      if (v) {
        this.settledTimeout = setTimeout(() => {
          this.close()
        }, this.timeout)
      }
    },
  },
  beforeDestroy() {
    if (this.settledTimeout !== null) {
      clearTimeout(this.settledTimeout)
    }
  },
  methods: {
    close() {
      this.$store.dispatch('commons/snackbar/hide')
      clearTimeout(this.settledTimeout)
    },
  },
}
