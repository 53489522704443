
import Mobile from '@/mixins/mobile-mixin'
import { debounce } from '@/utils'

export default {
  name: 'PaginationIndex',
  mixins: [Mobile],
  props: {
    currentPage: { type: Number, default: 1 },
    maxPage: { type: Number, default: 1 },
    queryPageName: { type: String, default: 'page' },
    fromHeaderCompanySelector: { type: Boolean, default: false },
  },
  data() {
    return {
      pageOffset: 4,
      showTens: true,
      showHundreds: true,
    }
  },
  computed: {
    page() {
      let boundedPage = this.currentPage
      boundedPage = Math.max(1, boundedPage)
      boundedPage = Math.min(this.maxPage, boundedPage)
      return boundedPage
    },
    prevPage() {
      return Math.max(1, this.page - 1)
    },
    nextPage() {
      return Math.min(this.maxPage, this.page + 1)
    },
    pageRange() {
      const range = []
      for (let i = this.pageOffset; i > 0; i -= 1) {
        if (this.page - i > 1) {
          range.push(this.page - i)
        }
      }
      range.push(this.page)
      for (let i = 1; i <= this.pageOffset; i += 1) {
        if (this.page + i < this.maxPage) {
          range.push(this.page + i)
        }
      }
      return range
    },
    tensPages() {
      const maxI = Math.min(this.maxPage, 99)
      const tens = []
      for (let i = 10; i <= maxI; i += 10) {
        if (
          i !== this.maxPage &&
          i > this.currentPage &&
          !(i >= this.currentPage - this.pageOffset && i <= this.currentPage + this.pageOffset) // Check if not already existing in offset pages
        )
          tens.push(i)
      }
      return tens
    },
    hundredsPages() {
      const maxI = Math.min(this.maxPage, 1000)
      const hundreds = []
      for (let i = 100; i <= maxI; i += 100) {
        if (
          i !== this.maxPage &&
          i > this.currentPage &&
          !(i >= this.currentPage - this.pageOffset && i <= this.currentPage + this.pageOffset) // Check if not already existing in offset pages
        )
          hundreds.push(i)
      }
      return hundreds
    },
  },
  beforeMount() {
    if (!this.fromHeaderCompanySelector)
      window.addEventListener('resize', debounce(this.matchFullWidth, 300), { passive: true })
  },
  beforeDestroy() {
    if (!this.fromHeaderCompanySelector) window.removeEventListener('resize', this.matchFullWidth)
  },
  mounted() {
    if (!this.fromHeaderCompanySelector) {
      setTimeout(() => {
        this.matchFullWidth()
      }, 200)
    }
  },
  methods: {
    linkForPage(page) {
      // No query if from company selector, empty link
      if (this.fromHeaderCompanySelector) return ''
      const { name, params } = this.$route
      let query = {}
      if (typeof window !== 'undefined') {
        query = decodeURI(window.location.search)
          .replace('?', '')
          .split('&')
          .map(param => param.split('='))
          .reduce((values, [key, value]) => {
            values[key] = value
            return values
          }, {})
      }
      query[this.queryPageName] = page
      return this.localePath({ name, query, params })
    },
    returnPage(page) {
      if (this.fromHeaderCompanySelector) this.$emit('update-page', page)
    },
    matchFullWidth() {
      // TODO store width and only fire with width change
      if (!this.fromHeaderCompanySelector) {
        // Fill or limit pagination numbers according to pagination div width
        // Get all informations
        const paginationWidth = document.querySelector('.pagination')?.offsetWidth
        const numberWidth = 30 // css a width
        const spacing = 10 // gap flex
        const numbersNeeded = Math.floor((paginationWidth - spacing) / (numberWidth + spacing))
        let paginationElements = document.querySelector('.pagination-numbers')?.childElementCount
        // Hide tens & hundreds in Sm
        if (this.isSm) {
          this.showTens = false
          this.showHundreds = false
        }
        // Then calculate displayed elems according to tens/hundred or not
        const displayedTens = this.showTens ? 0 : this.tensPages.length
        const displayedHundreds = this.showHundreds ? 0 : this.hundredsPages.length
        let displayedElements = paginationElements - displayedHundreds - displayedTens
        // Get missing or excess numbers, and adjust pageOffset ( -1 is for security)
        const missingNumbers = numbersNeeded - displayedElements
        const excessNumbers = displayedElements - numbersNeeded
        if (numbersNeeded > this.maxPage) {
          this.pageOffset = this.maxPage
        }
        if (missingNumbers > 0 && numbersNeeded < this.maxPage) {
          const newPageoffset = this.pageOffset + Math.floor(missingNumbers / 2) - 1
          this.pageOffset = newPageoffset
          if (this.isXs && newPageoffset > 2 && !(this.currentPage < 2 || this.currentPage > this.maxPage - 2)) {
            this.pageOffset = 2
          }
        } else if (excessNumbers >= 0) {
          const newPageoffset = this.pageOffset - Math.floor(excessNumbers / 2) - 1
          this.pageOffset = newPageoffset
          if (this.isXs && newPageoffset > 2 && !(this.currentPage < 2 || this.currentPage > this.maxPage - 2)) {
            this.pageOffset = 2
          }
        }
      }
    },
  },
}
