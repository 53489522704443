const Cookie = require('js-cookie')
const countries = require('../lang/countries')

export function currentLocation(ctx = this) {
  // return BE / FR / etc from host
  // default france host
  let tld = 'FR'

  if ((ctx?.$store || ctx?.store)?.state?.i18nStore?.tld) {
    // tld from store
    tld = (ctx?.$store || ctx?.store).state?.i18nStore?.tld?.toUpperCase()
  } else if (process.client && window?.location?.hostname?.split('.')?.pop()) {
    // tld from window hostnam
    tld = window?.location?.hostname?.split('.')?.pop()?.toUpperCase()
  } else if (process.server && ctx?.$nuxt?.context?.req?.headers?.host?.split('.')?.pop()) {
    // tld from $nuxt headers host
    tld = ctx?.$nuxt?.context?.req?.headers?.host?.split('.')?.pop()?.toUpperCase()
  } else if (process.server && ctx?.req?.headers?.host?.split('.')?.pop()) {
    // tld from req headers host
    tld = ctx?.req?.headers?.host?.split('.')?.pop()?.toUpperCase()
  }
  if (tld?.includes(':')) {
    // exclude port for dev env
    tld = tld.split(':').shift()
  }
  if (tld === 'COM') {
    // default to FR if .com for qa env
    tld = 'FR'
  }
  if (ctx?.$store || ctx?.store) {
    ;(ctx?.$store || ctx?.store).commit('i18nStore/setTld', tld)
  }
  return tld
}

export function currentCountry(ctx = this) {
  // return plain country object
  return countries.find(locale => {
    return locale.code?.toUpperCase() === currentLocation(ctx)
  })
}

export function currentLangCode(ctx = this, min = false, revert = false) {
  // return fr-be or fr-fr
  // with min param, return fr instead of fr-fr
  let currentLocale = getCurrentLocale(ctx)
  let location = currentLocation(ctx)
  if (min && currentLocale?.toLowerCase() === location?.toLowerCase()) {
    return currentLocale
  }
  if (revert) {
    return location?.toLowerCase() + '-' + currentLocale?.toLowerCase()
  }
  return currentLocale?.toLowerCase() + '-' + location?.toLowerCase()
}

export function getCurrentLocale(ctx = this) {
  // return locale like 'fr' default to france
  let locale = 'fr'
  if ((ctx?.route || ctx?.$route)?.query?.lang) {
    // force locale with query params from $route & and handle lang duplicated param in array
    locale = Array.isArray((ctx?.route || ctx?.$route)?.query?.lang)
      ? (ctx?.route || ctx?.$route)?.query?.lang[0]
      : (ctx?.route || ctx?.$route)?.query?.lang
  } else if (ctx?.app?.$cookies?.get('lang')) {
    // set locale with cookie
    locale = ctx?.app?.$cookies?.get('lang')
  } else if (Cookie.get('lang')) {
    // set locale with cookie
    locale = Cookie.get('lang')
  } else if ((ctx?.$store || ctx?.store)?.state?.i18nStore?.locale) {
    // locale from store
    locale = (ctx?.$store || ctx?.store).state?.i18nStore?.locale
  } else if ((ctx?.i18n || ctx?.$i18n)?.locales?.length) {
    // set locale with i18n and currentLocation
    const location = currentLocation(ctx)
    const country = countries.find(obj => obj?.code === location?.toLowerCase())
    locale = (ctx?.i18n || ctx?.$i18n)?.locales?.find(locale =>
      country?.availableLanguages?.includes(locale?.code?.toLowerCase())
    )?.code
  } else if (process.client) {
    // if client, use navigator language
    if (navigator?.language?.split('-')?.length) {
      locale = navigator?.language?.split('-')?.[1]
    } else {
      locale = navigator?.language?.toLowerCase()
    }
  }
  if (!locale) {
    // default reset to fr
    locale = 'fr'
  }
  if (ctx?.$store || ctx?.store) {
    ;(ctx?.$store || ctx?.store).commit('i18nStore/setLocale', locale)
  }
  if (ctx?.app?.$cookies) {
    ctx?.app?.$cookies.set('lang', locale, { maxAge: 60 * 60 * 24 * 365, domain: `.${currentCountry()?.host}` })
  }
  return locale
}
