import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_d36ab8ac from 'nuxt_plugin_plugin_d36ab8ac' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_cc6504f8 from 'nuxt_plugin_sentryserver_cc6504f8' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_34140bfc from 'nuxt_plugin_sentryclient_34140bfc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_image_cc470a8a from 'nuxt_plugin_image_cc470a8a' // Source: ./image.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_13428eee from 'nuxt_plugin_nuxtuseragent_13428eee' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_axios_a2550480 from 'nuxt_plugin_axios_a2550480' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_1264287b from 'nuxt_plugin_pluginutils_1264287b' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8006f9f4 from 'nuxt_plugin_pluginrouting_8006f9f4' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_b75693ae from 'nuxt_plugin_pluginmain_b75693ae' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_4a9b9d68 from 'nuxt_plugin_cookieuniversalnuxt_4a9b9d68' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_gtm_499ab496 from 'nuxt_plugin_gtm_499ab496' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_moduleplugin132bfb32_748806cd from 'nuxt_plugin_moduleplugin132bfb32_748806cd' // Source: ./module.plugin.132bfb32.mjs (mode: 'all')
import nuxt_plugin_router_3fb27422 from 'nuxt_plugin_router_3fb27422' // Source: ./router.js (mode: 'all')
import nuxt_plugin_currentLocale_9cc0814c from 'nuxt_plugin_currentLocale_9cc0814c' // Source: ../plugins/currentLocale.js (mode: 'all')
import nuxt_plugin_vuelidate_4be431c8 from 'nuxt_plugin_vuelidate_4be431c8' // Source: ../plugins/vuelidate.js (mode: 'all')
import nuxt_plugin_vuefilters_29b0f34c from 'nuxt_plugin_vuefilters_29b0f34c' // Source: ../plugins/vue-filters.js (mode: 'all')
import nuxt_plugin_vuexsharedmutations_2437911e from 'nuxt_plugin_vuexsharedmutations_2437911e' // Source: ../plugins/vuex-shared-mutations.js (mode: 'client')
import nuxt_plugin_lazyload_3bfb3f24 from 'nuxt_plugin_lazyload_3bfb3f24' // Source: ../plugins/lazy-load.js (mode: 'all')
import nuxt_plugin_localstorage_2952dcb4 from 'nuxt_plugin_localstorage_2952dcb4' // Source: ../plugins/local-storage.js (mode: 'client')
import nuxt_plugin_cookiestorage_d9114822 from 'nuxt_plugin_cookiestorage_d9114822' // Source: ../plugins/cookie-storage.js (mode: 'client')
import nuxt_plugin_apiservice_518ccdab from 'nuxt_plugin_apiservice_518ccdab' // Source: ../plugins/api-service.js (mode: 'all')
import nuxt_plugin_cartmanager_109275c1 from 'nuxt_plugin_cartmanager_109275c1' // Source: ../plugins/cart-manager.js (mode: 'all')
import nuxt_plugin_analyticsservice_6068c03f from 'nuxt_plugin_analyticsservice_6068c03f' // Source: ../plugins/analytics-service.js (mode: 'all')
import nuxt_plugin_events_71bdff88 from 'nuxt_plugin_events_71bdff88' // Source: ../plugins/events.js (mode: 'all')
import nuxt_plugin_eventsbus_19e72af5 from 'nuxt_plugin_eventsbus_19e72af5' // Source: ../plugins/events-bus.js (mode: 'all')
import nuxt_plugin_screens_71886b06 from 'nuxt_plugin_screens_71886b06' // Source: ../plugins/screens.js (mode: 'all')
import nuxt_plugin_getpage_4bcb145b from 'nuxt_plugin_getpage_4bcb145b' // Source: ../plugins/get-page.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_piwik_2dda2fa6 from 'nuxt_plugin_piwik_2dda2fa6' // Source: ../plugins/piwik.js (mode: 'client')
import nuxt_plugin_vclickoutside_12eb1bf8 from 'nuxt_plugin_vclickoutside_12eb1bf8' // Source: ../plugins/v-click-outside.js (mode: 'client')
import nuxt_plugin_axeptio_f27d7e96 from 'nuxt_plugin_axeptio_f27d7e96' // Source: ../plugins/axeptio.js (mode: 'client')
import nuxt_plugin_lang_389825da from 'nuxt_plugin_lang_389825da' // Source: ../plugins/lang.js (mode: 'client')
import nuxt_plugin_plugin_4ef73afc from 'nuxt_plugin_plugin_4ef73afc' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_userservice_1c07082e from 'nuxt_plugin_userservice_1c07082e' // Source: ../plugins/user-service.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-TNXFQ5J')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-TNXFQ5J&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_d36ab8ac === 'function') {
    await nuxt_plugin_plugin_d36ab8ac(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_cc6504f8 === 'function') {
    await nuxt_plugin_sentryserver_cc6504f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_34140bfc === 'function') {
    await nuxt_plugin_sentryclient_34140bfc(app.context, inject)
  }

  if (typeof nuxt_plugin_image_cc470a8a === 'function') {
    await nuxt_plugin_image_cc470a8a(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_13428eee === 'function') {
    await nuxt_plugin_nuxtuseragent_13428eee(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_a2550480 === 'function') {
    await nuxt_plugin_axios_a2550480(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_1264287b === 'function') {
    await nuxt_plugin_pluginutils_1264287b(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8006f9f4 === 'function') {
    await nuxt_plugin_pluginrouting_8006f9f4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_b75693ae === 'function') {
    await nuxt_plugin_pluginmain_b75693ae(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_4a9b9d68 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_4a9b9d68(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_499ab496 === 'function') {
    await nuxt_plugin_gtm_499ab496(app.context, inject)
  }

  if (typeof nuxt_plugin_moduleplugin132bfb32_748806cd === 'function') {
    await nuxt_plugin_moduleplugin132bfb32_748806cd(app.context, inject)
  }

  if (typeof nuxt_plugin_router_3fb27422 === 'function') {
    await nuxt_plugin_router_3fb27422(app.context, inject)
  }

  if (typeof nuxt_plugin_currentLocale_9cc0814c === 'function') {
    await nuxt_plugin_currentLocale_9cc0814c(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4be431c8 === 'function') {
    await nuxt_plugin_vuelidate_4be431c8(app.context, inject)
  }

  if (typeof nuxt_plugin_vuefilters_29b0f34c === 'function') {
    await nuxt_plugin_vuefilters_29b0f34c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexsharedmutations_2437911e === 'function') {
    await nuxt_plugin_vuexsharedmutations_2437911e(app.context, inject)
  }

  if (typeof nuxt_plugin_lazyload_3bfb3f24 === 'function') {
    await nuxt_plugin_lazyload_3bfb3f24(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localstorage_2952dcb4 === 'function') {
    await nuxt_plugin_localstorage_2952dcb4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_cookiestorage_d9114822 === 'function') {
    await nuxt_plugin_cookiestorage_d9114822(app.context, inject)
  }

  if (typeof nuxt_plugin_apiservice_518ccdab === 'function') {
    await nuxt_plugin_apiservice_518ccdab(app.context, inject)
  }

  if (typeof nuxt_plugin_cartmanager_109275c1 === 'function') {
    await nuxt_plugin_cartmanager_109275c1(app.context, inject)
  }

  if (typeof nuxt_plugin_analyticsservice_6068c03f === 'function') {
    await nuxt_plugin_analyticsservice_6068c03f(app.context, inject)
  }

  if (typeof nuxt_plugin_events_71bdff88 === 'function') {
    await nuxt_plugin_events_71bdff88(app.context, inject)
  }

  if (typeof nuxt_plugin_eventsbus_19e72af5 === 'function') {
    await nuxt_plugin_eventsbus_19e72af5(app.context, inject)
  }

  if (typeof nuxt_plugin_screens_71886b06 === 'function') {
    await nuxt_plugin_screens_71886b06(app.context, inject)
  }

  if (typeof nuxt_plugin_getpage_4bcb145b === 'function') {
    await nuxt_plugin_getpage_4bcb145b(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_piwik_2dda2fa6 === 'function') {
    await nuxt_plugin_piwik_2dda2fa6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vclickoutside_12eb1bf8 === 'function') {
    await nuxt_plugin_vclickoutside_12eb1bf8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axeptio_f27d7e96 === 'function') {
    await nuxt_plugin_axeptio_f27d7e96(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lang_389825da === 'function') {
    await nuxt_plugin_lang_389825da(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_4ef73afc === 'function') {
    await nuxt_plugin_plugin_4ef73afc(app.context, inject)
  }

  if (typeof nuxt_plugin_userservice_1c07082e === 'function') {
    await nuxt_plugin_userservice_1c07082e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
