import Vue from 'vue'
import Stock from '@/models/Stock'

export const state = () => ({
  actionPayload: null,
  managementState: 'LIST', // LIST, MULTI_BOOK, MULTI_SALE, STOCK_DETAIL, STOCK_DETAIL_BOOK, STOCK_DETAIL_SALE
  currentStocks: [],
  delegate: null,
  stockUid: null,
  selectedStocks: [],
  quantityTypes: ['UNIT', 'BOX', 'PALLET', 'CAN', 'CARTON', 'BAG'],
})

export const getters = {
  managementState: state => {
    return state.managementState
  },
  currentStocks: state => {
    return state.currentStocks
  },
  actionPayload: state => {
    return state.actionPayload
  },
  stock: state => {
    return (
      state.currentStocks.find(el => el.uid == state.stockUid) ||
      state.selectedStocks.find(el => el.uid == state.stockUid)
    )
  },
  selectedStocks: state => {
    return state.selectedStocks
  },
  stockIsSelected: state => uid => {
    return state.selectedStocks.find(s => s.uid === uid)
  },
  canUnbookSelection: (state, getters, rootState, rootGetters) => {
    return (
      state.selectedStocks.length > 0 &&
      state.selectedStocks.map(s => new Stock(s, rootGetters['i18nStore/country'])).every(s => s.isBooked)
    )
  },
  canUnsaleSelection: (state, getters, rootState, rootGetters) => {
    return (
      state.selectedStocks.length > 0 &&
      state.selectedStocks.map(s => new Stock(s, rootGetters['i18nStore/country'])).every(s => s.isPublic)
    )
  },
  canUpdateSelection: (state, getters, rootState, rootGetters) => {
    return (
      state.selectedStocks.length > 0 &&
      state.selectedStocks
        .map(s => new Stock(s, rootGetters['i18nStore/country']))
        .filter(s => !s.isPublic && !s.isBooked)
    )
  },
  quantityTypes: state => state.quantityTypes,
}

export const actions = {
  updateStock({ commit, state }, { fromStock, newStock, merge }) {
    const stocks = [...state.currentStocks]
    const newIdx = stocks.findIndex(el => el.uid === newStock.uid)
    if (newIdx > -1) {
      // Existing stock
      Vue.set(stocks, newIdx, newStock) // update it
      if (fromStock) {
        const fromIdx = stocks.findIndex(el => el.uid === fromStock.uid)
        if (fromIdx > -1 && fromIdx != newIdx && merge) {
          stocks.splice(fromIdx, 1) // remove because of merging
        }
      }
    } else if (!merge) {
      // Completely new stock
      stocks.unshift(newStock) // Add it at beggining
      if (fromStock) {
        const fromIdx = stocks.findIndex(el => el.uid === fromStock.uid)
        if (fromIdx > -1) {
          // Update splitted stock
          const updatedStock = { ...stocks[fromIdx] }
          updatedStock.quantity -= newStock.quantity
          Vue.set(stocks, fromIdx, updatedStock)
        }
      }
    }
    if (state.selectedStocks.length == 1) {
      commit('setSelectedStocks', [newStock])
    }
    commit('setCurrentStocks', stocks)
  },
  updateStocksMulti({ commit, state }, { stocks, fromStocks, merge }) {
    const currentStocks = [...state.currentStocks]

    if (merge) {
      // Remove all from stocks for multi actions. To handle multi unbook & merging
      fromStocks.forEach(stock => {
        const idx = currentStocks.findIndex(el => el.uid === stock.uid)
        if (idx > -1) {
          currentStocks.splice(idx, 1) // remove because of merging
        }
      })
    }

    stocks.forEach(stock => {
      const newIdx = currentStocks.findIndex(el => el.uid === stock.uid)
      if (newIdx > -1) {
        if (stock.is_archived) {
          // Stock being archived, to remove
          currentStocks.splice(newIdx, 1)
        } else {
          // Existing stock to update
          Vue.set(currentStocks, newIdx, stock) // update it
        }
      } else {
        // Completely new stock
        currentStocks.unshift(stock) // Add it at beggining
      }
    })

    commit('setCurrentStocks', currentStocks)
    commit('setSelectedStocks', stocks)
  },
  updateStockImages({ commit, state }, { images, referenceUid, remove }) {
    const stocks = [...state.currentStocks]

    state.currentStocks
      // Update all stock based on that ref
      .filter(s => s.reference.uid == referenceUid)
      .forEach(s => {
        const idx = stocks.findIndex(el => el.uid === s.uid)
        const updatedStock = { ...stocks[idx] }
        // Update stock object
        if (remove) {
          Vue.set(
            updatedStock,
            'reference',
            Object.assign({}, updatedStock.reference, {
              images: updatedStock.reference.images.filter(el => images.findIndex(i => i.uid == el.uid) < 0),
            })
          )
        } else {
          Vue.set(
            updatedStock,
            'reference',
            Object.assign({}, updatedStock.reference, { images: updatedStock.reference.images.concat(images) })
          )
        }
        Vue.set(stocks, idx, updatedStock)
      })
    commit('setCurrentStocks', stocks)
  },
  deleteStock({ commit, state }, stock) {
    const stocks = [...state.currentStocks]
    const idx = stocks.findIndex(el => el.uid === stock.uid)
    if (idx > -1) {
      stocks.splice(idx, 1) // remove because of merging
    }
    commit('setCurrentStocks', stocks)
  },

  toggleSelectedStock({ dispatch, state }, stock) {
    if (state.selectedStocks.find(s => s.uid === stock.uid)) {
      // Already selected
      dispatch('removeSelectedStocks', [stock])
    } else {
      // Not selected
      dispatch('addSelectedStocks', [stock])
    }
  },
  addSelectedStocks({ commit, state }, stocks) {
    const selectedStocks = JSON.parse(JSON.stringify(state.selectedStocks))

    stocks.forEach(s => {
      if (!state.selectedStocks.find(ss => ss.uid === s.uid)) {
        selectedStocks.push(s)
      }
    })
    commit('setSelectedStocks', selectedStocks)
  },
  removeSelectedStocks({ commit, state }, stocks) {
    const selectedStocks = JSON.parse(JSON.stringify(state.selectedStocks))

    stocks.forEach(s => {
      const idx = selectedStocks.findIndex(ss => ss.uid === s.uid)
      if (state.selectedStocks.find(ss => ss.uid === s.uid)) {
        selectedStocks.splice(idx, 1)
      }
    })
    commit('setSelectedStocks', selectedStocks)
  },

  removeAllSelectedStocks({ commit }) {
    commit('setSelectedStocks', [])
  },

  clearSelectedStocks({ commit }) {
    commit('setStockUid', null)
    commit('setSelectedStocks', [])
  },
  setStockUid({ commit, state }, { uid, delegate }) {
    // Avoid racing condition. A stock can be set to null only by the component who set it first
    if (uid !== null || state.delegate == delegate) {
      commit('setDelegate', delegate)
      commit('setStockUid', uid)
    }
  },
  setManagementState({ commit }, managementState) {
    commit('setManagementState', managementState)
  },
  setActionPayload({ commit }, actionPayload) {
    commit('setActionPayload', actionPayload)
  },
}

export const mutations = {
  setManagementState(state, managementState) {
    state.managementState = managementState
  },
  setActionPayload(state, actionPayload) {
    state.actionPayload = actionPayload
  },
  setCurrentStocks(state, stocks) {
    state.currentStocks = stocks
  },
  updateStock(state, { stock, index }) {
    Vue.set(state.currentStocks, index, stock)
  },
  setStockUid(state, uid) {
    state.stockUid = uid
  },
  setDelegate(state, delegate) {
    state.delegate = delegate
  },
  setSelectedStocks(state, stocks) {
    state.selectedStocks = stocks
  },
}
