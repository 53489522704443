import Searchable from './commons/searchable'

export const state = () => ({
  ...Searchable.stateH,
})

export const getters = {
  ...Searchable.getters,
}

export const actions = {
  ...Searchable.actions,
  updateResults({ state, commit, dispatch }, resetPage = false) {
    dispatch('updateRequestQuery', resetPage)
    const { perPage } = state.pagination
    try {
      return this.$api.quote.getSales(this.$currentUser.companyUid, state.requestQuery).then(rawSales => {
        commit('setResults', rawSales.results)
        commit('setPagination', {
          total: rawSales.total,
          totalPages: Math.ceil(rawSales.total / perPage),
          currentPage: state.pagination.currentPage,
          perPage: state.pagination.perPage,
        })
      })
    } catch (e) {
      console.log(e)
      return this.localePath({ statusCode: 404, message: e.message })
    }
  },
}

export const mutations = {
  ...Searchable.mutations,
}
