export default class UserMapperHelper {
  static userMapping = JSON.stringify({
    mapping: {
      user: [
        'uid',
        'first_name',
        'last_name',
        'phone_number',
        'phone',
        'email',
        'cart_uid',
        'user_roles',
        { role: ['uid', 'name'] },
        {
          company_groups: [
            'uid',
            'name',
            'key',
            'kind',
            'logo_url',
            'company_group_configuration',
            'current_user_roles',
          ],
        },
        {
          company: [
            'uid',
            'name',
            'slug',
            'zip_code',
            {
              addresses: ['city', 'complement', 'kind', 'name', 'street', 'uid', 'zip_code', 'country', 'country_iso'],
            },
            'address',
            'city',
            'siren',
            'status',
            'options',
            'stripe_url',
          ],
        },
        'activity',
      ],
    },
  })
  static userWithBookedStockMapping = JSON.stringify({
    mapping: {
      user: ['uid', 'first_name', 'last_name', 'booked_products_count'],
    },
  })
}
