
import i18nMx from '@/mixins/i18n-mixin'

export default {
  name: 'LangSwitcher',
  mixins: [i18nMx],
  data() {
    return {
      opened: false,
    }
  },
}
