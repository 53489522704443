export const state = () => ({
  all: [],
})

export const getters = {
  merchantByUid: state => uid => state.all.find(m => m.uid === uid),
  merchantBySlug: state => slug => state.all.find(m => m.slug === slug),
}

export const mutations = {
  addMerchants(state, merchants) {
    let allMerchants = state.all.concat(merchants)
    allMerchants = allMerchants.filter((m, index, self) => index === self.findIndex(merchant => m.uid === merchant.uid))
    state.all = allMerchants
  },
}
