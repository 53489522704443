import Repository from './repository'

export default class Tags extends Repository {
  async getStocksTags() {
    const url = `${this.backendUrl}stocks-tags`
    return this.axios.get(url).then(response => {
      return response.data.stocks_tags
    })
  }
}
