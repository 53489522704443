import CartItem from '@/models/CartItem'
import Stock from '@/models/Stock'
import Reference from '@/models/Reference'

import API from '@/mixins/sp-api-mixin'
import i18nMx from '@/mixins/i18n-mixin'

export default {
  mixins: [API, i18nMx],
  data() {
    return {
      stock: {},
      quantity: 0,
      fastAddToCart: null,
    }
  },
  methods: {
    async addToCart(accepted = false, stock, quantity, fastAddToCart) {
      if (fastAddToCart) {
        // Query for references to have infos on the stock company to be able to add to cart.
        this.spRequest({
          req: this.$api.reference.get(stock.reference.uid),
        }).then(result => {
          const fullStock = this.getFullCompanyInfos(result)
          let item
          try {
            item = new CartItem(fullStock, quantity, this.country)
          } catch (error) {
            this.$store.dispatch('commons/snackbar/show', {
              color: 'red',
              message: this.$t('backendError.cart_stock_not_created'),
              timeout: 2000,
              canBeClosed: false,
              customTop: this.getSnackBarTop(),
            })
            return
          }
          if (fullStock.reference.accreditation && !accepted) {
            this.$root.$emit('modalOpen_confirmationModalAddToCart', 'confirmationModalAddToCart')
            return
          }
          this.$cart.addItem({
            ...item,
            stock: { ...item.stock, source_id: this.$route.name },
          })
          this.$root.$emit('addToCart', { item, fastAddToCart: true })
          this.$store.dispatch('commons/snackbar/show', {
            color: 'primary',
            message: this.$t('snackbar.productAddedToCart') + '<div class="icon-check u-bg-white" />',
            timeout: 2000,
            canBeClosed: false,
            customTop: this.getSnackBarTop(),
          })
        })
      }

      this.$root.$emit('modalClose_confirmationModalAddToCart', 'confirmationModalAddToCart')
      if (stock.reference.accreditation && !accepted) {
        this.$root.$emit('modalOpen_confirmationModalAddToCart', 'confirmationModalAddToCart')
        return
      }
      const sourceId =
        fastAddToCart || !this.$nuxt?.context?.from?.name ? this.$route.name : this.$nuxt?.context?.from?.name
      this.$analytics.sendTagEvent(this.$events.TAG_PLAN.MKP.CONFIRM_BUY)
      let ref = new Reference(stock.o_reference, this.country)
      this.$analytics.sendEvent(this.$events.ADD_TO_CART, {
        ecommerce: {
          currency: 'EUR',
          value: Number(stock.storePrice(true) * quantity),
          tax: Number(ref.bestStock?.price_vat - ref.bestStock?.price) || 0,
          items: [
            {
              item_id: ref.trackingId,
              item_name: ref.name,
              name: ref.name,
              currency: 'EUR',
              discount: 0,
              item_brand: ref?.brand?.name,
              quantity: Number(quantity),
              price: Number(stock.storePrice(true)),
              ...ref.categoriesName.reduce((a, b, i) => {
                return { ...a, [`item_category${i === 0 ? '' : i + 1}`]: b }
              }, {}),
              item_category4: 'product',
              ...(sourceId
                ? {
                    item_list_id: sourceId,
                    item_list_name: sourceId?.replaceAll('-', ' '),
                  }
                : {}),
            },
          ],
        },
      })

      if (!fastAddToCart) {
        const item = new CartItem(stock, quantity, this.country)
        this.$cart.addItem({
          ...item,
          stock: { ...item.stock, source_id: this.$nuxt?.context?.from?.name || this.$route.name },
        })
        this.$root.$emit('addToCart', { item, fastAddToCart: false })
      }
    },
    getFullCompanyInfos(reference) {
      // Get reference, order stocks like product page stocks() & selectedStock() (without route part) & get stock company
      if (reference?.public_stocks) {
        const ref = new Reference(reference, this.country)
        const stocks = reference.public_stocks.map(child => {
          const s = new Stock(child, this.country)
          s.reference = ref
          return s
        })
        let stock = null
        if (this.$route.name.includes('merchant')) {
          stock = stocks.find(stock => stock?.company?.slug === this.$route.params.slug)
        }
        if (!stock) {
          stock = stocks.sort((a, b) => {
            if (a.distance !== null && a.distance !== undefined) {
              return a.distance - b.distance
            }
            return a.price - b.price
          })[0]
        }
        return stock
      }
      return null
    },
    getSnackBarTop() {
      // Get the top position of snackbar according to header displaying
      const basicTop = 20
      const header = document.querySelector('.header-container')
      const mkpSubheading = document.querySelector('.mkp-subheading')
      const bottomHeaderTop = header.getBoundingClientRect().bottom
      const bottomMkpSubheadingTop = mkpSubheading?.getBoundingClientRect()?.bottom ?? 0
      const stickedMkpSubheading =
        bottomMkpSubheadingTop === mkpSubheading?.offsetHeight ||
        bottomMkpSubheadingTop === header.offsetHeight + mkpSubheading?.offsetHeight
      // Merchant
      const isMerchantProductPage = this.$route.name === 'merchant-slug-products'
      const merchantHeader = document.querySelector('.merchant-header')
      const bottomMerchantHeaderTop = merchantHeader?.getBoundingClientRect()?.bottom
      const stickedMerchantMkpSubheading =
        bottomMkpSubheadingTop === mkpSubheading?.offsetHeight ||
        bottomMkpSubheadingTop === merchantHeader?.offsetHeight + mkpSubheading?.offsetHeight
      const stickedMerchantHeader = bottomMerchantHeaderTop === merchantHeader?.offsetHeight
      // Merchant Page
      if (isMerchantProductPage) {
        // If mkp-subheading is sticked, under it
        if (mkpSubheading && stickedMerchantMkpSubheading && !this.isXs) {
          return bottomMerchantHeaderTop + mkpSubheading.offsetHeight + basicTop
          // Else if merchant menu sticked, under it
        } else if (stickedMerchantHeader && !this.isXs) {
          return merchantHeader.offsetHeight + basicTop
          // Else if header is visible, under it
        } else if (window.scrollY < bottomHeaderTop && !this.isXs) {
          return bottomHeaderTop + basicTop
        }
        // Else stick to top
        return basicTop
      } else if (!isMerchantProductPage) {
        // If mkp-subheading is sticked
        if (mkpSubheading && stickedMkpSubheading && !this.isXs) {
          return bottomHeaderTop + mkpSubheading.offsetHeight + basicTop
        }
        // Else only take header bottom from window top
        return bottomHeaderTop + basicTop
      }
      return basicTop
    },
  },
}
