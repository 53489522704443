export default class Company {
  constructor(company) {
    Object.assign(this, company)
    this.hasPickUp = this.has_pick_up
    this.hasDelivery = this.has_delivery
    this.deliveryMethods = this.delivery_methods
  }

  get deliveryFee() {
    if (this.has_delivery) {
      // Get first delivery method that is not pick up
      const deliveryMethod = this.delivery_methods.find(r => r !== 'PICK_UP')
      // Use amount only for SELF_SHIPPING
      return deliveryMethod === 'SELF_SHIPPING' ? Number(this.delivery_fee) : null
    }
    return null
  }

  get offersDeliveryFreeOfCharge() {
    return this.delivery_free_of_charge_vat > 0
  }

  deliveryFreeOfCharge(vat = true) {
    return vat ? Number(this.delivery_free_of_charge_vat) : Number(this.delivery_free_of_charge)
  }
}
