
import Club from '@/mixins/club-mixin'
import i18nMx from '@/mixins/i18n-mixin'

export default {
  name: 'NavbarLeft',
  mixins: [Club, i18nMx],
  data() {
    return {
      routesMkp: ['index', 'product', 'category', 'brand', 'merchant'],
    }
  },
  computed: {
    user() {
      return this.$currentUser
    },
    stockButton() {
      return !this.isClub && this.user.isPro && !this.user.isFreemium
    },
  },
  methods: {
    getClubUrl(club) {
      if (process.client) {
        return window.location.host.includes('www')
          ? `${window.location.protocol}//${window.location.host.replace('www', club.key)}`
          : `${window.location.protocol}//${club.key}.${window.location.host}`
      } else {
        return ''
      }
    },
  },
}
