
import { mapGetters } from 'vuex'

export default {
  name: 'DevBanner',
  props: {
    onStaging: Boolean,
  },
  data() {
    return {
      clubBannerHeight: 0,
    }
  },
  computed: {
    ...mapGetters('club', ['isClub']),
  },
  mounted() {
    this.clubBannerHeight = document.querySelector('.club-banner')?.getBoundingClientRect()?.height || 0
  },
  beforeDestroy() {
    this.clubBannerHeight = null
  },
}
