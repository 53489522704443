// eslint-disable-next-line
export default ({}, inject) => {
  const screens = {
    CART: 'cart',
    ORDER_CONFIRMATION: 'OrderConfirmation',
    ADD_TO_CART: 'AddToCart',
    CART_CONFIRMATION_MODAL: 'AddToCartConfirmation',
    CGU: 'CGU',
    CGV: 'CGV',
    HOME: 'Home',
    CLUB_HOME: 'ClubHome',
    SIGNUP_SEARCH_COMPANY: 'InputCompanySearchCompany',
    SIGNUP_SEARCH_COMPANY_RESULTS: 'InputCompanySearchCompanyResults',
    SIGNUP_COMPANY_DETAILS: 'SubscriptionCompanyDetails',
    SIGNUP_PROMO_CODE: 'SubscriptionPromoCode',
    SIGNUP_OWNER_DETAILS: 'SubscriptionOwnerDetails',
    LOGIN: 'Login',
    LOGIN_CART: 'LoginCart',
    MARKETPLACE_SEARCH: 'MarketplaceSearch',
    MARKETPLACE_BRAND: 'MarketplaceBrand',
    MARKETPLACE_MERCHANT: 'MarketplaceMerchant',
    MARKETPLACE_MERCHANT_PRODUCTS: 'MarketplaceMerchantProducts',
    MARKETPLACE_MERCHANT_SEARCH: 'MarketplaceMerchantSearch',
    MARKETPLACE_MERCHANT_ABOUT_US: 'MarketplaceMerchantAboutUs',
    MARKETPLACE_CATEGORY: 'MarketplaceCategory',
    MARKETPLACE_NEW_PRODUCTS: 'MarketplaceNewProducts',
    MARKETPLACE_BEST_SELLERS: 'MarketplaceBestSellers',
    MARKETPLACE_BEST_DEALS: 'MarketplaceBestDeals',
    MARKETPLACE_SPECIAL_DISCOUNTS: 'MarketplaceSpecialDiscounts',
    ORDER_DETAILS: 'OrderDetail',
    PASSWORD: 'Password',
    PRODUCT_DETAIL: 'ProductDetail',
    ACTIVATION_CODE: 'ActivationCode',
    RESET_PASSWORD: 'ResetPassword',
    FILTER_BRANDS: 'SortFilterBrands',
    FILTER_CATEGORIES: 'SortFilterCategories',
    FILTER_DISTANCE: 'SortFilterDistance',
    TRANSACTIONS: 'Transactions',
    QUOTES: 'Quotes',
    DOWNLOAD: 'Download',
    BRANDS: 'Brands',
    PRESS: 'Press',
    PRESS_ARTICLE: 'PressArticle',
    JOBS: 'Jobs',
    JOB_POST: 'JobPost',
    PRIVACY: 'Privacy',
    STRIPE_ONBOARDING: 'StripeOnboarding',
    CHECKOUT: 'Checkout',
    CART_SUBSCRIBE: 'CartSubscribe',
    SUPPORT: 'Support',
    SUPPORT_ARTICLE: 'SupportArticle',
    BTOB: 'BtoB',
    CONTEST: 'Contest',
    PRICES: 'Prices',
    QRCODE: 'QRCode',
    LANDING: 'Landing',
  }

  const pageTemplate = {
    HOMEPAGE: 'homepage',
    CLUB_HOMEPAGE: 'club homepage',
    PRO_HOMEPAGE: 'pro homepage',
    CATEGORY: 'category page',
    BRAND: 'brand page',
    BRANDS_LIST: 'brands list page',
    LANDING: 'landing page',
    CONTACT: 'contact page',
    PRODUCT: 'product detail page',
    PRODUCTS_LIST: 'products list page',
    CART: 'cart page',
    CHECKOUT: 'checkout page',
    ORDER_CONFIRMATION: 'validation page',
    LOGIN: 'login page',
    DASHBOARD: 'dashboard page',
    ERROR: 'error page',
    BLOG: 'blog page',
    BLOG_ARTICLE: 'blog article page',
    MERCHANT: 'merchant page',
    ORDER: 'order page',
    ORDERS_LIST: 'orders list page',
    ISSUES: 'order issues page',
    PRESS: 'press page',
    PRESS_ARTICLE: 'press article page',
    PRO_BUNDLE: 'pro bundle page',
    PRO_SERVICE: 'pro service page',
    PLANS: 'plans page',
    ALERTS: 'alerts page',
    STRIPE_ONBOARDING: 'stripe onboarding page',
    PARTNER: 'partner page',
    PRO: 'pro page',
    STOCK: 'stock page',
    DOWNLOAD: 'download page',
    SIGNUP: 'signup page',
    SUPPORT: 'support page',
    LEGAL: 'legal page',
    MISSION: 'mission page',
    QRCODE: 'qrcode page',
    LP_INTERNATIONAL: 'LP de lancement',
  }

  inject('screens', screens)
  inject('pageTemplate', pageTemplate)
}
