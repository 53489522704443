export default function AxeptioEvent(callback) {
  if (window._axcb === undefined) {
    window._axcb = []
  }
  window._axcb.push(function (axeptio) {
    axeptio.on('cookies:complete', function () {
      // dataLayer à conditionner
      window.dataLayer = window.dataLayer || []
      callback()
    })
  })
}
