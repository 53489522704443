// This inject the default behavior for requesting StockPro API
// By default it handles a isLoading var
// By defaults it feeds the error var when needed

export default {
  data() {
    return {
      isLoading: false,
      error: null,
    }
  },
  methods: {
    async spRequest({ req, loader = true, context = this, errorMessages = [] }) {
      if (loader) context.isLoading = true
      context.error = null
      return req
        .then(response => {
          if (process.env.NODE_ENV !== 'production') {
            // console.log(response)
          }
          return Promise.resolve(response)
        })
        .catch(e => {
          // small hack to avoid 404 error if request is canceled
          if (e.message.includes('RequestCanceled')) {
            // console.log('resolve canceled request')
            return Promise.resolve()
          }
          if (e.message === 'maintenance_in_progress') {
            console.log('Maintenance in progress')
            return Promise.reject(e)
          }
          if (e?.response?.data?.message && !errorMessages.includes(e.response.data.message)) {
            context.error = context.$t
              ? context.$te(`backendError.${e.response.data.message}`)
                ? context.$t(`backendError.${e.response.data.message}`, {
                    phone: (context?.$store || context?.store).state?.i18nStore?.country?.phoneNumber,
                  })
                : context.$t(`backendError.unknown`, {
                    phone: (context?.$store || context?.store).state?.i18nStore?.country?.phoneNumber,
                  })
              : e.response.data.message
            throw new Error(e.response.data.message)
            // return Promise.reject(e.response.data.message);
          }
          context.error = context.$t
            ? context.$t(`backendError.unknown`, {
                phone: (context?.$store || context?.store).state?.i18nStore?.country?.phoneNumber,
              })
            : e
          if (process.env.NODE_ENV !== 'production') {
            console.log(context.error)
          }
          return Promise.reject(e)
        })
        .finally(() => {
          if (loader) context.isLoading = false
        })
    },
  },
}
