import Repository from './repository'

export default class GeoRepository extends Repository {
  async getGeo(filters = []) {
    const url = `${this.backendUrl}public/geo`
    let config = {
      params: {},
    }

    const zoomFilter = filters.find(filter => filter.type === 'ZOOM')
    if (zoomFilter?.value) config.params.zoom = zoomFilter.value?.toLowerCase()

    const areasFilter = filters.find(filter => filter.type === 'AREAS')
    if (areasFilter?.value) config.params.areas = areasFilter.value?.toLowerCase()

    return this.axios.get(url, config).then(response => response.data)
  }
}
