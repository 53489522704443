// import AxeptioEvent from '@/services/analytics/axeptio'
import sha256 from 'crypto-js/sha256'

export default class GTMAnalytics {
  constructor(gtm, i18n, store) {
    this.gtm = gtm
    this.i18n = i18n
    this.store = store
  }

  sendTagEvent(name, params) {
    // init dataLayer if not exist
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ...{ event: 'tagPlanEvent', eventName: name }, ...params })
  }

  sendEvent(name, params) {
    // init dataLayer if not exist
    window.dataLayer = window.dataLayer || []

    if (params?.ecommerce) {
      // Reset ecommerce payload before sending a new one
      window.dataLayer.push({ ecommerce: null })
    }
    if (name === 'login_status') {
      let currentUser = { ...params }
      params = {}
      currentUser?.auth?.loggedIn && !!currentUser?.auth?.user
        ? (params = {
            login_status: 'login_in',
            user_id: currentUser?.auth?.user?.uid,
            user_email: sha256(currentUser?.auth?.user?.email).toString(),
            user_type: currentUser?.auth?.user?.role?.name !== 'Individual' ? 'pro' : 'individual',
            user_status: 'none', // TODO, value missing from backend : buyer, seller, both
            user_subscription: 'none', // TODO, value missing from backend : CE, IM, PF
          })
        : (params = { login_status: 'not_login_in' })
    } else {
      params = {
        ...params,
      }
    }
    // console.log('sendEvent', { ...{ event: name }, ...params })
    window.dataLayer.push({ ...{ event: name }, ...params })
  }

  sendScreen(name, params = {}) {
    // init dataLayer if not exist
    window.dataLayer = window.dataLayer || []
    const event = {
      ...params,
      event: 'page_view',
      page_template: name,
      page_market: (this?.$store || this?.store).state?.i18nStore?.tld,
      page_language: (this?.$store || this?.store).state?.i18nStore?.locale?.toUpperCase(),
    }
    // console.log('sendScreen', event)
    window.dataLayer.push(event)
  }
}
