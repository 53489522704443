
export default {
  name: 'PartnerHeader',
  computed: {
    rawLanding() {
      return (
        this.$store.getters['landingDownload/landingDownload'] || this.$store.getters['landingDownload/landingDynamic']
      )
    },
  },
}
