export const state = () => ({
  requestIds: [],
})

export const mutations = {
  addRequestId(state, { requestId, slug }) {
    if (!state.requestIds.find(x => x.requestId == requestId && x.slug == slug)) {
      state.requestIds.push({ requestId, slug })
    }
  },
  clearRequests(state, { axios, slug }) {
    console.log('clearRequests slug', slug)
    state.requestIds.forEach((request, i) => {
      if (request.slug !== slug) {
        console.log(request.requestId)
        if (axios.cancelSourceMap[request.requestId]) {
          axios.cancelSourceMap[request.requestId].cancel(`RequestCanceled: ${request.requestId}`)
          console.log(`RequestCanceled: ${request.requestId}`)
          state.requestIds.splice(i, 1)
        }
      }
    })
  },
}
