
export default {
  name: 'ClubBanner',
  data() {
    return {
      link: null,
    }
  },
  mounted() {
    this.link = `${window.location.protocol}//www.${window.location.host.substring(
      window.location.host.indexOf('.') + 1
    )}`
  },
}
