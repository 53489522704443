import ApiService from '@/services/ApiService'

export default async ({ $axios, app }, inject) => {
  inject(
    'api',
    new ApiService({
      axios: $axios,
      backendUrl: process.env.PROXY_URL,
      storyApi: app.$storyapi,
    })
  )
}
