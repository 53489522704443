export default class OrderMapperHelper {
  static searchMapping = JSON.stringify({
    mapping: {
      order: [
        'uid',
        'quote_id',
        'number',
        'created_at',
        'status',
        'has_delivery',
        'delivery_method',
        'delivery_address',
        'total_price',
        'total_price_vat',
        'total_price_vat_nc',
        'total_price_no_vat_nc',
        'total_items_price',
        'total_items_price_vat',
        'total_discount_vat',
        'total_discounted_price',
        'total_discounted_price_vat',
        'previous_total_price',
        'fee',
        'vat',
        'accreditation',
        'has_issues',
        'current_cart_quantity',
        'mkp_reserved_qty',
        'total_price_without_fee',
        'total_fee',
        'price',
        'issue_kind',
        'issue_details',
        'previous_total_price_without_fee',
        'previous_total_price_vat',
        'previous_quantity',
        'negotiated_price',
        'billing_expected_amount',
        'worksite_reference',
        {
          company: [
            'name',
            'slug',
            {
              mkp_contact: ['phone', 'phone_number'],
            },
          ],
        },
        {
          user: ['uid', 'first_name', 'last_name', 'phone', 'phone_number'],
        },
        {
          stocks: [
            'uid',
            'current_cart_quantity',
            'mkp_reserved_qty',
            'total_price_without_fee',
            'total_fee',
            'price',
            'issue_kind',
            'selling_quantity',
            'issue_details',
            'previous_total_price_without_fee',
            'previous_total_price_vat',
            'previous_quantity',
            'price_without_commission',
          ],
        },
      ],
    },
  })

  static orderMapping = JSON.stringify({
    mapping: {
      order: [
        'uid',
        'number',
        'quote_id',
        'created_at',
        'status',
        'has_delivery',
        'delivery_method',
        'delivery_address',
        'total_price',
        'total_price_vat',
        'total_items_price',
        'total_items_price_vat',
        'total_discount_vat',
        'total_discounted_price',
        'total_discounted_price_vat',
        'cart_total_discount_vat',
        'previous_total_price',
        'vat_without_comission',
        'refund_amount',
        'fee',
        'vat',
        'delivery_code',
        'invoice_url',
        'documents',
        'billing_status',
        'billing_amount',
        'billing_expected_amount',
        'billing_amount_difference',
        'accreditation',
        'has_issues',
        'current_cart_quantity',
        'mkp_reserved_qty',
        'total_price_without_fee',
        'total_fee',
        'price',
        'issue_kind',
        'issue_details',
        'previous_total_price_without_fee',
        'previous_total_price_vat',
        'previous_quantity',
        'negotiated_price',
        'worksite_reference',
        {
          company: [
            'uid',
            'name',
            'slug',
            {
              mkp_contact: ['phone', 'phone_number'],
            },
            {
              addresses: ['city', 'complement', 'kind', 'name', 'street', 'uid', 'zip_code', 'country', 'country_iso'],
            },
          ],
        },
        {
          user: [
            'uid',
            'first_name',
            'last_name',
            'phone',
            'phone_number',
            'email',
            'role',
            {
              company: [
                'uid',
                'name',
                'slug',
                {
                  mkp_contact: ['phone', 'phone_number'],
                },
                {
                  addresses: [
                    'city',
                    'complement',
                    'kind',
                    'name',
                    'street',
                    'uid',
                    'zip_code',
                    'country',
                    'country_iso',
                  ],
                },
              ],
            },
          ],
        },
        {
          stocks: [
            'uid',
            'expiry_date',
            'price',
            'price_vat',
            'total_price',
            'total_price_vat',
            'total_price_without_fee',
            'total_price',
            'previous_total_price',
            'previous_total_price_without_fee',
            'previous_total_price_vat',
            'previous_quantity',
            'expiry_date_status',
            'issue_details',
            'issue_kind',
            'quantity',
            'quantityType',
            'quantity_type',
            'current_cart_quantity',
            'mkp_reserved_qty',
            'total_fee',
            'price_without_commission',
            'selling_quantity',
            {
              reference: [
                'name',
                'slug',
                'ref_co',
                'ean',
                'weight',
                'unit_weight',
                'weight_unit',
                'accreditation',
                {
                  category: ['name'],
                },
                {
                  brand: ['name'],
                },
                {
                  images: ['url', 'url_thumbnail', 'url_medium', 'url_large'],
                },
              ],
            },
            {
              storage: [
                'uid',
                'name',
                'address',
                'zip_code',
                'city',
                'country',
                'kind',
                {
                  parent: ['uid', 'name', 'address', 'zip_code', 'city', 'country', 'kind'],
                },
              ],
            },
          ],
        },
      ],
    },
  })

  static frozenOrderMapping = JSON.stringify({
    mapping: {
      order: [
        'uid',
        'expiry_date',
        'price',
        'price_vat',
        'total_price',
        'total_price_vat',
        'total_price_without_fee',
        'total_price',
        'previous_total_price',
        'previous_total_price_without_fee',
        'previous_total_price_vat',
        'previous_quantity',
        'expiry_date_status',
        'issue_details',
        'issue_kind',
        'quantity',
        'quantityType',
        'quantity_type',
        'current_cart_quantity',
        'mkp_reserved_qty',
        'total_fee',
        'price_without_commission',
        'selling_quantity',
        'stock_uid',
      ],
    },
  })

  static badgeMapping = JSON.stringify({
    mapping: {
      order: ['status', 'has_delivery'],
    },
  })
}
