import Repository from './repository'

export default class DownloadFilesRepository extends Repository {
  async whiteBook(token) {
    const url = `${this.backendUrlV2}download_files/white_book/${token}`
    let config = {
      responseType: 'blob',
    }
    return this.axios.get(url, config).then(response => response.data)
  }

  async monthlyReport(token) {
    const url = `${this.backendUrl}download_files/monthly_report/${token}`
    let config = {
      responseType: 'blob',
    }
    return this.axios.get(url, config).then(response => response.data)
  }
}
