let defaultImage = 'https://d3brsr9pdomwt0.cloudfront.net/illustrations/empty-product-img.png'

export default class References {
  constructor(reference, country) {
    Object.assign(this, reference)
    this.name = reference.name
    this.slug = reference.slug
    this.brand = reference.brand
    this.uid = reference.uid
    this.images = reference.images
    this.price = reference.recommended_price
    this.category = reference.category
    this.description = reference.description
    this.publicStocks = reference.public_stocks
    this.availableStocks = reference.public_stocks_count
    this.refCo = reference.ref_co
    this.hasDelivery = reference.has_delivery
    this.manualLink = reference.manual_link
    this.weight = reference.weight
    this.unitWeight = reference.unit_weight
    this.weightUnit = reference.weight_unit
    this.publicStocksQuantities = reference.public_stocks_quantities
    this.accreditation = reference.accreditation
    this.ecoTax = reference.eco_tax
    this.relatedReferences = reference.related_references
    this.country = country
    if (reference.ean !== null && reference.ean !== undefined) {
      if (!reference.ean.includes('stockpro_ean')) {
        this.ean = reference.ean
      }
    }
    if (this.images !== null && this.images !== undefined && this.images.length > 0) {
      if (this.images[0].url_thumbnail !== undefined && this.images[0].url_thumbnail !== null) {
        this.thumbnailUrl = this.images[0].url_thumbnail
      } else {
        this.thumbnailUrl = this.images[0].url
      }
    } else {
      this.thumbnailUrl = defaultImage
    }
  }

  static placeholderReference() {
    return new References(
      {
        name: 'NA',
        brand: 'NA',
        uid: '',
        category: null,
      },
      this.country
    )
  }

  get path() {
    return `${this.uid}-${encodeURI(this.name)}`
  }

  static findNextCategoryName(currentCategory) {
    if (currentCategory.parent !== null && currentCategory.parent !== undefined) {
      if (currentCategory.parent.name !== null && currentCategory.parent.name !== undefined) {
        return currentCategory.parent.name
      }

      return null
    }
    return null
  }

  get trackingId() {
    if (this.ean !== null && this.ean !== undefined) {
      return this.ean
    }
    if (this.refCo !== null && this.refCo !== undefined) {
      return this.refCo
    }
    return this.uid
  }

  get hasCategory() {
    if (this.category !== null && this.category !== undefined) return true
    return false
  }

  get categoryName() {
    if (this.hasCategory && this.category.name !== undefined && this.category.name !== null) return this.category.name
    return ''
  }

  get categoriesName() {
    if (this.hasCategory && this.category.name !== undefined && this.category.name !== null) {
      const categoriesName = [this.categoryName]
      let currentCategory = this.category
      let hasNext = true
      while (hasNext) {
        if (References.findNextCategoryName(currentCategory) !== null) {
          categoriesName.push(References.findNextCategoryName(currentCategory))
          currentCategory = currentCategory.parent
        } else hasNext = false
      }
      return categoriesName.reverse()
    }

    return []
  }

  get hasBrand() {
    return this.brand !== null && this.brand !== undefined && Object.keys(this.brand).length > 0
  }

  get hasBrandLogo() {
    return (
      this.hasBrand &&
      this.brand.logo_url !== undefined &&
      this.brand.logo_url !== null &&
      this.brand.logo_url.length > 0
    )
  }

  get brandName() {
    if (this.hasBrand) return this.brand.name
    return null
  }

  get brandUid() {
    if (this.hasBrand) return this.brand.uid
    return null
  }

  get numberOfImages() {
    if (this.images !== null && this.images !== undefined) return this.images.length
    return 0
  }

  get mainImageUrl() {
    if (this.numberOfImages > 0) return this.images[0].url_medium

    return defaultImage
  }

  get imagesUrl() {
    if (this.numberOfImages > 0) {
      return this.images.map(image => image.url_medium)
    }
    return []
  }

  get hasStock() {
    return (
      (this.bestStock !== null && this.bestStock !== undefined) ||
      (this.publicStocks !== null && this.publicStocks !== undefined && this.publicStocks.length > 0)
    )
  }

  get bestStock() {
    return this.best_stock_by_country?.[this.country?.code] === undefined
      ? this.best_stock
      : this.best_stock_by_country?.[this.country?.code]
  }

  get publicStocksQuantitiesByCountry() {
    return this.public_stocks_quantities_by_country?.[this.country?.code] === undefined
      ? this.public_stocks_quantities
      : this.public_stocks_quantities_by_country?.[this.country?.code]
  }
}
