export const SbAdditionalServicesBanner = () => import('../../components/storyblok/SbAdditionalServicesBanner.vue' /* webpackChunkName: "components/sb-additional-services-banner" */).then(c => wrapFunctional(c.default || c))
export const SbAdditionalServicesPage = () => import('../../components/storyblok/SbAdditionalServicesPage.vue' /* webpackChunkName: "components/sb-additional-services-page" */).then(c => wrapFunctional(c.default || c))
export const SbAdditionalServicesSection = () => import('../../components/storyblok/SbAdditionalServicesSection.vue' /* webpackChunkName: "components/sb-additional-services-section" */).then(c => wrapFunctional(c.default || c))
export const SbAgecSection = () => import('../../components/storyblok/SbAgecSection.vue' /* webpackChunkName: "components/sb-agec-section" */).then(c => wrapFunctional(c.default || c))
export const SbAvatar = () => import('../../components/storyblok/SbAvatar.vue' /* webpackChunkName: "components/sb-avatar" */).then(c => wrapFunctional(c.default || c))
export const SbAvatarList = () => import('../../components/storyblok/SbAvatarList.vue' /* webpackChunkName: "components/sb-avatar-list" */).then(c => wrapFunctional(c.default || c))
export const SbButtonsLinkHover = () => import('../../components/storyblok/SbButtonsLinkHover.vue' /* webpackChunkName: "components/sb-buttons-link-hover" */).then(c => wrapFunctional(c.default || c))
export const SbContactForm = () => import('../../components/storyblok/SbContactForm.vue' /* webpackChunkName: "components/sb-contact-form" */).then(c => wrapFunctional(c.default || c))
export const SbContainerBanner = () => import('../../components/storyblok/SbContainerBanner.vue' /* webpackChunkName: "components/sb-container-banner" */).then(c => wrapFunctional(c.default || c))
export const SbContent = () => import('../../components/storyblok/SbContent.vue' /* webpackChunkName: "components/sb-content" */).then(c => wrapFunctional(c.default || c))
export const SbCta = () => import('../../components/storyblok/SbCta.vue' /* webpackChunkName: "components/sb-cta" */).then(c => wrapFunctional(c.default || c))
export const SbCtaBanner = () => import('../../components/storyblok/SbCtaBanner.vue' /* webpackChunkName: "components/sb-cta-banner" */).then(c => wrapFunctional(c.default || c))
export const SbCtaGroup = () => import('../../components/storyblok/SbCtaGroup.vue' /* webpackChunkName: "components/sb-cta-group" */).then(c => wrapFunctional(c.default || c))
export const SbDemoBanner = () => import('../../components/storyblok/SbDemoBanner.vue' /* webpackChunkName: "components/sb-demo-banner" */).then(c => wrapFunctional(c.default || c))
export const SbDownloadBanner = () => import('../../components/storyblok/SbDownloadBanner.vue' /* webpackChunkName: "components/sb-download-banner" */).then(c => wrapFunctional(c.default || c))
export const SbFeatureBlock = () => import('../../components/storyblok/SbFeatureBlock.vue' /* webpackChunkName: "components/sb-feature-block" */).then(c => wrapFunctional(c.default || c))
export const SbFeaturesSection = () => import('../../components/storyblok/SbFeaturesSection.vue' /* webpackChunkName: "components/sb-features-section" */).then(c => wrapFunctional(c.default || c))
export const SbFullwidthBanner = () => import('../../components/storyblok/SbFullwidthBanner.vue' /* webpackChunkName: "components/sb-fullwidth-banner" */).then(c => wrapFunctional(c.default || c))
export const SbNetworkSection = () => import('../../components/storyblok/SbNetworkSection.vue' /* webpackChunkName: "components/sb-network-section" */).then(c => wrapFunctional(c.default || c))
export const SbNewsCard = () => import('../../components/storyblok/SbNewsCard.vue' /* webpackChunkName: "components/sb-news-card" */).then(c => wrapFunctional(c.default || c))
export const SbOfferDetail = () => import('../../components/storyblok/SbOfferDetail.vue' /* webpackChunkName: "components/sb-offer-detail" */).then(c => wrapFunctional(c.default || c))
export const SbOffersSection = () => import('../../components/storyblok/SbOffersSection.vue' /* webpackChunkName: "components/sb-offers-section" */).then(c => wrapFunctional(c.default || c))
export const SbOffersSelectionGrid = () => import('../../components/storyblok/SbOffersSelectionGrid.vue' /* webpackChunkName: "components/sb-offers-selection-grid" */).then(c => wrapFunctional(c.default || c))
export const SbPlan = () => import('../../components/storyblok/SbPlan.vue' /* webpackChunkName: "components/sb-plan" */).then(c => wrapFunctional(c.default || c))
export const SbPlansGroup = () => import('../../components/storyblok/SbPlansGroup.vue' /* webpackChunkName: "components/sb-plans-group" */).then(c => wrapFunctional(c.default || c))
export const SbPricingPlan = () => import('../../components/storyblok/SbPricingPlan.vue' /* webpackChunkName: "components/sb-pricing-plan" */).then(c => wrapFunctional(c.default || c))
export const SbProContent = () => import('../../components/storyblok/SbProContent.vue' /* webpackChunkName: "components/sb-pro-content" */).then(c => wrapFunctional(c.default || c))
export const SbRichText = () => import('../../components/storyblok/SbRichText.vue' /* webpackChunkName: "components/sb-rich-text" */).then(c => wrapFunctional(c.default || c))
export const SbSeoContent = () => import('../../components/storyblok/SbSeoContent.vue' /* webpackChunkName: "components/sb-seo-content" */).then(c => wrapFunctional(c.default || c))
export const SbServicesResumeSection = () => import('../../components/storyblok/SbServicesResumeSection.vue' /* webpackChunkName: "components/sb-services-resume-section" */).then(c => wrapFunctional(c.default || c))
export const SbServicesSection = () => import('../../components/storyblok/SbServicesSection.vue' /* webpackChunkName: "components/sb-services-section" */).then(c => wrapFunctional(c.default || c))
export const SbSubscribeModal = () => import('../../components/storyblok/SbSubscribeModal.vue' /* webpackChunkName: "components/sb-subscribe-modal" */).then(c => wrapFunctional(c.default || c))
export const SbSubtitle = () => import('../../components/storyblok/SbSubtitle.vue' /* webpackChunkName: "components/sb-subtitle" */).then(c => wrapFunctional(c.default || c))
export const SbTable = () => import('../../components/storyblok/SbTable.vue' /* webpackChunkName: "components/sb-table" */).then(c => wrapFunctional(c.default || c))
export const SbTestimoniesTabs = () => import('../../components/storyblok/SbTestimoniesTabs.vue' /* webpackChunkName: "components/sb-testimonies-tabs" */).then(c => wrapFunctional(c.default || c))
export const SbTitle = () => import('../../components/storyblok/SbTitle.vue' /* webpackChunkName: "components/sb-title" */).then(c => wrapFunctional(c.default || c))
export const SbVideoSection = () => import('../../components/storyblok/SbVideoSection.vue' /* webpackChunkName: "components/sb-video-section" */).then(c => wrapFunctional(c.default || c))
export const SbWhiteBookForm = () => import('../../components/storyblok/SbWhiteBookForm.vue' /* webpackChunkName: "components/sb-white-book-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
