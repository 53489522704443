
import Mobile from '@/mixins/mobile-mixin'
export default {
  name: 'SbButtonsLinkHover',
  mixins: [Mobile],
  props: {
    link: { type: Object, default: () => {} },
    click: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    toggleButton() {
      if (this.click && !this.isSm) {
        this.open = !this.open
      }
    },
  },
}
