// TODO rework
export default ({ i18n, req, app }) => {
  let locale
  if (app.$cookies.get('lang')) {
    locale = app.$cookies.get('lang')
  } else if (process.server) {
    locale = req.headers.host?.split('.')?.pop()
  } else if (process.client) {
    locale = location.host?.split('.')?.pop()
  }
  if (locale.includes(':')) {
    // for dev env, delete port
    locale = locale.substring(0, locale.indexOf(':'))
  }
  i18n.setLocale(locale || 'fr')
}
