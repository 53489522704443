export const state = () => ({
  featured: [],
})

export const getters = {
  featured: state => state.featured,
  featuredRefBySlug: state => slug => state.featured.find(ref => ref?.slug === slug),
}

export const mutations = {
  addFeaturedRef(state, reference) {
    state.featured.push(reference)
    state.featured = state.featured.filter((ref, index, self) => index === self.findIndex(r => r?.uid === ref?.uid))
  },
}
