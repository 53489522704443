
export default {
  name: 'ExpiryDateLabel',
  props: {
    stock: { type: Object, default: () => null },
    plainText: { type: Boolean, default: false },
    classVariantLabel: { type: String, default: null },
    classVariantValue: { type: String, default: null },
  },
  methods: {
    formattedDate() {
      const date = new Date(this.stock.expiryDate)
      if (date) {
        const parsedDate = date.toLocaleDateString()
        if (parsedDate) {
          // replace all crash sometimes
          return parsedDate.replace('/', '.').replace('/', '.')
        }
      }
      return null
    },
  },
}
