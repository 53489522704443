import Cookie from 'js-cookie'
import countries from '@/lang/countries'

export default async function load({ store }) {
  if (location?.search?.includes('axeptio_disabled=1')) {
    Cookie.set('axeptio_disabled', '1')
  }
  if (process.client && !!Number(process.env.AXEPTIO_ENABLED && !Cookie.get('axeptio_disabled'))) {
    let domain = location?.host?.replace('www.', '')?.split(':').shift()
    let tld = store?.state?.i18nStore?.tld?.toLowerCase()
    let lang = [tld, store?.state?.i18nStore?.locale]
    lang = lang.filter((x, i) => lang.indexOf(x) === i).slice(0, 2)
    let axeptioKey = countries.find(x => x.code === tld)?.axeptioKey
    // console.log(`stockpro-${lang.join('-')}`, domain)
    window.axeptioSettings = {
      clientId: axeptioKey,
      userCookiesDomain: domain || process.env.PRODUCTION_DOMAIN,
      cookiesVersion: `stockpro-${lang.join('-')}`,
    }
    ;(function (d, s) {
      var t = d.getElementsByTagName(s)[0],
        e = d.createElement(s)
      e.async = true
      e.src = '//static.axept.io/sdk-slim.js'
      t.parentNode.insertBefore(e, t)
    })(document, 'script')
    window._axcb = window._axcb || []
    window._axcb.push(function (axeptio) {
      axeptio.on('cookies:complete', function () {
        window.dataLayer = window.dataLayer || []
      })
      // axeptio.on('consent:saved', function () {
      //   window.location.reload()
      // })
    })
  }
}
