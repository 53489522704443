export default function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }
  if (!to.hash || !to.hash.length) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 50)
    })
  }
}
