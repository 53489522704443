import Vue from 'vue'
import reject from 'lodash.reject'

export const state = () => ({
  items: [],
  deliveryMethods: [],
  coupons: [],
  expire: 1,
  syncError: null,
  isReady: false,
  // We must have both cartUid and remoteCart object
  cartUid: null,
  remoteCart: null,
  deliveryAddress: null,
  version: 0,
  utm: {},
})

export const getters = {
  items: state => state.items,
  deliveryMethods: state => state.deliveryMethods,
  coupons: state => state.coupons,
  syncError: state => state.syncError,
  isReady: state => state.isReady,
  cartUid: state => state.cartUid,
  remoteCart: state => state.remoteCart,
  version: state => state.version,
  isInCart: state => refUid => !!state.items.find(x => x.stock.uid === refUid),
  utm: state => state.utm,
}

export const mutations = {
  setCart(state, cart) {
    Object.assign(state, cart)
  },
  addItem(state, item) {
    state.items.push({
      stock: item.stock,
      quantity: item.quantity,
    })
  },
  updateItem(state, { item, index }) {
    Vue.set(state.items, index, {
      stock: item.stock,
      quantity: item.quantity,
    })
  },
  remove(state, item) {
    state.items = reject(state.items, el => el.stock.uid === item.stock.uid)
  },
  setDeliveryMethod(state, deliveryMethod) {
    const index = state.deliveryMethods.findIndex(el => el.merchantUid === deliveryMethod.merchantUid)
    if (index === -1) {
      state.deliveryMethods.push(deliveryMethod)
    } else {
      Vue.set(state.deliveryMethods, index, deliveryMethod)
    }
  },
  removeDeliveryMethod(state, merchantUid) {
    state.deliveryMethods = reject(state.deliveryMethods, el => el.merchantUid === merchantUid)
  },
  addCoupon(state, coupon) {
    state.coupons.push(coupon)
  },
  clearCoupons(state) {
    state.coupons = []
  },
  setAddress(state, address) {
    if (address.kind === 'SHIPPING') {
      state.deliveryAddress = address
    }
  },
  setSyncError(state, error) {
    state.syncError = error
  },
  setIsReady(state, isReady) {
    state.isReady = isReady
  },
  setCartUid(state, uid) {
    state.cartUid = uid
  },
  setRemoteCart(state, cart) {
    state.remoteCart = cart
  },
  setVersion(state, version) {
    state.version = version
  },
  clearCart(state) {
    state.isReady = false
    state.items = []
    state.coupons = []
    state.remoteCart = null
    state.cartUid = null
    state.deliveryAddress = null
    state.deliveryMethods = []
  },
  clearRemoteCart(state) {
    state.remoteCart = null
  },
  setUtm(state, utm) {
    Object.assign(state.utm, utm)
  },
}
