import Filter from '@/models/Filter'

export default class QueryHelper {
  constructor(ctx) {
    this.ctx = ctx
  }

  recursiveFilter(item, type, query, label, testId) {
    let filter = new Filter({
      label: label || item.name,
      value: item.uid,
      query,
      data: {},
      type,
      testId,
    })
    if (item.parent) {
      filter.data.parent = item.parent
    }
    if (item.children) {
      filter.data.children = item.children.map(child => {
        let label = child.name
        if (type === 'STORAGE') {
          label = `${child.name} (${child.stocks_count})`
        }
        return this.recursiveFilter(child, type, query, label)
      })
    }
    return filter
  }

  getPage() {
    let { page } = this.ctx.route.query
    if (page === undefined || page === null || Number.isNaN(page)) page = 1

    page = parseInt(page, 10)
    page = page <= 0 ? 1 : page
    return page
  }
  getPerPage() {
    let { perPage } = this.ctx.route.query
    if (perPage === undefined || perPage === null || Number.isNaN(perPage)) perPage = 15

    perPage = parseInt(perPage, 10)
    perPage = perPage <= 0 ? 1 : perPage
    return perPage
  }

  getFilters(saveOnStore = true) {
    const filters = []

    const {
      query,
      brands,
      bookedBy,
      categories,
      storages,
      hasAlert,
      isPublic,
      locality,
      delivery,
      minPrice,
      maxPrice,
      orderBy,
      sortBy,
      sortDirection,
      merchants,
      company,
      withVAT,
      channel,
      freshness,
      bestDeals,
      tags,
      page,
      perPage,
      merchantTags,
      merchant_tags,
    } = this.ctx.route.query

    if (query) {
      filters.push(
        new Filter({
          label: query,
          value: query,
          query: 'query',
          type: 'QUERY',
        })
      )
    }

    if (company) {
      filters.push(
        new Filter({
          label: company,
          value: company,
          query: 'company',
          type: 'COMPANY',
        })
      )
    }

    if (delivery) {
      filters.push(
        new Filter({
          label: this.ctx.app.i18n.t('search.deliveryAvailable'),
          value: true,
          query: 'delivery',
          type: 'DELIVERY',
        })
      )
    }

    if (minPrice) {
      filters.push(
        new Filter({
          label: this.ctx.app.i18n.tc('search.minPrice', minPrice),
          value: minPrice,
          query: 'minPrice',
          type: 'MIN_PRICE',
        })
      )
    }

    if (maxPrice) {
      filters.push(
        new Filter({
          label: this.ctx.app.i18n.tc('search.maxPrice', maxPrice),
          value: maxPrice,
          query: 'maxPrice',
          type: 'MAX_PRICE',
        })
      )
    }

    if (withVAT) {
      filters.push(
        new Filter({
          label: null,
          value: withVAT,
          query: 'withVAT',
          type: 'WITH_VAT',
          display: false,
        })
      )
    }

    if (orderBy) {
      filters.push(
        new Filter({
          label: null,
          value: orderBy,
          query: 'orderBy',
          type: 'ORDER_BY',
          display: false,
        })
      )
    }

    if (sortBy && sortDirection) {
      filters.push(
        new Filter({
          label: null,
          value: sortBy,
          query: 'sortBy',
          type: 'SORT_BY',
          display: false,
        })
      )
      filters.push(
        new Filter({
          label: null,
          value: sortDirection,
          query: 'sortDirection',
          type: 'SORT_DIRECTION',
          display: false,
        })
      )
    }

    if (locality) {
      const localityFilters = decodeURIComponent(locality).split('+')
      if (localityFilters.length > 0) {
        const localityFilter = new Filter({
          label: localityFilters[0],
          value: decodeURIComponent(locality),
          query: 'locality',
          type: 'LOCALITY',
        })
        filters.push(localityFilter)
      }
    }

    const freshnessFilter = []

    if (freshness) {
      const freshnessSplit = freshness.split(',')

      freshnessSplit.forEach(value => {
        freshnessFilter.push(
          new Filter({
            label: this.ctx.app.i18n.t(`search.freshness${value}`),
            value: value,
            query: 'mkp_freshness',
            type: 'FRESHNESS',
          })
        )
      })
    }
    filters.push(...freshnessFilter)

    const bestDealsFilter = []

    if (bestDeals) {
      const bestDealsSplit = bestDeals.split(',')

      bestDealsSplit.forEach(value => {
        const bdSplit = value.split('_')

        bestDealsFilter.push(
          new Filter({
            label: this.ctx.app.i18n.t(`search.bestDeals${bdSplit[0]}${bdSplit[1]}`),
            value: value,
            query: `${bdSplit[1] === '-' ? 'under_' : ''}best_deal`,
            type: 'BESTDEALS',
          })
        )
      })
    }
    filters.push(...bestDealsFilter)

    const brandsFilter = []
    if (brands) {
      const brandUids = brands.split(',')
      brandUids.forEach(uid => {
        const brand = this.ctx.store.getters['brands/brandByUid'](uid)
        if (brand) {
          brandsFilter.push(
            new Filter({
              label: brand.name,
              value: brand.uid,
              query: 'brands',
              type: 'BRAND',
            })
          )
        }
      })
    }
    filters.push(...brandsFilter)

    const bookedByFilter = []
    if (bookedBy) {
      const userUids = bookedBy.split(',')
      userUids.forEach(uid => {
        const user = this.ctx.store.getters['users/userWithBookedStockByUid'](uid)
        if (user) {
          bookedByFilter.push(
            new Filter({
              // label: `${user.first_name} ${user.last_name} (${user.booked_products_count})`,
              label: `${user.first_name} ${user.last_name}`,
              value: user.uid,
              query: 'booked_by',
              type: 'BOOKED_BY',
            })
          )
        }
      })
    }
    filters.push(...bookedByFilter)

    const storagesFilter = []
    if (storages) {
      const storageUids = storages.split(',')
      storageUids.forEach(uid => {
        const storage = this.ctx.store.getters['storages/storageByUid'](uid)
        if (storage) {
          storagesFilter.push(
            this.recursiveFilter(storage, 'STORAGE', 'storages', `${storage.name} (${storage.stocks_count})`)
          )
        }
      })
    }
    filters.push(...storagesFilter)

    const categoriesFilter = []
    if (categories) {
      const categoryUids = categories.split(',')
      categoryUids.forEach(uid => {
        const category = this.ctx.store.getters['categories/availableCategoryByUid'](uid)
        if (category) {
          categoriesFilter.push(
            new Filter({
              label: category.name,
              value: category.uid,
              query: 'categories',
              type: 'CATEGORY',
            })
          )
        }
      })
    }
    filters.push(...categoriesFilter)

    if (hasAlert) {
      filters.push(
        new Filter({
          label: this.ctx.app.i18n.t('search.has_alert'),
          value: true,
          query: 'hasAlert',
          type: 'HAS_ALERT',
        })
      )
    }

    if (isPublic) {
      filters.push(
        new Filter({
          label: this.ctx.app.i18n.t('search.is_public'),
          value: true,
          query: 'isPublic',
          type: 'IS_PUBLIC',
        })
      )
    }

    const merchantsFilter = []
    if (merchants) {
      const merchantUids = merchants.split(',')
      merchantUids.forEach(uid => {
        const merchant = this.ctx.store.getters['merchants/merchantByUid'](uid)
        if (merchant) {
          merchantsFilter.push(
            new Filter({
              label: merchant.name,
              value: merchant.uid,
              query: 'merchants',
              type: 'MERCHANT',
            })
          )
        }
      })
    }
    filters.push(...merchantsFilter)

    const channelsFilters = []
    if (channel) {
      let channelsQueries = channel.split(',')
      channelsQueries.forEach(channel => {
        channelsFilters.push(
          new Filter({
            value: channel,
            query: 'channel',
            type: 'CHANNEL',
          })
        )
      })
    }
    filters.push(...channelsFilters)

    if (tags) {
      let tagsQueries = tags.split(',')
      tagsQueries.forEach(tag => {
        filters.push(
          new Filter({
            label: null,
            value: tag,
            query: 'tags',
            type: 'TAGS',
            display: false,
          })
        )
      })
    }
    if (merchant_tags || merchantTags) {
      let merchantTagsQueries = (merchant_tags || merchantTags).split(',')
      merchantTagsQueries.forEach(tag => {
        filters.push(
          new Filter({
            label: null,
            value: tag,
            query: 'merchant_tags',
            type: 'MERCHANT_TAGS',
            display: false,
          })
        )
      })
    }

    if (page) {
      filters.push(
        new Filter({
          label: null,
          value: Number(page),
          query: 'page',
          type: 'PAGE',
          display: false,
        })
      )
    }
    if (perPage) {
      filters.push(
        new Filter({
          label: null,
          value: Number(perPage),
          query: 'perPage',
          type: 'PER_PAGE',
          display: false,
        })
      )
    }

    if (saveOnStore) {
      this.ctx.app.store.commit('filters/setActiveFilters', filters)
    }
    return {
      filters,
    }
  }

  async syncOrdersFilters(vuexStore) {
    const { number, page, start_date, end_date, sort, order_by } = this.ctx.route.query

    this.ctx.app.store.commit(`${vuexStore}/setAutoUpdate`, false)
    this.ctx.app.store.commit(`${vuexStore}/setQuery`, number)
    if (page) {
      this.ctx.app.store.commit(`${vuexStore}/setCurrentPage`, Number(page))
    } else {
      this.ctx.app.store.commit(`${vuexStore}/setCurrentPage`, 1)
    }
    this.ctx.app.store.commit(`${vuexStore}/setSort`, { key: sort, value: order_by })
    const filters = []
    if (start_date) {
      filters.push({ key: 'start_date', value: start_date })
    }
    if (end_date) {
      filters.push({ key: 'end_date', value: end_date })
    }
    this.ctx.app.store.commit(`${vuexStore}/setFilters`, filters)
    await this.ctx.app.store.dispatch(`${vuexStore}/updateResults`)
    this.ctx.app.store.commit(`${vuexStore}/setAutoUpdate`, true)
  }
}
