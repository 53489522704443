
import defaultHead from '@/utils/defaultHead'
import { defaultHeadMeta } from '@/utils/defaultHead'
import Theme from '@/mixins/theme-mixin'
import Club from '@/mixins/club-mixin'
import Mobile from '@/mixins/mobile-mixin'
import addToCart from '@/mixins/add-to-cart-mixin'
import HeadMx from '@/mixins/head-mixin'

import Snackbar from '@/components/Snackbar/Snackbar'
import ClubFooter from '@/components/Footer/ClubFooter'
import Header from '@/components/Header/Header'
import CartConfirmationModal from '@/components/Modals/CartConfirmationModal/CartConfirmationModal'
import DevBanner from '@/components/Banners/DevBanner/DevBanner'
import ClubBackMkpBanner from '@/components/Banners/ClubBackMkpBanner/ClubBackMkpBanner'
import ConfirmationModal from '@/components/Modals/ConfirmationModal/ConfirmationModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'ClubLayout',
  components: {
    Snackbar,
    ClubFooter,
    Header,
    CartConfirmationModal,
    DevBanner,
    ClubBackMkpBanner,
    ConfirmationModal,
    MainButton,
  },
  mixins: [Theme, Club, Mobile, addToCart, HeadMx],
  data() {
    return {
      modals: 0,
    }
  },
  head() {
    return {
      ...defaultHead(this.$locale),
      meta: defaultHeadMeta,
      link: [
        {
          type: 'image/jpg',
          rel: 'icon',
          name: 'favicon',
          href: this.clubConfig?.favicon,
        },
      ],
    }
  },
  computed: {
    onStaging() {
      return process.env.ENV_NAME === 'STAGING'
    },
    onQA() {
      return process.env.ENV_NAME === 'QA'
    },
  },
  beforeMount() {
    this.$analytics.sendEvent(this.$events.LOGIN_STATUS, this.$currentUser)
    this.$root.$on('addToCartAction', data => {
      this.stock = data.stock
      this.quantity = data.quantity
      this.fastAddToCart = data.fastAddToCart
      this.addToCart(data.accepted, this.stock, this.quantity, this.fastAddToCart)
    })
  },
  mounted() {
    const utms = this.getUtmTags()
    utms.forEach(utm => {
      this.$store.commit('utm/add', utm)
    })
    this.$store.commit('config/add', { showVAT: false })
  },
  beforeDestroy() {
    this.$root.$off('addToCartAction')
  },
  methods: {
    getUtmTags() {
      const utms = []
      const queryParams = Object.keys(this.$route.query)
      const utmParams = queryParams.filter(key => key.startsWith('utm_'))
      utmParams.forEach(param => {
        utms.push(`${param}:${this.$route.query[param]}`)
      })
      return utms
    },
  },
}
