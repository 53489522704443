export default {
  watch: {
    clubConfig: {
      handler(v) {
        this.setTheme(v)
      },
      deep: true,
    },
  },
  methods: {
    setTheme(clubConfig) {
      let theme = clubConfig?.theme
      if (theme && Object.keys(theme).length) {
        Object.keys(theme.colors).forEach(color => {
          document.querySelector('html').style.setProperty(`--${color}`, theme.colors[color])
        })
      }
    },
  },
  mounted() {
    this.setTheme(this.clubConfig)
  },
}
