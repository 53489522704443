// const qs = require('qs')

export default function load(ctx) {
  if (!ctx.$axios.cancelSourceMap) {
    ctx.$axios.cancelSourceMap = {}
  }
  ctx.$axios.interceptors.request.use(async req => {
    // set X-Country
    const tld = (ctx?.$store || ctx?.store).state?.i18nStore?.tld
    ctx.$axios.setHeader('X-Country', tld?.toLowerCase())

    // set X-Language
    const locale = (ctx?.$store || ctx?.store).state?.i18nStore?.locale
    ctx.$axios.setHeader('X-Language', locale)

    // set Company-Group if isClub
    if (ctx.store.getters['club/isClub']) {
      const companyGroupHeader = ctx.store.getters['club/domain']
      // console.log('set header for club ' + companyGroupHeader)
      ctx.$axios.setHeader('Company-Group', companyGroupHeader)
    }

    // set Mkp-web
    ctx.$axios.setHeader('Mkp-web', 'true')

    // set cancelToken for axios
    // const ignoreRequests = ['/categories', '/sign_in', '/sign_out', '/me']
    // if (req.url && !!ignoreRequests.find(x => !req.url.includes(x))) {
    //   const requestId = `${req.method}:${req.url}:${qs.stringify(req.params, { encode: false, allowDots: true })}`
    //   const source = ctx.$axios.CancelToken.source()
    //   req.cancelToken = source.token
    //   ctx.$axios.cancelSourceMap[requestId] = source
    //   ctx.store.commit('request/addRequestId', { requestId, slug: ctx.route.path })
    // }

    return req
  })

  ctx.$axios.interceptors.response.use(
    response => response,
    error => {
      if (
        error?.response?.status === 401 &&
        !!ctx.app?.$auth?.getToken('local') &&
        error?.response?.data?.message === 'bad_parameter'
      ) {
        ctx.app.$cart.setIsReady(false)
        ctx.app.$auth.logout()
      }
      return Promise.reject(error)
    }
  )
}
