import Stock from './Stock'

export default class CartItem {
  constructor(stock, quantity, country) {
    this.uid = stock.uid
    this.country = country
    this.stock = new Stock(stock, country)
    this.quantity = quantity
  }
}
