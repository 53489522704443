
import DownIcon from '@/assets/icons/ico-down.svg'
import UpIcon from '@/assets/icons/ico-up.svg'

export default {
  name: 'SelectField',
  props: {
    classVariant: { type: null, default: null },
    selectedValue: { type: [String, Number], default: '' },
    placeholderValue: { type: String, default: '' },
    inputId: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: '' },
    icon: { type: String, default: '' },
    iconLeft: { type: String, default: '' },
    required: { type: Boolean, default: false },
    inputFormatter: {
      type: Function,
      default(input) {
        return input
      },
    },
  },
  data() {
    return {
      iconSelect: {
        down: DownIcon,
        up: UpIcon,
      },
      inputValue: this.value,
      activeInput: false,
      fieldFocus: false,
      selectedInputType: this.inputType,
    }
  },
  computed: {
    hasError() {
      return this.error.length !== 0
    },
  },
  watch: {
    value() {
      this.updateValue(this.value)
    },
    inputValue() {
      this.updateValue(this.inputValue)
    },
  },
  mounted() {
    this.manageBlur()
  },
  methods: {
    toggle() {
      this.fieldFocus = !this.fieldFocus

      this.$emit(this.fieldFocus ? 'focus' : 'blur')
    },

    toggleFocus() {
      this.fieldFocus = !this.fieldFocus
    },

    manageBlur() {
      if (this.value !== null && this.value !== undefined) {
        this.fieldFocus = this.value.length > 0 || this.value instanceof Date
      } else {
        this.fieldFocus = false
      }
    },
    updateValue(newValue) {
      this.inputValue = this.inputFormatter(newValue)
      this.activeInput = (this.inputValue && this.inputValue.length > 0) || this.inputValue instanceof Date
      this.$emit('onValue', this.inputFormatter(this.inputValue))
      this.$emit('input', this.inputFormatter(this.inputValue))
    },
    toggleShowPassword() {
      if (this.selectedInputType === 'password') {
        this.selectedInputType = 'text'
      } else {
        this.selectedInputType = 'password'
      }
    },
    clear() {
      this.inputValue = ''
      this.$emit('clear')
    },
  },
}
