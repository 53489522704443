
import i18nMx from '@/mixins/i18n-mixin'
import Mobile from '@/mixins/mobile-mixin'

import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import SelectField from '@/components/SelectField/SelectField'

export default {
  name: 'LocationSelectionModal',
  components: {
    StandardModal,
    MainButton,
    SelectField,
  },
  mixins: [i18nMx, Mobile],
  data() {
    return {
      showLocationSelectionModal: false,
      userLocation: null,
      selectedHost: null,
      showMenu: false,
    }
  },
  computed: {
    countriesList() {
      // determines buttons list
      if (this.locationInScopeCountries && this.userLocation) {
        return [
          this.countries.find(country => country.code.toUpperCase() === this.userLocation),
          this.countries.find(country => country.code.toUpperCase() === this.tld),
        ]
      }
      return this.countries
    },
    locationInScopeCountries() {
      if (this.userLocation) {
        return this.countries.map(x => x.code).includes(this.userLocation?.toLowerCase())
      }
      return true
    },
    userLocationCountryName() {
      if (this.userLocation) {
        return this.isoNameCountries[this.userLocation]
      }
      return this.isoNameCountries[this.$tld]
    },
    navigatorLocale() {
      let locale = null
      if (process.client) {
        if (navigator?.language?.split('-')?.length) {
          locale = navigator?.language?.split('-')?.shift()?.toUpperCase() || this.$locale
        } else {
          locale = navigator?.language?.toUpperCase() || this.$locale
        }
      }
      return this.$i18n.localeCodes.includes(locale.toLowerCase()) ? locale : 'EN'
    },
    navigatorLocation() {
      if (process.client) {
        if (navigator?.language?.split('-')?.length) {
          return navigator?.language?.split('-')?.pop()?.toUpperCase() || this.tld || 'FR'
        } else {
          return navigator?.language?.toUpperCase() || this.tld || 'FR'
        }
      }
      // default return TLD
      return this.$tld
    },
  },
  mounted() {
    this.initGeoIp()
    this.selectedHost = this.$country
  },
  methods: {
    initGeoIp() {
      // avoid multiple calls for same user by using cookies
      if (!this.$cookies.get('x-country-ip') && !this.$cookies.get('x-location_modal_disabled')) {
        // Init maxmind script
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.id = 'maxmind-geoip'
        script.onload = setTimeout(() => this.getGeoIp(), 500)
        script.src = 'https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js'
        const first = document.getElementsByTagName('script')[0]
        first.parentNode.insertBefore(script, first)
        // To avoid CORS issue in local env, use extension like https://chromewebstore.google.com/detail/moesif-origincors-changer/digfbfaphojjndkpccljibejjbppifbc?hl=fr
      }
    },
    async getGeoIp() {
      let countryCookie = null
      if (window.geoip2) {
        try {
          await window.geoip2.country(
            data => {
              // console.log('data', data)
              if (data?.country) {
                countryCookie = data.country.iso_code
                this.$cookies.set('x-country-ip', data.country.iso_code, {
                  maxAge: 60 * 60 * 24 * 365,
                  domain: `.${this.country?.host}`,
                })
                this.openModal(countryCookie)
              }
            },
            error => {
              console.log(error)
              throw error
            }
          )
        } catch (error) {
          console.log(error)
          this.$sentry.captureException(error)
        }
      } else {
        this.openModal(countryCookie)
      }
    },
    openModal(countryCookie) {
      this.$nextTick(() => {
        let locationInRightHost =
          (this.$cookies.get('x-country-ip') || countryCookie) === this.$tld ||
          (!(this.$cookies.get('x-country-ip') || countryCookie) && this.navigatorLocation === this.$tld)
        this.userLocation = this.$cookies.get('x-country-ip') || countryCookie || this.navigatorLocation
        if (
          !locationInRightHost &&
          !this.$cookies.get('x-selectedHost') &&
          !this.$cookies.get('x-location_modal_disabled')
        ) {
          this.showLocationSelectionModal = true
          this.$nextTick(() => {
            this.$root.$emit('modalOpen_locationSelectionModal', 'locationSelectionModal')
          })
        }
      })
    },
    afterSwitchLocale() {
      return this.close()
    },
    selectHost(country, url) {
      this.$cookies.set('x-selectedHost', country.host, { maxAge: 60 * 60 * 24 * 365, domain: `.${country.host}` })
      if (url && country?.code?.toUpperCase() !== this.tld) {
        url += `?source_host=${this.tld}`
        this.openLink(url, false, ``)
      }
      this.close()
    },
    hostUrl(host) {
      return `${window.location.protocol}//www.${host}`
    },
    close() {
      // if close modal, set selectedHost to current host for 2 week
      this.$cookies.set('x-selectedHost', this.$country.host, {
        maxAge: 60 * 60 * 24 * 14,
        domain: `.${this.$country.host}`,
      })
      this.$root.$emit('modalClose_locationSelectionModal', 'locationSelectionModal')
    },
    openLink(url, blank = true) {
      const link = document.createElement('a')
      link.href = url
      if (blank) {
        link.setAttribute('target', '_blank')
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
