export default class LocalCartProvider {
  constructor(store) {
    this.store = store
  }

  addItem(item) {
    this.store.commit('cart/addItem', item)
  }

  updateItem(item, index) {
    this.store.commit('cart/updateItem', { item, index })
  }

  removeItem(item) {
    this.store.commit('cart/remove', item)
  }

  setDeliveryMethod(delivery) {
    this.store.commit('cart/setDeliveryMethod', delivery)
  }

  setAddress(address) {
    this.store.commit('cart/setAddress', address)
  }

  removeDeliveryMethod(merchantUid) {
    this.store.commit('cart/removeDeliveryMethod', merchantUid)
  }

  addCoupon(coupon) {
    this.store.commit('cart/addCoupon', coupon)
  }

  setIsReady(isReady) {
    this.store.commit('cart/setIsReady', isReady)
  }

  clearCoupons() {
    this.store.commit('cart/clearCoupons')
  }

  clearCart() {
    this.store.commit('cart/clearCart')
  }

  clearRemoteCart() {
    this.store.commit('cart/clearRemoteCart')
  }

  setCartUid(uid) {
    this.store.commit('cart/setCartUid', uid)
  }

  setRemoteCart(cart) {
    this.store.commit('cart/setRemoteCart', cart)
  }

  setVersion(version) {
    this.store.commit('cart/setVersion', version)
  }

  get items() {
    return this.store.getters['cart/items']
  }

  get deliveryMethods() {
    return this.store.getters['cart/deliveryMethods']
  }

  get coupons() {
    return this.store.getters['cart/coupons']
  }

  get cartUid() {
    return this.store.getters['cart/cartUid']
  }

  get remoteCart() {
    return this.store.getters['cart/remoteCart']
  }

  get isReady() {
    return this.store.getters['cart/isReady']
  }

  get version() {
    return this.store.getters['cart/version']
  }
}
