export default {
  computed: {
    isXxs() {
      return this.$store.state.mobile.isXxs
    },
    isXs() {
      return this.$store.state.mobile.isXs
    },
    isSm() {
      return this.$store.state.mobile.isSm
    },
    isMd() {
      return this.$store.state.mobile.isMd
    },
    isLg() {
      return this.$store.state.mobile.isLg
    },
    isXl() {
      return this.$store.state.mobile.isXl
    },
    isMobile() {
      return this.$store.state.mobile.isMobile
    },
    isTablet() {
      return this.$store.state.mobile.isTablet
    },
    isPc() {
      return this.$ua.isFromPc()
    },
    isMobilePhone() {
      return this.$ua.isFromMobilephone()
    },
    isAndroid() {
      return this.$ua.isFromAndroidOs()
    },
    isIos() {
      return this.$ua.isFromIos()
    },
    deviceType() {
      // pc, mobile, tablet...
      return this.$ua.deviceType()
    },
    browserName() {
      return this.$ua.browser()
    },
  },
}
