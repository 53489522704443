export default class MkpAlertMapper {
  static basicMapping = JSON.stringify({
    mapping: {
      mkp_alert: [
        'uid',
        'query',
        'has_delivery',
        'is_archived',
        'kind',
        'locality',
        'max_price',
        'created_at',
        'min_price',
        'company',
        {
          reference: ['name', 'slug', 'ean', 'ref_co', { brand: ['name', 'slug'] }, { category: ['name', 'slug'] }],
        },
        'brands',
        {
          categories: ['name', 'uid', 'children'],
        },
      ],
    },
  })
}
