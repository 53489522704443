import Repository from './repository'

export default class ProspectRepository extends Repository {
  async getOrigins() {
    const url = `${this.backendUrl}/user_origins`

    return this.axios.get(url).then(response => {
      return response.data.user_origins
    })
  }

  async subscribeNewsletter(email) {
    const url = `${this.backendUrl}prospects/newsletter`
    const config = {
      prospect: {
        tags: 'Newsletter',
        is_person: true,
        email,
      },
    }
    return this.axios.post(url, config).then(response => response.data.success)
  }

  async subscribeProspect(prospect, config = {}) {
    const url = `${this.backendUrl}prospects`

    return this.axios.post(url, prospect, config).then(response => response.data.success)
  }

  async boostMySalesProspect(prospect, biCampaign, emailToken, config = {}) {
    const url = `${this.backendUrl}prospects/boost-my-sales`

    return this.axios
      .post(url, { prospect: prospect, bi_campaign: biCampaign, email_token: emailToken }, config)
      .then(response => response.data.success)
  }
}
