export const state = () => ({
  config: {
    showVAT: true,
    pageData: null,
  },
})

export const getters = {
  config: state => state.config,
  getKey: state => key => state.config[key],
}

export const mutations = {
  add(state, options) {
    Object.assign(state.config, options)
  },
  remove(state, keys) {
    keys.forEach(key => {
      delete state.config[key]
    })
  },
}
