export default class ReferenceMapperHelper {
  static referenceMapping = JSON.stringify({
    mapping: {
      reference: [
        'uid',
        'name',
        'names',
        'slug',
        'recommended_price',
        'recommended_price_vat',
        'best_stock',
        'best_stock_by_country',
        'description',
        'public_stocks_count',
        'ref_co',
        'ean',
        'manual_link',
        'weight',
        'unit_weight',
        'weight_unit',
        'has_delivery',
        'public_stocks_quantities',
        'public_stocks_quantities_by_country',
        'accreditation',
        'eco_tax',
        {
          related_references: [
            'uid',
            'name',
            'slug',
            'recommended_price',
            'recommended_price_vat',
            { best_stock_by_country: ['price', 'price_vat', 'uid', 'quantity', 'quantity_type', 'selling_quantity'] },
            { best_stock: ['price', 'price_vat', 'uid', 'quantity', 'quantity_type', 'selling_quantity'] },
            'public_stocks_count',
            'public_stocks_quantities',
            {
              images: ['url', 'url_thumbnail', 'url_medium', 'url_large'],
            },
            {
              brand: ['uid', 'name', 'logo_url'],
            },
          ],
        },
        {
          public_stocks: [
            'uid',
            'quantity',
            'quantity_type',
            'price',
            'price_vat',
            'selling_quantity',
            'expiry_date',
            'expiry_date_status',
            'has_delivery',
            'location',
            'vipros_cashback',
            'vipros_points',
            'tags',
            {
              storage: [
                'uid',
                'name',
                'gps',
                'zip_code',
                'city',
                {
                  parent: ['uid', 'name', 'zip_code', 'city'],
                },
              ],
            },
            {
              company: [
                'uid',
                'slug',
                'name',
                'label',
                'has_delivery',
                'has_pick_up',
                'delivery_methods',
                'delivery_fee',
                'delivery_free_of_charge',
                'delivery_free_of_charge_vat',
                'delivery_delay',
              ],
            },
          ],
        },
        {
          images: ['url', 'url_thumbnail', 'url_medium', 'url_large'],
        },
        {
          category: ['uid', 'name', 'slug', { parent: ['uid', 'name', { parent: ['uid', 'name'] }] }],
        },
        {
          brand: ['name', 'slug', 'logo_url'],
        },
      ],
    },
  })

  static searchMapping = JSON.stringify({
    mapping: {
      reference: [
        'uid',
        'name',
        'slug',
        'recommended_price',
        'recommended_price_vat',
        {
          best_stock_by_country: [
            'price',
            'price_vat',
            'uid',
            'quantity',
            'quantity_type',
            'selling_quantity',
            'batch_size',
          ],
        },
        { best_stock: ['price', 'price_vat', 'uid', 'quantity', 'quantity_type', 'selling_quantity', 'batch_size'] },
        'best_stock_by_country',
        'public_stocks_count',
        'public_stocks_quantities',
        'public_stocks_quantities_by_country',
        {
          images: ['url', 'url_thumbnail', 'url_medium', 'url_large'],
        },
        {
          category: ['uid', 'name', 'slug', { parent: ['uid', 'name', { parent: ['uid', 'name'] }] }],
        },
        {
          brand: ['uid', 'name', 'logo_url'],
        },
      ],
    },
  })

  static webstockSearchMapping = JSON.stringify({
    mapping: {
      reference: [
        'uid',
        'name',
        'slug',
        'recommended_price',
        'recommended_price_vat',
        'description',
        'ref_co',
        'ean',
        'weight',
        { best_stock: ['price', 'price_vat', 'uid', 'quantity', 'quantity_type', 'selling_quantity'] },
        'best_stock_by_country',
        'public_stocks_count',
        'public_stocks_quantities',
        {
          images: ['url', 'url_thumbnail', 'url_medium', 'url_large'],
        },
        {
          category: ['uid', 'name', 'slug', { parent: ['uid', 'name', { parent: ['uid', 'name'] }] }],
        },
        {
          brand: ['uid', 'name', 'logo_url'],
        },
      ],
    },
  })

  static slugMapping = JSON.stringify({
    mapping: {
      reference: ['name', 'slug', 'best_stocks_by_merchants'],
    },
  })
}
