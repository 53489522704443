
import { mapGetters } from 'vuex'
import Cookie from 'js-cookie'
import { debounce } from '@/utils'
import AxeptioEvent from '@/services/analytics/axeptio'

import defaultHead from '@/utils/defaultHead'

import Theme from '@/mixins/theme-mixin'
import Mobile from '@/mixins/mobile-mixin'
import i18nMx from '@/mixins/i18n-mixin'
import addToCart from '@/mixins/add-to-cart-mixin'
import HeadMx from '@/mixins/head-mixin'

import Snackbar from '@/components/Snackbar/Snackbar'
import Footer from '@/components/Footer/Footer.vue'
import Header from '@/components/Header/Header'
import CartConfirmationModal from '@/components/Modals/CartConfirmationModal/CartConfirmationModal'
import NavbarLeft from '@/components/Navbar/NavbarLeft'
import DevBanner from '@/components/Banners/DevBanner/DevBanner'
import LocationSelectionModal from '@/components/I18n/LocationSelectionModal'
import ClubFooter from '@/components/Footer/ClubFooter'
import ConfirmationModal from '@/components/Modals/ConfirmationModal/ConfirmationModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'

import colors from '@/assets/scss/globals/colors.scss'

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    CartConfirmationModal,
    Footer,
    DevBanner,
    ClubFooter,
    NavbarLeft,
    Snackbar,
    LocationSelectionModal,
    ConfirmationModal,
    MainButton,
    ZendeskButton,
  },
  mixins: [Theme, Mobile, i18nMx, addToCart, HeadMx],
  data() {
    return {
      modals: 0,
    }
  },
  head() {
    return {
      ...defaultHead(this.$locale, this.$currentUser?.isAuthenticated, this.country),
      ...this.defaultMeta,
      link: [...(this.hreflang() || []), this.canonical()],
    }
  },
  computed: {
    ...mapGetters('club', ['isClub']),
    currentLang() {
      return this.$store.state?.i18nStore?.locale
    },
    onStaging() {
      return process.env.ENV_NAME === 'STAGING'
    },
    onQA() {
      return process.env.ENV_NAME === 'QA'
    },
  },
  watch: {
    '$currentUser.isAuthenticated': {
      handler(isAuthenticated) {
        if (isAuthenticated) {
          let customStyleTag = document.createElement('style')
          customStyleTag.setAttribute('data-hid', 'custom-styles')
          customStyleTag.innerHTML = '#PopupSignupForm_0 {display: none;}'
          document.body.appendChild(customStyleTag)
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.$analytics.sendEvent(this.$events.LOGIN_STATUS, this.$currentUser)
    const country = this.countries.find(x => x.iso.toUpperCase() === this.$store.state?.i18nStore?.tld?.toUpperCase())
    if (this.$route.query?.source_host) {
      this.$cookies.set('x-selectedHost', country.host, {
        maxAge: 60 * 60 * 24 * 365,
        ...(country ? { domain: `.${country.host}` } : {}),
      })
    }
    this.$root.$on('addToCartAction', data => {
      this.stock = data.stock
      this.quantity = data.quantity
      this.fastAddToCart = data.fastAddToCart
      this.addToCart(data.accepted, this.stock, this.quantity, this.fastAddToCart)
    })
    this.handleResize()
    window.addEventListener('resize', debounce(this.handleResize, 300), { passive: true })
  },
  beforeDestroy() {
    this.$root.$off('addToCartAction')
    window.removeEventListener('resize', this.handleResize)
  },
  async mounted() {
    // add small timeout to re-call SB content after lang switch if necessary
    await setTimeout(async () => {
      let country = this.country
      let locale = this.locale
      if (this.$route?.query?.lang && this.$route?.query?.lang !== this.$cookies.get('lang')) {
        await this.switchLocale(
          Array.isArray(this.$route.query.lang) ? this.$route.query.lang[0] : this.$route.query.lang
        )
      } else if (country?.availableLanguages?.includes(locale)) {
        await this.switchLocale(locale)
      } else if (country?.availableLanguages?.length) {
        await this.switchLocale(country.availableLanguages[0])
      }
      if (this.$route.query?.awc) {
        AxeptioEvent(() => {
          Cookie.set('awc', this.$route.query.awc, {
            expires: 30,
            secure: true,
          })
        })
      }
    }, 50)
    if (document.querySelector('html').style.getPropertyValue('--primary') !== colors.green) {
      this.setTheme({ theme: { colors }, favicon: '/favicon.ico' })
    }
    const utms = this.getUtmTags()
    utms.forEach(utm => {
      this.$store.commit('utm/add', utm)
    })
  },
  methods: {
    handleResize() {
      this.$store.dispatch('mobile/handleResize')
    },
    getUtmTags() {
      const utms = []
      const queryParams = Object.keys(this.$route.query)
      const utmParams = queryParams.filter(key => key.startsWith('utm_'))
      utmParams.forEach(param => {
        utms.push(`${param}:${this.$route.query[param]}`)
      })
      return utms
    },
  },
}
