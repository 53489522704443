module.exports = [
  {
    name: 'Nederlands',
    code: 'nl',
    iso: 'nl-NL',
    file: 'nl-NL.json',
    langSelection: 'Selecteer uw voorkeurstaal om door te gaan',
  },
  // default locale must be the last
  {
    name: 'Français',
    code: 'fr',
    iso: 'fr-FR',
    file: 'fr-FR.json',
    langSelection: 'Sélectionnez votre langue préférée pour continuer',
    isCatchallLocale: true,
  },
]
