import { routeOption, getMatchedComponents, normalizePath } from '@/utils'

/* 
!!!!!!!!!!!!! WARNING !!!!!!!!!!!!!
This code is strongly inspired and tied with nuxtjs/auth plugin
If this lib is updated or changed, you MUST update this auth middleware
!!!!!!!!!!!!! WARNING !!!!!!!!!!!!!
*/
export default async function load(ctx) {
  // Disable middleware if options: { authClub: false } is set on the route and being in a club
  if (ctx.store.getters['club/isClub'] && routeOption(ctx.route, 'authClub', false)) {
    return
    // ctx.$auth.redirect('login')
  }

  // Disable middleware if options: { auth: false } is set on the route and not in a club
  if (!ctx.store.getters['club/isClub'] && routeOption(ctx.route, 'auth', false)) {
    return
  }

  // Disable middleware if no route was matched to allow 404/error page
  const matches = []
  const Components = getMatchedComponents(ctx.route, matches)
  if (!Components.length) {
    return
  }

  const { login, callback } = ctx.$auth.options.redirect
  const pageIsInGuestMode = routeOption(ctx.route, 'auth', 'guest')
  const insidePage = page => normalizePath(ctx.route.path) === normalizePath(page)

  // add for manage cookie in multiple domains
  // ctx.app.$auth.onRedirect(() => {
  //   // ctx.app.$auth.reset()
  //   if (ctx?.app?.$auth?.state?.user && ctx?.$auth?.$state?.loggedIn) {
  //     ctx.app.$auth.setUser(ctx.app.$auth?.state?.user)
  //   }
  // })
  if (ctx.route.path !== '/login') {
    const redirectUrl = '/login?redirect=' + ctx.route.path
    if (!ctx.$auth.loggedIn) {
      ctx.redirect(redirectUrl)
    }
  }
  if (ctx.$auth.$state.loggedIn) {
    // -- Authorized --
    if (!login || insidePage(login) || pageIsInGuestMode) {
      ctx.$auth.redirect('index')
    }
  } else {
    // -- Guest --
    // (Those passing `callback` at runtime need to mark their callback component
    // with `auth: false` to avoid an unnecessary redirect from callback to login)
    if (!pageIsInGuestMode && (!callback || !insidePage(callback))) {
      ctx.$auth.redirect('login')
    }
  }
}
/* 
!!!!!!!!!!!!! WARNING !!!!!!!!!!!!!
This code is strongly inspired and tied with nuxtjs/auth plugin
If this lib is updated or changed, you MUST update this auth middleware
!!!!!!!!!!!!! WARNING !!!!!!!!!!!!!
*/
