export default class Category {
  constructor(
    {
      name,
      uid,
      image_on,
      image_off,
      illustration,
      children,
      parent,
      parent_uid,
      slug,
      mkp_products_count,
      count,
      stock_available,
    },
    country
  ) {
    this.name = name
    this.slug = slug
    this.uid = uid
    this.imageOn = image_on
    this.imageOff = image_off
    this.illustration = illustration
    this.children = children
    this.parent = parent
    this.parent_uid = parent_uid
    if (children !== undefined && children !== null) {
      this.children = children?.map(child => new Category(child, country))
    } else {
      this.children = []
    }
    this.productsCount = mkp_products_count !== undefined ? mkp_products_count : count
    this.stockAvailable = stock_available
    this.isActive = false
    this.country = country
  }

  get hasChildren() {
    return this.children !== null && this.children.length > 0
  }

  get path() {
    return `${this.uid}-${encodeURI(this.key)}`
  }

  get breadcrumbPath() {
    const categories = []
    let currentCategory = this

    categories.push(currentCategory)
    currentCategory = currentCategory.parent

    while (currentCategory !== null && currentCategory !== undefined && Object.keys(currentCategory).length !== 0) {
      categories.push(currentCategory)
      currentCategory = currentCategory.parent
    }
    return categories.reverse()
  }

  get firstLevelParentUid() {
    let currentCategory = this
    while (currentCategory?.parent && currentCategory?.parent !== null) {
      currentCategory = currentCategory.parent
    }
    return currentCategory?.uid
  }

  get relatedUids() {
    const uids = []

    if (this.hasChildren) {
      this.recursiveUids(this.children, uids)
    } else {
      uids.push(this.uid)
    }

    return uids
  }

  get thumbnail() {
    if (this.imageOn) {
      return this.imageOn.replace('upload/', 'upload/c_fill,w_60,h_60,c_thumb,f_auto/')
    }
    return null
  }

  get stockAvailableByCountry() {
    return this.stockAvailable?.[this.country?.code]
  }

  recursiveUids(subcategories, uids) {
    subcategories.forEach(category => {
      if (category.hasChildren) {
        this.recursiveUids(category.children, uids)
      } else {
        uids.push(category.uid)
      }
    })
  }
}
