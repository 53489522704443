
import MainButton from '@/components/Buttons/MainButton/MainButton'
import CartStockPreview from '@/components/Cart/CartStockPreview/CartStockPreview'

export default {
  name: 'CartConfirmationModal',
  components: {
    MainButton,
    CartStockPreview,
  },
  data() {
    return {
      item: null,
      timeout: null,
    }
  },
  mounted() {
    this.$root.$on('confirmCartAdded', cartItem => {
      // Cancel previous hide timeout
      if (this.timeout !== null) {
        clearTimeout(this.timeout)
        this.close()
      }
      this.item = cartItem
      this.$emit('modalShow')
      this.timeout = setTimeout(this.close, 5000)
    })
  },
  beforeDestroy() {
    this.$root.$off('confirmCartAdded')
    if (this.timeout !== null) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    close() {
      if (this.timeout !== null) {
        clearTimeout(this.timeout)
      }
      this.item = null
      this.timeout = null
      this.$emit('modalHide')
    },
  },
}
