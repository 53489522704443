export const state = () => ({
  editStorage: null,
  companyStorages: [],
  newStorage: null,
})

export const getters = {
  companyStorages: state => {
    return state.companyStorages
  },
  editStorage: state => {
    return state.editStorage
  },
  storageBySlug: state => slug => {
    const findNestedStorage = (arr, parent, slug, nestingKey) => {
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]
        if (item.slug === slug) return item
        if (item[nestingKey]) {
          const result = findNestedStorage(item[nestingKey], item, slug, nestingKey)
          if (result) return result
        }
      }
      return null
    }
    return findNestedStorage(state.companyStorages, null, slug, 'children')
  },
  storageByUid: state => uid => {
    const findNestedStorage = (arr, uid, nestingKey) => {
      for (const item of arr) {
        if (item.uid === uid) {
          return item
        }
        if (item[nestingKey]) {
          const nestedResult = findNestedStorage(item[nestingKey], uid, nestingKey)
          if (nestedResult) {
            return nestedResult
          }
        }
      }
      return null
    }
    return findNestedStorage(state.companyStorages, uid, 'children')
  },
  newStorage: state => state.newStorage,
}

export const actions = {
  async getCompanyStorages({ commit }) {
    try {
      await this.$api.storage.getCompanyStorages(this.$currentUser.companyUid).then(rawStorages => {
        commit('setCompanyStorages', rawStorages)
      })
    } catch (e) {
      console.log(e)
      return this.localePath({ statusCode: 404, message: e.message })
    }
  },
  setEditStorage({ commit }, storage) {
    commit('setEditStorage', storage)
  },
}

export const mutations = {
  setCompanyStorages(state, storages) {
    state.companyStorages = storages
  },
  setEditStorage(state, storage) {
    state.editStorage = storage
  },
  addStorages(state, newStorages) {
    let storages = state.companyStorages.concat(newStorages)
    storages = storages.filter((b, index, self) => index === self.findIndex(storage => b.uid === storage.uid))
    state.companyStorages = storages
  },
  addSubStorages(state, newStorages) {
    let storages = JSON.parse(JSON.stringify(state.companyStorages))
    newStorages.forEach(newSubstorage => {
      let idx = storages.findIndex(s => s.uid == newSubstorage.parent.uid)
      if (idx > -1) {
        storages[idx].children.push(newSubstorage)
      }
    })
    state.companyStorages = storages
  },

  setNewStorage(state, storage) {
    state.newStorage = storage
  },
}
