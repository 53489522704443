// State ------------------------------------------------------------------------------------------

const state = () => ({
  show: false,
  color: '',
  message: '',
  timeout: 2000,
  canBeClosed: true,
  customTop: null,
})

// Getters ----------------------------------------------------------------------------------------

const getters = {
  show: state => state.show,
  color: state => state.color,
  message: state => state.message,
  timeout: state => state.timeout,
  canBeClosed: state => state.canBeClosed,
  customTop: state => state.customTop,
}

// Actions ----------------------------------------------------------------------------------------

const actions = {
  hide({ commit }) {
    commit('hide')
  },

  show({ commit }, param) {
    commit('show', param)
  },
}

// Mutations --------------------------------------------------------------------------------------

const mutations = {
  hide(state) {
    state.show = false
  },

  show(state, param) {
    state.color = param.color
    state.message = param.message
    state.timeout = param.timeout ? param.timeout : 2000
    state.show = true
    state.canBeClosed = param.canBeClosed
    state.customTop = param.customTop
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
