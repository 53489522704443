
import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'ConfirmationModal',
  components: { MainButton, StandardModal },
  props: {
    modalName: { type: String, default: 'confirmationModal' },
  },
  methods: {
    onConfirm() {
      this.$emit('onConfirm')
      this.$root.$emit(`modalClose_${this.modalName}`, this.modalName)
    },
    onClose() {
      this.$root.$emit(`modalClose_${this.modalName}`, this.modalName)
    },
  },
}
