
import defaultHead from '@/utils/defaultHead'
import Theme from '@/mixins/theme-mixin'
import Scrollto from '@/mixins/scrollto-mixin'
import i18nMx from '@/mixins/i18n-mixin'
import HeadMx from '@/mixins/head-mixin'

import Footer from '@/components/Footer/Footer'
import ProHeader from '@/components/Header/ProHeader'
import DevBanner from '@/components/Banners/DevBanner/DevBanner'
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'

export default {
  name: 'ProLayout',
  components: {
    ProHeader,
    Footer,
    DevBanner,
    ZendeskButton,
  },
  mixins: [Theme, Scrollto, i18nMx, HeadMx],
  head() {
    return {
      ...defaultHead(this.$locale),
      ...this.defaultMeta,
      meta: this.defaultMeta.meta.map(x => {
        if (x.property === 'og:image') {
          x.content = 'https://d3brsr9pdomwt0.cloudfront.net/social/web_banner_pro.jpg'
        }
        return x
      }),
      link: [...(this.hreflang() || []), this.canonical()].map(x => {
        x.href = x.href.replace('www', 'pro')
        return x
      }),
    }
  },
  computed: {
    onStaging() {
      return process.env.ENV_NAME === 'STAGING'
    },
    onQA() {
      return process.env.ENV_NAME === 'QA'
    },
  },
  beforeMount() {
    this.$analytics.sendEvent(this.$events.LOGIN_STATUS, this.$currentUser)
  },
  mounted() {
    const utms = this.getUtmTags()
    utms.forEach(utm => {
      this.$store.commit('utm/add', utm)
    })
  },
  methods: {
    getUtmTags() {
      const utms = []
      const queryParams = Object.keys(this.$route.query)
      const utmParams = queryParams.filter(key => key.startsWith('utm_'))
      utmParams.forEach(param => {
        utms.push(`${param}:${this.$route.query[param]}`)
      })
      return utms
    },
  },
}
