const stateH = {
  autoUpdate: true,
  results: [],
  filters: [],
  sort: {
    key: null,
    value: 'desc',
  },
  query: null,
  pagination: {
    total: 0,
    totalPages: 1,
    currentPage: 1,
    perPage: 10,
  },
  requestQuery: null,
}

const getters = {
  results: state => state.results,
  query: state => state.query,
  pagination: state => state.pagination,
  currentPage: state => state.pagination.currentPage,
  total: state => state.pagination.total,
  sort: state => state.sort,
  filters: state => state.filters,
  requestQuery: state => state.requestQuery,
  filterForKey: state => filterKey => state.filters.find(f => f.key === filterKey),
  dateRange: (state, getters) => {
    const start = getters.filterForKey('start_date')
    const end = getters.filterForKey('end_date')
    if (end && start) {
      return {
        start: start.value,
        end: end.value,
      }
    }
    return start
  },
}

const actions = {
  updateCurrentPage({ state, commit, dispatch }, page) {
    commit('setCurrentPage', page)
    if (state.autoUpdate) {
      dispatch('updateRequestQuery', false)
    }
  },
  updateQuery({ state, commit, dispatch }, query) {
    commit('setQuery', query)
    if (state.autoUpdate) {
      dispatch('updateRequestQuery')
    }
  },
  toggleFilters({ state, commit, dispatch }, filters) {
    const newFilters = [...state.filters]
    filters.forEach(filter => {
      const idx = newFilters.findIndex(el => el.key === filter.key)
      if (idx > -1) {
        newFilters.splice(idx, 1)
      }
      if (filter.value !== null) {
        newFilters.push(filter)
      }
    })
    commit('setFilters', newFilters)
    if (state.autoUpdate) {
      dispatch('updateRequestQuery')
    }
  },
  updateSort({ state, commit, dispatch }, sort) {
    commit('setSort', sort)
    if (state.autoUpdate) {
      dispatch('updateRequestQuery')
    }
  },
  toggleSort({ state, commit, dispatch }, key) {
    const sort = { ...state.sort }
    if (sort.key === key && sort.value === 'desc') {
      sort.value = 'asc'
    } else {
      sort.key = key
      sort.value = 'desc'
    }
    commit('setSort', sort)
    if (state.autoUpdate) {
      dispatch('updateRequestQuery')
    }
  },
  updateRequestQuery({ state, commit }, resetPage = true) {
    const requestQuery = {}
    if (state.query && state.query !== '') {
      requestQuery.number = state.query
    }
    if (state.sort.key) {
      requestQuery.sort = state.sort.key
      requestQuery.order_by = state.sort.value
    }
    state.filters.forEach(filter => {
      requestQuery[filter.key] = filter.value
    })

    if (state.pagination.currentPage > 1) {
      requestQuery.page = resetPage ? 1 : state.pagination.currentPage
    }
    requestQuery.per_page = state.pagination.perPage
    if (JSON.stringify(state.requestQuery) !== JSON.stringify(requestQuery)) {
      commit('setRequestQuery', requestQuery)
    }
  },
}

const mutations = {
  setAutoUpdate(stateH, autoUpdate) {
    stateH.autoUpdate = autoUpdate
  },
  setResults(stateH, results) {
    stateH.results = results
  },
  setQuery(stateH, query) {
    stateH.query = query
  },
  setFilters(stateH, filters) {
    stateH.filters = filters
  },
  setSort(stateH, sort) {
    stateH.sort = sort
  },
  setPagination(stateH, pagination) {
    stateH.pagination = pagination
  },
  setCurrentPage(stateH, page) {
    stateH.pagination.currentPage = page
  },
  setRequestQuery(stateH, requestQuery) {
    stateH.requestQuery = requestQuery
  },
}

export default {
  stateH,
  getters,
  actions,
  mutations,
}
