export const state = () => ({
  location: {
    lat: null,
    lng: null,
  },
})

export const getters = {
  location: state => state.location,
}

export const mutations = {
  setLocation(state, location) {
    state.location = location
  },
}

export const actions = {
  async getLocation({ commit, state }) {
    if (!state.location.lat || !state.location.lng) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          pos => {
            const crd = pos.coords
            commit('setLocation', { lat: crd.latitude, lng: crd.longitude })
            return resolve(pos.coords)
          },
          err => {
            reject(err)
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        )
      })
    }
  },
}
