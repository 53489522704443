// import DefaultImage from '/static/empty-product-img.png'
import Category from './Category'

export default class Brand {
  constructor(
    {
      name,
      uid,
      slug,
      logo_url,
      description,
      web_url,
      mobile_url,
      categories,
      mkp_products_count,
      count,
      offers_count_by_country,
    },
    country
  ) {
    this.name = name
    this.slug = slug
    this.logo_url = logo_url
    this.description = description
    this.wide_image = web_url
    this.mobile_image = mobile_url
    this.uid = uid
    this.categories = categories
    this.productsCount = mkp_products_count || count
    this.offersCountByCountry = offers_count_by_country
    this.country = country
  }

  get logo() {
    // if (this.logo_url === null || this.logo_url === undefined || this.logo_url === '') {
    //   return DefaultImage
    // }

    return this.logo_url
  }

  get thumbnail() {
    // if (this.logo_url === null || this.logo_url === undefined || this.logo_url === '') {
    //   return DefaultImage
    // }

    return this.logo_url.replace('upload/', 'upload/h_30,c_thumb,f_auto/')
  }

  get hasCategories() {
    return this.categories != null
  }

  get categoriesUid() {
    if (this.hasCategories) return this.computeCategoriesUid()
    return []
  }

  computeCategoriesUid() {
    let categoriesUid = []

    this.categories.forEach(rawCategory => {
      const category = new Category(rawCategory, this.country)
      categoriesUid = categoriesUid.concat(category.activeUids)
    })

    return categoriesUid
  }
}
