export default class Filter {
  constructor({
    label,
    value,
    query,
    type, // 'CATEGORY', 'BRAND', etc
    display = true,
    data,
    silent = false,
    testId,
  }) {
    this.label = label
    this.value = value
    this.query = query
    this.type = type
    this.display = display
    this.data = data
    this.silent = silent // silent is to avoid trigger handlerewriteurl
    this.testId = testId // testId is to add testId for testing
  }

  get isAlertFilter() {
    return this.type !== 'LOCALITY' && this.type !== 'FRESHNESS' && this.type !== 'BESTDEALS'
  }
}
