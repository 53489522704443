export default class CategoryMapperHelper {
  static categoryMapping = JSON.stringify({
    mapping: {
      category: [
        'uid',
        'name',
        'slug',
        'slugs',
        'image_on',
        'image_off',
        'illustration',
        'parent_uid',
        'mkp_products_count',
        'stock_available',
        'seo_priority',
        { parent: ['uid', 'name', 'slug', 'slugs'] },
        {
          children: [
            'uid',
            'name',
            'slug',
            'slugs',
            'image_on',
            'image_off',
            'illustration',
            'parent_uid',
            'mkp_products_count',
            'stock_available',
            'seo_priority',
            { parent: ['uid', 'name', 'slug', 'slugs'] },
            {
              children: [
                'uid',
                'name',
                'slug',
                'slugs',
                'image_on',
                'image_off',
                'illustration',
                'parent_uid',
                'mkp_products_count',
                'stock_available',
                'seo_priority',
                { parent: ['uid', 'name', 'slug', 'slugs'] },
              ],
            },
          ],
        },
      ],
    },
  })

  static slugCategoryMapping = JSON.stringify({
    mapping: {
      category: [
        'uid',
        'name',
        'names',
        'slug',
        'slugs',
        'image_on',
        'image_off',
        'illustration',
        'children',
        'stock_available',
        'mkp_products_count',
        {
          parent: ['uid', 'name', 'slug', 'slugs', 'image_on', 'image_off', 'children', 'parent', 'mkp_products_count'],
        },
      ],
    },
  })
}
