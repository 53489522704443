export const state = () => ({
  domain: null,
  uid: null,
  name: null,
  kind: null,
  key: null,
  logo_url: null,
  description: null,
  company_group_configuration: {},
  current_user_roles: {},
})

export const getters = {
  domain: state => {
    return state.domain
  },
  isClub: state => {
    return state.domain != null && state.domain != undefined && state.domain != ''
  },
  layout: (state, getters) => {
    return getters.isClub ? 'club' : 'default'
  },
  clubEnablePayment: state => {
    // return if payment is processed in club by StockPro (true) or by club owner
    return state.company_group_configuration.payment_policy != 'BOOK'
  },
  clubEnableDelivery: (/* state */) => {
    // return if delivery is processed in club by StockPro (true) or by club owner
    // return state.company_group_configuration.delivery_policy != 'EXTERNAL'
    return false
  },
  clubConfig(state) {
    return {
      ...state.company_group_configuration,
      // Mock data
      favicon: 'https://res.cloudinary.com/hwpsb8mtr/image/upload/company_groups/qa/pam-favicon.png',
    }
  },
}

export const actions = {
  async getClub({ commit }) {
    try {
      await this.$api.club
        .get()
        .then(rawClub => {
          if (!rawClub) throw 'error'
          commit('setClub', rawClub)
        })
        .catch(e => {
          console.log('error get raw club', e)
        })
    } catch (e) {
      // console.log(e)
      return this.localePath({ statusCode: 404, message: e.message })
    }
  },
}

export const mutations = {
  setClubDomain(state, domain) {
    state.domain = domain
  },
  setClub(state, club) {
    // console.log('setClub', club)
    if (club) {
      state.uid = club.uid
      state.name = club.name
      state.kind = club.kind
      state.key = club.key
      state.description = club.description
      state.company_group_configuration = club.company_group_configuration
      state.current_user_roles = club.current_user_roles
      state.logo_url = club.company_group_configuration.logo_url
    }
  },
}
