
export default {
  name: 'LazyImage',
  // by default lazy parameter is true
  // declare provide() { return { lazy: false } } in any parent/upper-parent to force lazy parameter
  // or declare in props lazyLoading
  // in logic the props weight is higher than provide()
  inject: { lazy: { default: true } },
  props: {
    src: { type: String, required: true },
    alt: { type: String, default: '' },
    backgroundImage: { type: Boolean, default: false },
    width: { type: [String, Number], default: null },
    maxWidth: { type: [String, Number], default: null },
    minWidth: { type: [String, Number], default: null },
    height: { type: [String, Number], default: null },
    quality: { type: Number, default: 80 },
    classVariant: { type: null, default: null },
    bgSize: { type: String, default: 'cover' },
    bgMinSize: { type: Boolean, default: false },
    lazyLoading: { type: Boolean, default: null },
  },
  data() {
    return {
      loaded: false,
      intersectionObserver: null,
      imageError: null,
    }
  },
  head() {
    if (!this.isLazy) {
      return {
        link: [{ rel: 'preload', href: this.urlWithProvider, as: 'image' }],
      }
    }
  },
  computed: {
    isLazy() {
      return this.lazyLoading !== null ? this.lazyLoading : this.lazy
    },
    provider() {
      const regex =
        /(cloudfront\.net|s3-eu-west-3\.amazonaws\.com\/stockpro-references|s3-eu-west-3\.amazonaws\.com\/stockpro-marketing|s3-eu-west-3\.amazonaws\.com\/stockpro-webapp)/gi
      if (this.src?.includes('storyblok')) {
        return 'storyblok'
      } else if (this.src?.includes('cloudinary.com/hr363qyfe')) {
        return 'cloudinary'
      } else if (regex.test(this.src)) {
        return 'cloudfront'
      }
      return undefined
    },
    urlWithoutProvider() {
      let url = this.src
      if (this.provider === 'storyblok') {
        url = this.src?.replace('https://a.storyblok.com', '')
      } else if (this.provider === 'cloudinary') {
        url = this.src?.replace('https://res.cloudinary.com/hr363qyfe/image/upload/', '')
      } else if (this.provider === 'cloudfront') {
        url = this.src?.replace('https://d2tbovyd0ghy3r.cloudfront.net', '')
      }
      return url
    },
    urlWithProvider() {
      if (this.urlWithoutProvider) {
        return this.$img(
          this.urlWithoutProvider,
          {
            width: this.width,
            height: this.height,
          },
          {
            provider: this.provider,
          }
        )
      }
      return this.src
    },
    // Reduce CLS for img solutions pro in homepage
    preSizeCLS() {
      let url = this.src
      if (this.provider === 'storyblok') {
        const regex = /\/(\d+)x(\d+)\//
        const matches = url.match(regex)
        let dimensions = {
          //width: matches[1],
          height: matches[2],
        }
        return dimensions
      }
      return null
    },
    cls() {
      return this.$parent.cls
    },
  },
  mounted() {
    if (this.isLazy) {
      const options = {
        rootMargin: '0px',
        threshold: 0,
      }
      this.intersectionObserver = new IntersectionObserver(this.onIntersection, options)
      if (this.$refs['image-' + this.width]) {
        this.intersectionObserver.observe(this.$refs['image-' + this.width])
      }
    } else {
      this.loaded = true
      if (this.backgroundImage && this.urlWithoutProvider) {
        this.setBackground()
      }
    }
  },
  beforeDestroy() {
    if (this.isLazy && this.intersectionObserver) {
      this.intersectionObserver.disconnect()
      delete this.intersectionObserver
    } else if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    } else {
      this.intersectionObserver = null
    }
  },
  methods: {
    setBackground() {
      let imgUrl = this.$img(
        this.urlWithoutProvider,
        {
          width: this.width,
          height: this.height,
        },
        {
          provider: this.provider,
        }
      )

      this.$refs['image-' + this.width].setAttribute(
        'style',
        `
                background-image: url(${imgUrl}), url(${this.src});
                ${
                  this.width
                    ? this.bgMinSize
                      ? `width: min(${!/[a-z%]/.test(this.width) ? `${this.width}px` : this.width}, 100%);`
                      : `width: ${!/[a-z%]/.test(this.width) ? `${this.width}px` : this.width};`
                    : ''
                }
                ${
                  this.height
                    ? this.bgMinSize
                      ? `height: min(${!/[a-z%]/.test(this.height) ? `${this.height}px` : this.height}, 100%);`
                      : `height: ${!/[a-z%]/.test(this.height) ? `${this.height}px` : this.height};`
                    : ''
                }
                ${this.bgSize ? `background-size: ${this.bgSize};` : ''}
                ${
                  this.maxWidth
                    ? `max-width: ${!/[a-z%]/.test(this.maxWidth) ? `${this.maxWidth}px` : this.maxWidth};`
                    : ''
                }
                ${
                  this.minWidth
                    ? `min-width: ${!/[a-z%]/.test(this.minWidth) ? `${this.minWidth}px` : this.minWidth};`
                    : ''
                }
              `
      )
    },
    onIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('lazy-load--loaded')
          this.loaded = true
          if (this.backgroundImage && this.urlWithoutProvider) {
            this.setBackground()
          }
          this.intersectionObserver.unobserve(this.$refs['image-' + this.width])
        } else {
          this.loaded = false
        }
      })
    },
    onLoaded() {
      this.imageError = null
      this.$emit('loaded')
    },
    onError() {
      if (!this.imageError) {
        this.imageError = Date.now()
      }
      this.$emit('error')
    },
  },
}
