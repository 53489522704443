export default ({ store }) => {
  const savedStateCart = localStorage.getItem('cart')
  if (savedStateCart) {
    store.commit('cart/setCart', JSON.parse(savedStateCart))
  }

  store.subscribe((mutation, state) => {
    if (mutation.type.startsWith('cart')) {
      localStorage.setItem('cart', JSON.stringify(state.cart))
    }
  })
}
