import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      displayTrustpilot: true,
    }
  },
  mounted() {
    if (this.isClub) {
      this.displayTrustpilot = false
    } else {
      let trustpilotScript = document.getElementById('trustpilot-widget-script')
      if (!trustpilotScript) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.id = 'trustpilot-widget-script'
        script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        script.async = true
        document.head.appendChild(script)
      }
      this.$nextTick(() => this.loadTrustpilot())
    }
  },
  computed: {
    ...mapGetters('club', ['isClub']),
  },
  methods: {
    loadTrustpilot() {
      if (window.Trustpilot) {
        let els = document.querySelectorAll('.trustpilot-widget')
        if (els && els.length) {
          els.forEach(el => {
            this.$nextTick(() => window.Trustpilot.loadFromElement(el, true))
          })
        }
      }
    },
  },
}
