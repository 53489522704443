export const state = () => ({
  all: [],
  withBookedStock: [],
})

export const getters = {
  userByUid: state => uid => state.all.find(c => c.uid === uid),
  userWithBookedStockByUid: state => uid => state.withBookedStock.find(c => c.uid === uid),
}

export const mutations = {
  setAllUsers(state, users) {
    state.all = users
  },
  addUsersWithBookedStock(state, users) {
    let allUsers = [...state.withBookedStock, ...(users.length ? users : [])]
    allUsers = allUsers.filter((b, index, self) => index === self.findIndex(user => b?.uid === user?.uid))
    state.withBookedStock = allUsers
  },
}

export const actions = {
  async getAll({ commit }) {
    try {
      await this.$api.user.getUsers().then(rawUsers => {
        commit('setAllUsers', rawUsers)
      })
    } catch (e) {
      console.log(e)
      return this.localePath({ statusCode: 404, message: e.message })
    }
  },
}
