
import API from '@/mixins/sp-api-mixin'
import Pagination from '@/components/Pagination/Pagination'

export default {
  name: 'CompanySelector',
  components: {
    Pagination,
  },
  mixins: [API],
  props: {
    showCompanyList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      companies: [],
      currentPage: 1,
      perPage: 10,
      maxPage: null,
      totalCompanies: null,
    }
  },
  computed: {
    user() {
      return this.$currentUser
    },
    // Commented : we use direct api call now, but can be useful.
    // filteredUserCompanies() {
    //   if (this.search && this.search.length) {
    //     const regExp = new RegExp(this.search.normalize('NFD').replace(/[\u0300-\u036f]/gi, ''), 'gi')
    //     return this.companies.filter(x => regExp.test(x.name.normalize('NFD').replace(/[\u0300-\u036f]/gi, '')))
    //   }
    //   return this.companies
    // },
    companiesLength() {
      return this.companies.length
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.getCompanies()
      },
    },
    search: {
      handler() {
        //delay for typing in input
        setTimeout(() => {
          this.getCompanies()
        }, 1000)
      },
    },
  },
  mounted() {
    this.getCompanies(true)
  },
  methods: {
    close() {
      this.$emit('close')
    },

    isCurrentCompany(companyUid) {
      if (!this.$currentUser || !this.$currentUser.companyUid) return false
      return this.$currentUser.companyUid === companyUid
    },
    // init params to have totalcompanies available for this user and not updating during search
    getCompanies(init = false) {
      const filter = {
        kind: 'PROFESSIONAL',
        page: this.currentPage,
        items_per_page: this.perPage,
        search: this.search,
      }
      this.spRequest({
        req: this.$api.company.getCompanies(filter),
      }).then(data => {
        this.companies = data?.companies
        this.maxPage = data?.page_count
        this.currentPage = data?.page
        if (init) {
          this.totalCompanies = data?.total
        }
      })
    },
    async setCurrentCompany(companyUid) {
      const user = {
        current_company_uid: companyUid,
      }
      this.spRequest({
        req: this.$api.user.update(this.$currentUser.uid, this.$currentUser.companyUid, user),
      })
        .then(async updatedUser => {
          if (this.$route.path !== this.localePath('index')) {
            await this.$router.push(this.localePath('index'))
          }
          this.$auth.setUser(updatedUser)
        })
        .finally(() => {
          this.close()
        })
    },
    updatePage(newPage) {
      this.currentPage = newPage
    },
  },
}
