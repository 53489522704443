import countries from '@/lang/countries'
import isoNameCountries from '@/lang/isoNameCountries'
import locales from '@/lang/locales'
import { defaultHeadMeta } from '@/utils/defaultHead'
import Cookie from 'js-cookie'

export default {
  data() {
    return {
      countries,
      locales,
      isoNameCountries,
    }
  },
  computed: {
    tld() {
      return this.$store.state.i18nStore.tld
    },
    locale() {
      return this.$store.state.i18nStore.locale
    },
    country() {
      return this.$store.state.i18nStore.country
    },
    langCode() {
      // return fr-FR // useful for ex. for trustpilot
      return this.locale + '-' + this.tld
    },
    hostCountryName() {
      // return France, Belgique
      return isoNameCountries[this.tld]
    },
    availableLanguages() {
      // return array of available languages like ['fr', 'be']
      return this.country?.availableLanguages?.map(lang => {
        return locales.find(locale => locale?.code?.toLowerCase() === lang?.toLowerCase())
      })
    },
    localCompanyCode() {
      // return SIREN, KVK or BCE
      return this.country?.localCompanyCode
    },
    defaultMeta() {
      return {
        title: this.country?.metaTitle?.[this.locale],
        meta: [
          ...defaultHeadMeta.map(meta => {
            if (meta.name === 'title') {
              meta.content = this.country?.metaTitle?.[this.locale]
            }
            if (meta.name === 'og:title') {
              meta.content = this.country?.metaTitle?.[this.locale]
            }
            if (meta.name === 'description') {
              meta.content = this.country?.metaDescription?.[this.locale]
            }
            if (meta.name === 'og:description') {
              meta.content = this.country?.metaDescription?.[this.locale]
            }
            return meta
          }),
        ],
      }
    },
    countryPhoneNumber() {
      return this.country?.phoneNumber
    },
    contactFormLink() {
      return this.country?.contactFormLink[this.locale]
    },
  },
  mounted() {
    if (this.$route.query?.location_modal_disabled === '1') {
      Cookie.set('x-location_modal_disabled', '1')
    }
  },
  methods: {
    async switchLocale(lang, fromUserAction = false) {
      this.$i18n.setLocale(lang)
      this.$cookies.set('lang', lang, { maxAge: 60 * 60 * 24 * 365, domain: `.${this.country?.host}` })
      this.afterSwitchLocale()
      this.opened = false
      this.$store.commit('i18nStore/setLocale', lang)
      let langCode = new Set()
      langCode.add(this.tld?.toLowerCase())
      langCode.add(lang?.toLowerCase())
      langCode = Array.from(langCode)
      langCode = langCode?.join('-')
      if (fromUserAction) {
        let alternates = document?.querySelectorAll('link[rel=alternate]')
        let alternate = null
        alternates?.forEach(link => {
          if (link?.hreflang?.toLowerCase() === langCode) {
            alternate = link
          }
        })
        if (alternate) {
          window.location.replace(alternate.href)
        }
      }
      this.$nuxt.refresh()
      await this.$store.dispatch('categories/getAvailableCategories')
      if (process.client) {
        let html = document.querySelector('html')
        html.lang = langCode
      }
    },
    afterSwitchLocale() {
      // can be define in component after locale switching
      return null
    },
    getCountry(value) {
      // return plain country depending on value
      return this.countries?.find(
        x => x?.name === value || x?.name?.includes(name) || x?.iso === value || x?.code === value
      )
    },
  },
}
