
import PartnerHeader from '@/components/PartnerHeader/PartnerHeader'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'LandingDownload',
  components: {
    PartnerHeader,
    Footer,
  },
}
