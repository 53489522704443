export default class CompanyMapperHelper {
  static companyMapping = JSON.stringify({
    mapping: {
      merchant: ['uid', 'slug', 'name', 'status', 'mkp_products_count', 'tags'],
    },
  })

  static merchantMapping = JSON.stringify({
    mapping: {
      merchant: [
        'uid',
        'slug',
        'name',
        'mkp_products_count',
        'tags',
        'about_us_sections',
        'avatar_url',
        'banners',
        'brand_selection',
        'brand_selection_params',
        'category_selection_params',
        'category_selections',
        'delivery_free_of_charge',
        'delivery_free_of_charge_vat',
        'description',
        'has_delivery',
        'has_pick_up',
        'product_ads',
        'product_selection',
      ],
    },
  })
}
