const middleware = {}

middleware['brands-store-load'] = require('../middleware/brands-store-load.js')
middleware['brands-store-load'] = middleware['brands-store-load'].default || middleware['brands-store-load']

middleware['cancel-requests'] = require('../middleware/cancel-requests.js')
middleware['cancel-requests'] = middleware['cancel-requests'].default || middleware['cancel-requests']

middleware['categories-store-load'] = require('../middleware/categories-store-load.js')
middleware['categories-store-load'] = middleware['categories-store-load'].default || middleware['categories-store-load']

middleware['clear-datalayer'] = require('../middleware/clear-datalayer.js')
middleware['clear-datalayer'] = middleware['clear-datalayer'].default || middleware['clear-datalayer']

middleware['clear-lang-param'] = require('../middleware/clear-lang-param.js')
middleware['clear-lang-param'] = middleware['clear-lang-param'].default || middleware['clear-lang-param']

middleware['company-storages-store-load'] = require('../middleware/company-storages-store-load.js')
middleware['company-storages-store-load'] = middleware['company-storages-store-load'].default || middleware['company-storages-store-load']

middleware['custom-auth'] = require('../middleware/custom-auth.js')
middleware['custom-auth'] = middleware['custom-auth'].default || middleware['custom-auth']

middleware['featured-products-store-load'] = require('../middleware/featured-products-store-load.js')
middleware['featured-products-store-load'] = middleware['featured-products-store-load'].default || middleware['featured-products-store-load']

middleware['pro-area-store-load'] = require('../middleware/pro-area-store-load.js')
middleware['pro-area-store-load'] = middleware['pro-area-store-load'].default || middleware['pro-area-store-load']

export default middleware
