import Repository from './repository'

export default class MerchantRepository extends Repository {
  async getMerchant(slug, mapping = this.mapper.merchantMapping) {
    const url = encodeURI(`${this.backendUrl}merchants/${slug}`)
    const config = {
      params: {},
      headers: {
        Mapping: mapping,
      },
    }
    return this.axios.get(url, config).then(response => response.data.company)
  }

  async getMerchants(filters, mapping = this.mapper.companyMapping) {
    const url = `${this.backendUrl}merchants`
    const config = {
      params: {},
      headers: {
        Mapping: mapping,
      },
    }
    if (filters && filters.length > 0) {
      const brandsFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'BRAND') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (brandsFilter.length > 0) config.params.brand_uid = brandsFilter.join()

      const categoriesFilter = filters.reduce((filtered, filter) => {
        if (filter.type === 'CATEGORY') {
          filtered.push(filter.value)
        }
        return filtered
      }, [])
      if (categoriesFilter.length > 0) {
        config.params.category_uid = categoriesFilter.join()
      }

      const keywordsFilter = filters.find(f => f.type === 'KEYWORDS')
      if (keywordsFilter) config.params.product_query = keywordsFilter.value

      const nameFilter = filters.find(f => f.type === 'NAME')
      if (nameFilter) config.params.name = nameFilter.value

      // const localityFilter = filters.find(f => f.type === 'LOCALITY')
      // let coordinatesFilter = null
      // if (localityFilter) {
      //   coordinatesFilter = localityFilter.value.split('+')[1].split(',')
      //   if (coordinatesFilter) {
      //     config.params.location = {}
      //     // ;[config.params.location['lat'], config.params.location['lon']] = coordinatesFilter
      //   }
      // }

      const deliveryFilter = filters.find(f => f.type === 'DELIVERY')
      if (deliveryFilter) config.params.has_delivery = deliveryFilter.value

      const minPriceFilter = filters.find(f => f.type === 'MIN_PRICE')
      if (minPriceFilter) config.params.min_price = minPriceFilter.value

      const maxPriceFilter = filters.find(f => f.type === 'MAX_PRICE')
      if (maxPriceFilter) config.params.max_price = maxPriceFilter.value

      let freshnessFilter = filters.filter(f => f.type === 'FRESHNESS')
      if (freshnessFilter.length > 0) config.params.mkp_freshness = Math.max(...freshnessFilter.map(f => f.value))

      let underBestDealsFilter = filters.filter(f => f.type === 'BESTDEALS' && f.query === 'under_best_deal')
      if (underBestDealsFilter.length > 0)
        config.params.under_best_deal = Math.min(...underBestDealsFilter.map(f => f.value.split('_')[0]))

      let bestDealsFilter = filters.filter(f => f.type === 'BESTDEALS' && f.query === 'best_deal')
      if (bestDealsFilter.length > 0) {
        config.params.best_deal = Math.min(...bestDealsFilter.map(f => f.value.split('_')[0]))
      }
    }
    return this.axios.get(url, config).then(response => response.data.companies)
  }
}
