
import defaultHead from '@/utils/defaultHead'

import Theme from '@/mixins/theme-mixin'
import Club from '@/mixins/club-mixin'
import i18nMx from '@/mixins/i18n-mixin'
import HeadMx from '@/mixins/head-mixin'
import ZendeskButton from '@/components/Buttons/ZendeskButton/ZendeskButton'

export default {
  name: 'ErrorLayout',
  components: { ZendeskButton },
  mixins: [Theme, Club, i18nMx, HeadMx],
  props: { error: { type: Object, default: () => null } },
  head() {
    return {
      // ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      ...defaultHead(this.$locale),
      ...this.defaultMeta,
      link: [...(this.hreflang() || []), this.canonical()],
    }
  },
}
