import Reference from './Reference'
import Company from './Company'

export default class Stock {
  constructor(stock, country) {
    this.uid = stock.uid
    this.company = new Company(stock.company)
    this.quantity = stock.quantity
    this.batchSize = stock.batch_size || stock.batchSize
    this.previousQuantity = stock.previous_quantity || stock.previousQuantity
    this.quantityType = stock.quantity_type || stock.quantityType
    this.price = Number(stock.price)
    this.priceVAT = Number(stock.price_vat || stock.priceVAT)
    this.fee = Number(stock.fee)
    this.totalOrderPrice = Number(stock.total_price || stock.totalPrice)
    this.totalOrderPriceVAT = Number(stock.total_price_vat || stock.totalOrderPriceVAT || 0) // TODO remove default
    this.totalOrderPriceNoVATNC = Number(stock.total_price_without_fee || stock.totalOrderPriceNoVATNC)
    this.previousTotalOrderPrice = Number(stock.previous_total_price || stock.previousTotalPrice)
    this.previousTotalOrderPriceVAT = Number(stock.previous_total_price_vat || stock.previousTotalPriceVat || 0)
    this.previousTotalOrderPriceNoVATNC = Number(
      stock.previous_total_price_without_fee || stock.previousTotalOrderPriceNoVATNC
    )
    this.previousQuantity = stock.previous_quantity || stock.previousQuantity
    this.priceWithoutCommission = stock.price_without_commission || stock.priceWithoutCommission
    this.sellingQuantity = stock.selling_quantity || stock.sellingQuantity || 'by_unit'
    this.selectedQuantity = stock.selected_quantity || stock.selectedQuantity
    this.reference = stock.reference
    this.storage = stock.storage
    this.cartQuantity = stock.current_cart_quantity || stock.cartQuantity
    this.location = stock.location
    this.distance = this.storage ? this.storage.distance : null
    this.publicAt = typeof stock.is_public === 'undefined' ? new Date(stock.publicAt) : new Date(stock.public_at)
    this.expiryDate = stock.expiry_date || stock.expiryDate
    this.expiryDateStatus = stock.expiry_date_status || stock.expiryDateStatus
    this.hasDelivery = stock.has_delivery || stock.hasDelivery
    this.hasAlerts = stock.has_alerts || stock.hasAlerts
    this.alerts = stock.alerts
    this.isPublic = typeof stock.is_public === 'undefined' ? stock.isPublic : stock.is_public
    this.isArchived = typeof stock.is_archived === 'undefined' ? stock.isPublic : stock.is_archived
    this.user = stock.user
    this.internalName = stock.internal_name
    this.issueDetails = stock.issue_details
    this.issueKind = stock.issue_kind
    this.viprosPoints = stock.vipros_points || stock.viprosPoints
    this.viprosCashback = stock.vipros_cashback || stock.viprosCashback
    this.note = stock.note
    this.mkpReservedQty = stock.mkp_reserved_qty
    this.tags = stock.tags
    this.source_id = stock.source_id
    this.country = country
  }

  get buyQuantity() {
    if (this.isBatch && this.quantity !== null) {
      // When it's a batch, quantity means the batch quantity not the item available quantity
      return 1
    }

    return this.quantity
  }

  get distanceKm() {
    if (this.storage !== null && this.storage !== undefined) {
      if (this.distance !== null && this.distance !== undefined) {
        if (this.distance >= 1000) {
          return `${(this.distance / 1000)?.toFixed(1) || 0}km`
        }
        return `${this.distance}m`
      }
    }
    return null
  }

  get formattedDistance() {
    if (this.storage !== null && this.storage !== undefined) {
      if (this.distanceKm) {
        return this.distanceKm
      }

      if (this.storage.city !== null && this.storage.city !== undefined && this.storage.city !== '') {
        return `${this.storage.zip_code} ${this.storage.city}`
      }
    }

    return null
  }

  get unit() {
    return this.quantityType ? this.quantityType.toUpperCase() : 'UNIT'
  }

  get isBatch() {
    return this.sellingQuantity === 'by_batch'
  }

  get stockUnitForQuantity() {
    return this.stockUnitWithQuantity(this.quantity)
  }

  get packagingLabel() {
    if (this.isBatch) return 'product.byBatch'
    return 'product.theUnit'
  }

  get o_reference() {
    return new Reference(this.reference, this.country)
  }

  get isBooked() {
    return this.user !== null && this.user !== undefined && Object.keys(this.user).length !== 0
  }

  get isEditable() {
    return !this.isBooked && !this.isPublic
  }

  get bookedByUser() {
    if (this.isBooked) {
      return `${this.user.first_name} ${this.user.last_name}`
    }
    return null
  }

  get mainStorage() {
    if (this.subStorage !== null) {
      return this.storage.parent
    }
    return this.storage
  }

  get subStorage() {
    if (this.storage.parent !== null && this.storage.parent !== undefined) {
      return this.storage
    }
    return null
  }

  get alert() {
    if (this.hasAlerts) {
      return this.alerts[0]
    }

    return null
  }

  get f_alertColor() {
    if (this.alert.is_reached) return 'u-c-grapefruit'
    else return 'u-c-primary'
  }

  storePrice(vat = true) {
    return vat ? Number(this.priceVAT) : Number(this.price)
  }

  catalogPriceEquivalent(vat = true) {
    if (this.reference.price <= 0) {
      return 0
    }
    let refPrice = Number(this.reference.price)
    if (vat)
      refPrice =
        Number(this.reference.price_vat || this.reference.recommended_price_vat) ||
        Number(refPrice * (this.country?.VAT || 1))
    return refPrice
  }

  savings(vat = true) {
    const price = this.storePrice(vat)
    const value = this.catalogPriceEquivalent(vat) - price
    return Math.max(0, value)
  }

  percentageOff() {
    const value = -(this.savings(true) / this.catalogPriceEquivalent(true))
    return Math.min(0, value)
  }

  stockUnitWithQuantity(quantity) {
    if (quantity > 1) return `${this.unit}s`
    return this.unit
  }
}
