
import Reference from '@/models/Reference'

import Price from '@/mixins/price-mixin'
import Club from '@/mixins/club-mixin'
import i18nMx from '@/mixins/i18n-mixin'

import ExpiryDateLabel from '@/components/ExpiryDateLabel/ExpiryDateLabel'
import RefcoLabel from '@/components/RefcoLabel/RefcoLabel'

export default {
  name: 'CartStockPreview',
  components: {
    ExpiryDateLabel,
    RefcoLabel,
  },
  mixins: [Price, Club, i18nMx],
  props: {
    stock: { type: Object, default: null },
    cartQuantity: { type: Number, default: 0 },
    showDistance: { type: Boolean, default: true },
    mode: { type: String, default: 'CART' }, // CART/LIGHT/PURCHASE/SALE
  },
  computed: {
    reference() {
      if (this.stock.reference.refCo !== null && this.stock.reference.refCo !== undefined) {
        return this.stock.reference
      }
      return new Reference(this.stock.reference, this.country)
    },
    refCoValue() {
      if (this.reference.refCo) {
        return this.reference.refCo
      }
      return this.reference.refCo
    },
    isAPurchase() {
      return this.mode === 'PURCHASE'
    },
    catalogPriceEquivalent() {
      return this.stock?.catalogPriceEquivalent(this.showVAT, this)
    },
  },
}
