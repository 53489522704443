export default class StockMapperHelper {
  static stockMapping = JSON.stringify({
    mapping: {
      stock: [
        'uid',
        'quantity',
        'quantity_type',
        'price',
        'price_vat',
        'total_price',
        'total_price_vat',
        'total_price_without_fee',
        'selling_quantity',
        'expiry_date',
        'expiry_date_status',
        'alerts',
        'has_alerts',
        'is_public',
        'is_archived',
        'country_keys',
        'note',
        {
          storage: ['uid', 'name', 'parent'],
        },
        {
          user: ['uid', 'first_name', 'last_name'],
        },
        {
          reference: [
            'uid',
            'name',
            'slug',
            'recommended_price',
            'ref_co',
            'ean',
            'accreditation',
            'description',
            'created_at',
            'company_id',
            'weight',
            'unit_weight',
            'weight_unit',
            {
              images: ['uid', 'url', 'url_thumbnail', 'url_medium', 'url_large'],
            },
            {
              category: ['uid', 'name', 'slug'],
            },
            {
              brand: ['name', 'slug'],
            },
          ],
        },
      ],
    },
  })

  static searchMapping = JSON.stringify({
    mapping: {
      stock: [
        'uid',
        'quantity',
        'quantity_type',
        'price',
        'price_vat',
        'selling_quantity',
        'public_at',
        'expiry_date',
        'expiry_date_status',
        'alerts',
        'has_alerts',
        'is_public',
        'country_keys',
        'note',
        {
          storage: ['uid', 'name', 'parent'],
        },
        {
          user: ['uid', 'first_name', 'last_name'],
        },
        {
          reference: [
            'uid',
            'name',
            'slug',
            'recommended_price',
            'ref_co',
            'ean',
            'accreditation',
            'description',
            'created_at',
            'company_id',
            'weight',
            'unit_weight',
            'weight_unit',
            'eco_tax',
            {
              images: ['uid', 'url', 'url_thumbnail', 'url_medium', 'url_large'],
            },
            {
              category: ['uid', 'name', 'slug'],
            },
            {
              brand: ['uid', 'name', 'slug'],
            },
          ],
        },
      ],
    },
  })
}
