import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"/","home":"/","callback":"/login"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/","expires":6,"secure":true,"domain":"stock-pro.fr"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/stockpro/api/v1/sign_in","method":"post","propertyName":"token","property":"user"},"logout":{"url":"/stockpro/api/v1/sign_out","method":"delete"},"user":{"url":"/stockpro/api/v1/me","method":"get","propertyName":"user","headers":{"Mapping":"{\"mapping\":{\"user\":[\"uid\",\"first_name\",\"last_name\",\"phone_number\",\"phone\",\"email\",\"cart_uid\",\"user_roles\",{\"role\":[\"uid\",\"name\"]},{\"company_groups\":[\"uid\",\"name\",\"key\",\"kind\",\"logo_url\",\"company_group_configuration\",\"current_user_roles\"]},{\"company\":[\"uid\",\"name\",\"slug\",\"zip_code\",{\"addresses\":[\"city\",\"complement\",\"kind\",\"name\",\"street\",\"uid\",\"zip_code\",\"country\",\"country_iso\"]},\"address\",\"city\",\"siren\",\"status\",\"options\",\"stripe_url\"]},\"activity\"]}}"}}},"autoLogout":true,"autoFetchUser":true,"tokenType":null,"_name":"local"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
